import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './ViewLots.css'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import { calculateSqurareKmFromSquareMeters } from '../../functions/calculateSquareKmFromSquareMeters';
import { subscriptionStatuses } from '../SubscriptionStatuses/SubscriptionStatuses';
import LayersIcon from '@mui/icons-material/Layers';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import EventIcon from '@mui/icons-material/Event';
import CropDinIcon from '@mui/icons-material/CropDin';
import RefreshIcon from '@mui/icons-material/Refresh';
import CropFreeIcon from '@mui/icons-material/CropFree';
import VisibilityIcon from '@mui/icons-material/Visibility';
import pricingSchedule from '../../assets/pricingSchedule.json';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';


function ViewLots(props) {

    const orgObj = props.organizationObj
    const firestore = firebase.firestore();
    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const navigate = useNavigate()

    const [userBillingLots, setUserBillingLots] = useState({})

    useEffect(() => {
        init()

    }, [orgObj?.selectedOrganization])

    function init() {
        getOrganizationBillingLots()
    }

    
    // Get the user's lots
    function getOrganizationBillingLots() {

        var ref = firestore.collection('Billing').doc(orgObj?.selectedOrganization).collection('Lots');        
        ref
        //.where("metaData.active", "==", true)
        .onSnapshot((querySnapshot) => {
            var lots = {}
            querySnapshot.forEach((doc) => {
                lots[doc.id] = doc.data()
            });
            setUserBillingLots(lots)
        }) 
    }

    function kmSquaredToMilesSquared(kmSquared) {
        return Math.floor(kmSquared * 0.386102 * 100) / 100
    }

    return (
        <div className="viewLotsContent">
            <div className='viewLotsContentScroller'>
                <Paper elevation={2} className='viewLotsContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <LayersIcon style={{marginRight: '10px'}}/>
                        All Lots
                        <div style={{flex: 1}}></div>                        
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddCircleIcon/>}
                            onClick={() => {
                                navigate(`/Organization/CreateLot`)
                            }}
                            style={{}}>
                            Create Lot
                        </Button>      
                    </div>         
                </Paper>
                <div className="viewLotsContentBody">
                    {
                        //order the lots by displayName
                        Object.keys(userBillingLots).sort((a, b) => { 
                            return userBillingLots[a].displayName > userBillingLots[b].displayName ? 1 : -1 
                        }).filter(e => userBillingLots[e]?.metaData?.active == true).map((lotId) => {                        

                            const lot = userBillingLots[lotId]

                            const stripeSubscriptionStatus = lot?.stripeSubscription?.status

                            return (
                                <Paper
                                    elevation={2}
                                    className='viewLotsLotCard'>
                                    <div className='viewLotsLotCardHeader'>{lot?.displayName}</div>
                                    <h5><DonutLargeIcon style={{marginRight: '10px', fontSize: '20px'}}/><strong style={{marginRight: '5px',}}>Status:</strong>{subscriptionStatuses[stripeSubscriptionStatus] != undefined ? subscriptionStatuses[stripeSubscriptionStatus]:null} {lot?.stripeSubscription?.cancel_at_period_end && stripeSubscriptionStatus == "active" ? subscriptionStatuses['cancelRequested'] : null}</h5>
                                    <h5><CropDinIcon style={{marginRight: '10px', fontSize: '20px'}}/><strong style={{marginRight: '5px',}}>Total Area:</strong>{`${calculateSqurareKmFromSquareMeters(lot?.exactArea)} km²`}</h5>                                    
                                    <h5><EventIcon style={{marginRight: '10px', fontSize: '20px'}}/><strong style={{marginRight: '5px',}}>Created:</strong>{`${calculateDateFromMillisecond(lot?.dateCreated?.seconds * 1000)}`}</h5>                                    
                                    <div style={{flex: 1}}></div>
                                    <div className='viewLotsLotCardButtonHolder'>                                        
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            startIcon={<VisibilityIcon/>}
                                            onClick={() => {
                                                navigate(`/Organization/Lots/${lotId}`)
                                            }}
                                            style={{marginRight: '10px', flex: 1,}}>
                                            View Lot
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<ViewInArIcon/>}
                                            onClick={() => {
                                                navigate(`/Organization/MapView/${lotId}`)
                                            }}
                                            style={{flex: 1,}}>
                                            Map View
                                        </Button>
                                    </div>                                    
                                </Paper>
                            )

                        })
                    }
                </div>    
            </div>    
            
        
            <Footer></Footer>
        </div>
    )

}


export default ViewLots