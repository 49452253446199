import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { Button, IconButton, Paper, Select, MenuItem, InputLabel, FormControl, Box, 
         Typography, Slider, Switch, FormGroup, FormControlLabel, CircularProgress, 
         Tabs, Tab, AppBar, Stack, Pagination, Icon, Autocomplete, TextField, Collapse } from '@mui/material';
import { GoogleMap, useLoadScript, Marker, Polygon, GroundOverlay, OverlayView } from '@react-google-maps/api';


import colors from '../../colors'
import './MapView.css';
import firebase from 'firebase/compat/app';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import InfiniteScroll from 'react-infinite-scroll-component';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import logo from '../../assets/logoOrange.png'
import nashvilleDemo from '../../assets/nashvilleDemoColorCorrected.png'

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { toEasternTime } from '../../functions/toEasternTime'
import { calculateAreaFromCoordinates } from '../../functions/calculateAreaFromCoordinates'

//import jsonData from '../../PopulationRenders/Geometry/USA-AZ-Phoenix.json'

import googleMapsStyle from '../../assets/googleMapsStyle.json'
import { useParams, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import MapIcon from '@mui/icons-material/Map';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import CropFreeIcon from '@mui/icons-material/CropFree';
import SaveIcon from '@mui/icons-material/Save';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import GifIcon from '@mui/icons-material/Gif';
import GifBoxIcon from '@mui/icons-material/GifBox';
import FindArea from '../FindArea/FindArea';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { sendEmailVerification } from 'firebase/auth';
import { ViewInAr } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import pricingSchedule from '../../assets/pricingSchedule.json';
import * as turf from '@turf/turf';
import e from 'cors';

function MapView(props) {

  const orgObj = props.organizationObj

  const theme = useTheme();
  const demoMode = props.demoMode;
  const userId = demoMode ? "Demo":orgObj?.selectedOrganization /*props.user.uid*/;

  const createAlert = props.createAlert

  const firestore = firebase.firestore();
  const navigate = useNavigate();
  const {lotId, dateValue, analyticId} = useParams();
  const costPerKm2 = 0;

  
  const mapRef = React.useRef(null);
  const [mapZoom, setMapZoom] = useState(12)
  const [location, setLocation] = useState({lat: 28.5015, lng: -81.38541,})
  const [userLocation, setUserLocation] = useState({lat: 28.5015, lng: -81.38541,})
  const currentLocation  = useRef({lat: 28.5015, lng: -81.38541});
  const [lots, setLots] = useState({})
  const lotsRef = useRef(lots)
  const [selectedLot, setSelectedLot] = useState("")
  const selectedLotRef = useRef(selectedLot)
  const [mapLoaded, setMapLoaded] = useState(false);
  const [dateSliderValue, setDateSliderValue] = useState(dateValue != undefined ? parseInt(dateValue) : (toEasternTime(new Date())).getTime())
  const [mapPngObject, setMapPngObject] = useState({})
  const [requestedMapPngObject, setRequestedMapPngObject] = useState({})
  const [mapGifObject, setMapGifObject] = useState({})
  const [requestedMapGifObject, setRequestedMapGifObject] = useState({})
  const [showMap, setShowMap] = useState(true);
  const [showGif, setShowGif] = useState(false);
  const [showSample, setShowSample] = useState(false);
  const showGiftRef = useRef(showGif)
  const [showAOIs, setShowAOIs] = useState(true);
  const [imageProcessingJSON, setImageProcessingJSON] = useState({})
  const [selectedAnalyticKey, setSelectedAnalyticKey] = useState("")
  const [sidePanelOpen, setSidePanelOpen] = useState(true);
  const [sidePanelTabValue, setSidePanelTabValue] = React.useState(0);
  const [showGroundOverlayGif, setShowGroundOverlayGif] = useState(true)
  const [analyticPhotoURLs, setAnalyticPhotoURLs] = useState({})
  const [analyticPhoto3YearURL, setAnalyticPhoto3YearURL] = useState({})
  const [analyticGIFURL, setAnalyticGIFURL] = useState({})
  const [legendShown, setLegendShown] = useState(true)
  const [showLandclearing, setShowLandclearing] = useState(true)
  const [showConstruction, setShowConstruction] = useState(true)
  const [showRenovation, setShowRenovation] = useState(false)
  const [brightness, setBrightness] = useState(1.5)
  const [constructionPage, setConstructionPage] = useState(1)
  const [landClearingPage, setLandClearingPage] = useState(1)
  const [renovationPage, setRenovationPage] = useState(1)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [toolbarMarginLeft , setToolbarMarginLeft] = useState('0px');
  const [backendSquares, setBackendSquares] = useState({});
  const [usedBackendSquares, setUsedBackendSquares] = useState({});
  const lastMapBoundsChange = useRef(0)
  const [yearImgPicker, setYearImgPicker] = useState(0)
  const [visible25Boxes, setVisible25Boxes] = useState([])
  const [visible25GifBoxes, setVisible25GifBoxes] = useState([])
  const [all25GifBoxes, setAll25GifBoxes] = useState([])
  const [lotDateAnalytics, setLotDateAnalytics] = useState({})
  const [selectedLotDateSquareAnalytics, setSelectedLotDateSquareAnalytics] = useState([])
  const [mapTypeId, setMapTypeId] = useState('roadmap');
  const [userInStreetView, setUserInStreetView] = useState(false)

  const [datePickerInterval, setDatePickerInterval] = useState("month")
  const MONTHINTERVAL = useRef(3)
  const DATAMONTHINTERVAL = useRef(12)
  const FILEFORMATS = [
    {
      name: "png",
      maxZoom: 1000,
      minZoom: 0
    },
    // {
    //   name: "jpg",
    //   maxZoom: 0,
    //   minZoom: 0
    // }
  ]
  const TRAININGGIFSECTIONS = 10

  const datePickerIntervalOptions = {
    "quarter": {
      title: "Quarterly Maps",
      nextInterval: "month",
      monthInterval: 3,
      dataMonthInterval: 12,
      optionsFunction: getFirstDayOfQuarters,
    },
    "month": {
      title: "Monthly Maps",
      nextInterval: "quarter",
      monthInterval: 1,
      dataMonthInterval: 3,
      optionsFunction: getFirstDayOfMonths,
    },
  }

  const MOBILEPIXELWIDHTHRESHOLD = 800
  const AOIDISTANCE = .25


  const IMAGEPROCESSINGKEY = "75-25.0-9"
  const storage = getStorage();
  // Define refs for Polygon instance and listeners
  const polygonRef = useRef({});
  const listenersRef = useRef({});
  const googleMapGeometryLoaded = useRef(false)
  const mapOptions = {
    styles: googleMapsStyle
  }
  const rectangleOptions = {
      fillColor: "#000000",
      fillOpacity: 0,
      strokeColor: "#000000",
      strokeOpacity: 1,
      strokeWeight: 2,
      zIndex: 1
  }

  const labelTypes = {
    "newConstruction": {
      "label": "New Construction", 
    },
    "landclearing": {
      "label": "Land Clearing", 
    },
    // "renovation": {
    //   "label": "Renovation", 
    // },

  }

  const numberOfImagesPerPage = windowWidth > MOBILEPIXELWIDHTHRESHOLD ? 12:9;

  const containerStyle = {
      width: '400px',
      height: '400px'
    };
    
  const center = {
      lat: 28.125,
      lng: -80.625
  };

  const { isLoaded } = useLoadScript({    
     googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"
  })

  const mapHandleOnLoad = map => {
    mapRef.current = map;
    init();
  };
  const mapHandleCenterChanged = () => {

    if (mapRef.current) {
      currentLocation.current = {lat:mapRef.current.center.lat(), lng: mapRef.current.center.lng()}
    }
  };

  var mapBoundsChangeTimeout = null

  const mapHandleBoundsChanged = (_fireImmediately, _selfCalled) => {

    const timeBetweenRefresh = 1000

    try{
      if (mapRef.current) {

        try{
          clearTimeout(mapBoundsChangeTimeout)
        }catch(err){
          console.error(err)
        }

        if(_fireImmediately || Date.now() - lastMapBoundsChange.current > timeBetweenRefresh){


          const bounds = mapRef.current.getBounds();
          const ne = bounds.getNorthEast();
          const sw = bounds.getSouthWest();
          
          calculateVisible25Boxes({
            lat: ne.lat(),
            lng: ne.lng()
          }, {
            lat: sw.lat(),
            lng: sw.lng()
          })
          lastMapBoundsChange.current = Date.now()
          
        }else{

          if(!_selfCalled){
            //set timeout to update visibleBackendLots
            mapBoundsChangeTimeout = setTimeout(() => {
              mapHandleBoundsChanged(false, true)
            }, timeBetweenRefresh+5);
          }
  
  
        }
      }
    }catch(err){
      
    }
  };

  function calculateVisible25Boxes(ne, sw){
    // Initialize the result array to store boxes
    const tempBoxIds = [] ;
    const tempBoxCoords = [];

    const divisionRatio = 1/AOIDISTANCE

    // Start from south-west coordinates
    let y = Math.floor(sw.lat * divisionRatio) / divisionRatio;  // Latitude (rounded down to nearest .25)
    let x = Math.floor(sw.lng * divisionRatio) / divisionRatio;  // Longitude (rounded down to nearest .25)

    // Loop until we reach the north-east bounds
    while (y < ne.lat) {
        while (x < ne.lng) {
            // For each increment, construct the .25 box
            const boxSW = { lat: y, lng: x };
            const boxNE = { lat: y + AOIDISTANCE, lng: x + AOIDISTANCE };

            // Check if this .25 box is at least partially inside the main bounding box
            if ((boxSW.lat <= ne.lat && boxSW.lng <= ne.lng) && (boxNE.lat >= sw.lat && boxNE.lng >= sw.lng)) {                                
                tempBoxIds.push(calculateLotEagleLatLngConversion(boxSW.lat.toFixed(2), boxSW.lng.toFixed(2)))
                // tempBoxCoords.push({
                //   key: get25ImageKey(userId, selectedLot, parseBackendDateString(dateSliderValue, MONTHINTERVAL.current, DATAMONTHINTERVAL.current), calculateLotEacleSubLatLngConversion(boxSW.lng, boxSW.lat), getFileFormat),
                //   coords: {
                //     lat: boxSW.lat,
                //     lng: boxSW.lng
                //   }
                // })
            }
            x += AOIDISTANCE;
        }
        x = Math.floor(sw.lng * divisionRatio) / divisionRatio; // Reset the x back to start value
        y += AOIDISTANCE;
    }

    //append previous state and remove duplicates
    setVisible25Boxes(prevState => { return [...new Set([...prevState, ...tempBoxIds])] })


    try{
      const _selectedLot = lotsRef.current[selectedLotRef.current] != undefined ? lotsRef.current[selectedLotRef.current]:{}
      const gifs = _selectedLot?.usedBackendSquares.map(e => e.square25).map(square => {
        return calculateAllTrainingGifs(square).map(e => {
          return {
            key: get25GifImageKey(userId, selectedLotRef.current, parseBackendDateString(dateSliderValue, MONTHINTERVAL.current, DATAMONTHINTERVAL.current), square, e.x, e.y, "gif"),
            squareId: square,
            bounds: e.bounds,
            x: e.x,
            y: e.y,
          }
        
        })          
      }).flat()

      //map ref bounds 
      const mapBounds = mapRef.current.getBounds()
      const northWest = mapBounds.getNorthEast()
      const southEast = mapBounds.getSouthWest()

      const mapRefBounds = {
        north: northWest.lat(),
        south: southEast.lat(),
        east: northWest.lng(),
        west: southEast.lng()
      }

      const selectedLotPolygon = _selectedLot?.polygon.map(e => {return [e.lng, e.lat]})
      //set the first element of the polygon to the last element
      selectedLotPolygon.push(selectedLotPolygon[0])

      const filteredGifs = gifs.filter(element => {

        if(!showGiftRef.current){
          return false
        }

        //calculate visible training gifs
        try{     
                    
          return doBoundsIntersect(element.bounds, mapRefBounds) && doPolygonsIntersect(selectedLotPolygon, boundsToPolygonArray(element.bounds))
          
        }catch(err){
          console.error(err)
          return false
        }
      })

      setVisible25GifBoxes(filteredGifs)
      setAll25GifBoxes(gifs)

    }catch(err){
      console.error(err)
    }



  }

  



  function init(){
    //check if user has location enabled
    if (navigator.geolocation) {
      try{
        navigator.geolocation.getCurrentPosition(function(resp){

          var userLocationElement = {
            lat: resp.coords.latitude,
            lng: resp.coords.longitude
          }

          //setUserLocation(userLocationElement)
          setLocation(userLocationElement)        
          setLots({})
          lotsRef.current = {}
          getFirestoreLots(userLocationElement)                      

        }, function(err){
          console.error(err)
          setLots({})
          lotsRef.current = {}
          getFirestoreLots(location)
        });
      }catch(err){ 
        console.error(err)
        setLots({})
        lotsRef.current = {}
        getFirestoreLots(location)
      }
    }else{
      setLots({})
      lotsRef.current = {}
      getFirestoreLots(location)
    }

    const streetView = mapRef.current.getStreetView();

    // Add listener for Street View visibility change
    streetView.addListener('visible_changed', () => {
      if (streetView.getVisible()) {
        setUserInStreetView(true)
      } else {
        setUserInStreetView(false)
      }
    });

  }
  

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
    
  }, []);


  useEffect(() => {

    if(Object.keys(lots).length > 0){
      //only save firebase endpoints
      const tempLots = {}
      Object.keys(lots).forEach(key => {
        if('dateCreated' in lots[key]){
          tempLots[key] = lots[key]
        }
      })
      try{        

        localStorage.setItem(`lots`, JSON.stringify(tempLots))
      }catch(err){
        console.error(err)
      }
    }

  }, [lots])

  useEffect(() => {

    if(Object.keys(backendSquares).length > 0){

      const tempbackendSquares = {}
      // get keys and extract the greatest last updated date
      var greatestUpdateSeconds = 0
      Object.keys(backendSquares).forEach(key => {
        if('dateUpdated' in backendSquares[key]){
          tempbackendSquares[key] = backendSquares[key]
          if(backendSquares[key].dateUpdated.seconds > greatestUpdateSeconds){
            greatestUpdateSeconds = backendSquares[key].dateUpdated.seconds
          }
        }
      })
      //get all keys
      const allKeys = Object.keys(tempbackendSquares)



      try{        
        localStorage.setItem(`backendSquares`, JSON.stringify({
          data: allKeys,
          lastUpdated: greatestUpdateSeconds
        }))
      }catch(err){
        console.error(err)
      }
    }

  }, [backendSquares])

  useEffect(() => {

    var tempSelectedLotDateSquareAnalytics = []

    try{
      lots?.[selectedLot]?.usedSquares.forEach((square) => {
        
        const analyticsKey = getLotDateAnalyticsKey(selectedLot, square, dateSliderValue)
        const analyticsObj = lotDateAnalytics[analyticsKey]
        if(analyticsObj?.analytics?.length > 0){
          
          analyticsObj.analytics.map((analytic) => {

            //if analytic has the key polygon convert it back to json
            if(analytic?.polygon != undefined){
              //if typeof analytic.polygon == "string"
              if(typeof analytic.polygon == "string"){
                analytic.polygon = JSON.parse(analytic.polygon)
              }
            }

            tempSelectedLotDateSquareAnalytics.push({
              "analyticsObj": analyticsObj,
              "analytic": analytic
            })
          })
          

        }
        
      })
    }catch(err){      
      console.error(err)
    }

    // calculateLotArea(lots?.[selectedLot], tempSelectedLotDateSquareAnalytics)

    setSelectedLotDateSquareAnalytics(tempSelectedLotDateSquareAnalytics)
    setVisible25Boxes([])
    mapHandleBoundsChanged(true, false)
    setYearImgPicker(0)

  }, [lots, selectedLot, dateSliderValue, lotDateAnalytics])


  useEffect(() => {
    setConstructionPage(1)
    setLandClearingPage(1)
    setRenovationPage(1)    
  }, [selectedLot, dateSliderValue])


  useEffect(() => {
    var paginatedAnalytics
    //get images for the visible items for the active page
    //if the side panel is open
    if(sidePanelOpen){
      switch (sidePanelTabValue) {
        //construction
        case 0:
          paginatedAnalytics = paginationFilter(selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "newConstruction"), constructionPage).map(e => {
            return {
              "documentId": e.analyticsObj.documentId,              
              "analyticId": e.analytic.key,
            }
          })
          getPolygonDateImages(paginatedAnalytics)          
          break;
        //land clearing
        case 1:          
          paginatedAnalytics = paginationFilter(selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "landclearing"), landClearingPage).map(e => {
            return {
              "documentId": e.analyticsObj.documentId,              
              "analyticId": e.analytic.key,
            }
          })
          getPolygonDateImages(paginatedAnalytics)  
          break;
        //renovation
        case 2:
          paginatedAnalytics = paginationFilter(selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "renovation"), renovationPage).map(e => {
            return {
              "documentId": e.analyticsObj.documentId,              
              "analyticId": e.analytic.key,
            }
          })
          getPolygonDateImages(paginatedAnalytics)  
          break;

      }

    }

  }, [constructionPage, landClearingPage, renovationPage, sidePanelOpen, sidePanelTabValue, selectedLotDateSquareAnalytics])

  useEffect(() => {

    //get the current dateSliderValue and set the dateSliderValue to the closest available date
    //if it's a tie go to the higher date
    const availableDates = calculateAvailableDates()
    if(availableDates.length > 0){
      var closestDate = availableDates[0].value
      var closestDateDiff = Math.abs(dateSliderValue - closestDate)
      availableDates.forEach(element => {

        if(Math.abs(dateSliderValue - element.value) == closestDateDiff){
          //if the element is greater than the current closest date
          if(element.value > closestDate){
            closestDate = element.value
            closestDateDiff = Math.abs(dateSliderValue - element.value)
          }
        }else if(Math.abs(dateSliderValue - element.value) < closestDateDiff){
          closestDate = element.value
          closestDateDiff = Math.abs(dateSliderValue - element.value)
        }
      });

      //if the closest date is not the current dateSliderValue
      if(closestDate != dateSliderValue){
        handleDateSliderChange(closestDate)
      }
    }

  }, [lotDateAnalytics])

  function handleWindowSizeChange() {
    setWindowWidth(window.innerWidth);    
  }

  const exitStreetView = () => {
    const streetView = mapRef.current.getStreetView();
    streetView.setVisible(false);
  };
  

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  function setCookie(cname, cvalue, exdays) {
    var d = toEasternTime(new Date());
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  function paginationFilter(data, pageNumber) {
    let startIndex = (pageNumber - 1) * numberOfImagesPerPage;
    let endIndex = startIndex + numberOfImagesPerPage;
    return data.slice(startIndex, endIndex);
  }

  function setGoogleMapType(_type){

    setMapTypeId(_type)

    //setMapTypeId(prev => prev === 'satellite' ? 'roadmap' : 'satellite');
  }

  function toggleSidePanel(_open){


    // if(_open && !sidePanelOpen){

    //   setToolbarMarginLeft(`-855px`)
    //   setTimeout(() => {
    //     setSidePanelOpen(true)
    //     setToolbarMarginLeft(`0px`)        
    //   }, 100);

      
    // }else if(!_open && sidePanelOpen){
        
    //     setToolbarMarginLeft(`-855px`)
    //     setTimeout(() => {
    //       setSidePanelOpen(false)   
    //     }, 100);
  
    //   }
  }

  function runTutorial(_manual){

    // //run tutorial if there is no cookie
    // if((false && getCookie("tutorial") != "true") || _manual){
      
    //     introJs().setOptions({
    //       steps: [
    //         {
    //           title: 'Lot Eagle',
    //           intro: "This tutorial will walk you through the features of Map View. Click 'Next' to continue.",
    //           disableInteraction: true,
    //         },
    //         {
    //           element: document.querySelector(".mapContainer"),
    //           title: 'Map View',
    //           intro: "This area allows you to select and view territories.",
    //           disableInteraction: true,
    //         },
    //         {
    //           element: document.querySelector(".mapContainerSliderDiv"),
    //           title: 'Timeline',
    //           intro: "Drag the timeline to view historical data.",
    //           disableInteraction: true,
    //         },
    //         {
    //           element: document.querySelector(".toolbarScrollViewHolder"),
    //           title: 'Areas of Interest',
    //           intro: "Areas of change that have been selected by our algorithms and reviewed by our QA team.",
    //           disableInteraction: false,
    //         },
    //         {
    //           element: document.querySelector(".activeAOIArea"),
    //           title: 'Selected Area of Interest',
    //           intro: "Additional information about the selected Area of Interest can be found here. Including an summary image of the area over the past three years.",
    //           disableInteraction: false,
    //         },
    //         {
    //           element: document.querySelector(".legendDiv"),
    //           title: 'Legend',
    //           intro: "Enable and disable layers.",
    //           disableInteraction: true,
    //         },
    //         {
    //           element: document.querySelector(".selectedAOISave"),
    //           title: 'Download Excel',
    //           intro: "Download a spreadsheet of the Areas of Interest.",
    //           disableInteraction: true,
    //         },
    //       ]
    //     }).onbeforeexit(function () {
    //       setCookie("tutorial", "true", 365)
    //     }).start(); 

    // }
  }


  

  function padNumber(number) {
    
    return number.toFixed(3).toString().padStart(7, '0');
  

  }
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

  function roundDownToNth(num) {
    return Math.floor(num * (1/AOIDISTANCE)) / (1/AOIDISTANCE);
  }
  

  function calculateLotEagleLatLngConversion(_lat, _lng){
    return padNumber(roundDownToNth(_lng) + 180) + '-' + padNumber(roundDownToNth(_lat) + 90)
  }

  

  


  function changeBrightness(_brightness){
    if(_brightness > 0 && _brightness <= 10){
      setBrightness(_brightness)
      



      document.getElementById('dynamicCSS').innerHTML = `
        img[src*="firebase"] {
          filter: brightness(${_brightness});
        }
      `;

    }
  }

  function findClosestAOI(_lots, _userLocation){

    try{

      var lat = _userLocation['lat']
      var lng = _userLocation['lng']

      var closestDistance = Infinity;
      var closestLatLngKey = null;
      
      Object.keys(_lots).filter(e => _lots[e]?.metaData?.active == true).forEach(function(key) {
        const element = _lots[key];
        let distance = haversineDistance(lat, lng, element?.center?.lat, element?.center?.lng);
        if (distance < closestDistance) {
          closestDistance = distance;
          closestLatLngKey = key;
        }
      });
      


      //setLocation({lat: _polygons[closestLatLngKey]?.center?.lat, lng: _polygons[closestLatLngKey]?.center?.lng})      
      selectLot(closestLatLngKey, _lots)

    }catch(err){
      console.error(err)
    }        


  }

  
  // Function to calculate the haversine distance between two points on Earth
  function haversineDistance(lat1, lng1, lat2, lng2) {
    let radLat1 = toRadians(lat1);
    let radLat2 = toRadians(lat2);
    let deltaLat = radLat2 - radLat1;
    let deltaLng = toRadians(lng2 - lng1);
    let R = 6371000
    
    let a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
            Math.cos(radLat1) * Math.cos(radLat2) *
            Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let d = R * c;
    
    return d;
  }

  // Function to convert degrees to radians
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  function backendSquareIdToLatLng(id) {
    // Split the ID into lat and lng parts
    const [lngStr, latStr] = id.split("-");

    // Convert the strings to their numeric representations and adjust back to original values
    const lng = (parseFloat(lngStr).toFixed(3)) - 180;
    const lat = (parseFloat(latStr).toFixed(3)) - 90;

    return { lat, lng };
  }

  // function to convert from lat lng to square id
  function latLngToBackendSquareId(lng, lat) {
    // Convert the lat and lng to strings and pad them to 3 decimal places

    // perform the inverse of thse operations in backendSquareIdToLatLng
    // const lng = (parseFloat(lngStr).toFixed(3)) - 180;
    // const lat = (parseFloat(latStr).toFixed(3)) - 90;

    const lngString = padNumber(lng + 180);
    const latString = padNumber(lat + 90);

    return `${lngString}-${latString}`;
  }


  
  function getFirestoreLots(_userLocation){

    //get local data first
    var tempLots = {}
    var updateDate = toEasternTime(new Date(0))
    // try {
    //   tempLots = JSON.parse(localStorage.getItem(`lots`))
    //   if(Object.keys(tempLots).length > 0){        
    //      updateDate = toEasternTime(new Date((Math.max(...Object.values(tempLots).map(item => {try{return item.dateUpdated.seconds}catch(err){return 0}})) * 1000) + 1));
    //   }
    //   //filter out data where metaData.active is false
    //   Object.keys(tempLots).forEach(key => {
    //     if(!tempLots[key].metaData.active){
    //       delete tempLots[key]
    //     }
    //   })
    // }catch(err){
    //   console.error(err)
    // }

    // //check if tempPolygons has been sucessfully retreived from the cache
    // if(!(typeof tempLots === 'object') || tempLots == null){
    //   tempLots = {}
    //   updateDate = toEasternTime(new Date(0))
    // }

    //firestore.collection('GenericValues').doc("Polygons").collection("Cities")
    
    firestore
    .collection('Billing')
    .doc(userId)
    .collection("Lots")
    .where("metaData.active", "==", true)
    //.where("dateUpdated", ">", updateDate)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {        

        tempLots[doc.id] = doc.data();
      });
      setLots({...tempLots})
      lotsRef.current = {...tempLots}
      
      if(lotId != undefined){
        selectLot(lotId, tempLots)
      }else{
        //set the user's location
        findClosestAOI(tempLots, _userLocation)
      }

    })
    .catch((error) => {
      createAlert("error", "Error getting lots")
      setLots({...tempLots})
      lotsRef.current = {...tempLots}
    });
  
  }

  function getPolygonImageProcessingAnalytics(_key, _dateVal){

    return new Promise((resolve, reject) => {

      const compoundKey = parseBackendString(_key, _dateVal, MONTHINTERVAL.current, DATAMONTHINTERVAL.current, true)

      if(!(compoundKey in mapPngObject)){

        firestore.collection('BackendImageProcessingManual')
        .doc(compoundKey)
        .get()
        .then((doc2) => {   

          var data2 = {}
          const doc2Data = doc2.data();
          if(doc2Data != undefined){
            try{

              Object.keys(doc2Data).forEach(key => {
                data2[key] = JSON.parse(doc2Data[key])
              })
            }catch(err){}

          }

          firestore.collection('BackendImageProcessing')
          .doc(compoundKey)
          .get()
          .then((doc) => {   

            const data = doc.data();
            if(data != undefined){
              const tempObject = {}
              Object.keys(data).forEach(key => {
                tempObject[key] = JSON.parse(data[key])
              })
              //overwrite any manually managed fields
              Object.keys(data2).forEach(key => {
                Object.keys(data2[key]).forEach(key2 => {
                  tempObject[key][key2] = data2[key][key2];
                });
              })

              Object.keys(tempObject).forEach(key => {
                for (let key2 in tempObject[key]) {
                  try{
                    const element = tempObject[key][key2].coordinates.label;
                    if (element === "renovation" || element === "falsePositive") {
                      delete tempObject[key][key2];
                    }
                  }catch(err){
                    console.error(err)
                    delete tempObject[key][key2];
                  }                  
                }
              });

              //only return data from elements that have been manually reviewed
              var filteredTempObject = {}
            
              Object.keys(data2).forEach(key => {
                filteredTempObject[key] = tempObject[key]
              })
              
              
              resolve({
                key: compoundKey,
                val: filteredTempObject
              })
            }else{
              resolve({
                key: compoundKey,
                val: {}
              })
            }

      
          })
          .catch((error) => {
              console.error("Error getting documents: ", error);
              resolve({
                key: compoundKey,
                val: {}
              })
          });  
        });
      }else{
        resolve({
          key: compoundKey,
          val: {}
        })
      }
      
    })    
  }

  function getAnalyticImgKey(_squareDateKey, _analyticId, _imgType){
    return `${_squareDateKey}-${IMAGEPROCESSINGKEY}-${_analyticId}-${_imgType}`
  }

  function getPolygonDateImages(_analyticsToRequest){
    
    const promises = []

    _analyticsToRequest.forEach(element => {

      if(!(getAnalyticImgKey(element.documentId, element.analyticId, "afterPng") in analyticPhotoURLs)){
        promises.push(getAnalyticImgUrl(element.documentId, element.analyticId, "afterPng"))
        
      }
      if(!(getAnalyticImgKey(element.documentId, element.analyticId, "gif") in analyticPhotoURLs)){
        promises.push(getAnalyticImgUrl(element.documentId, element.analyticId, "gif"))        
      }
      if(!(getAnalyticImgKey(element.documentId, element.analyticId, "png") in analyticPhotoURLs)){
        promises.push(getAnalyticImgUrl(element.documentId, element.analyticId, "png"))        
      }
      
    });
  
    Promise.all(promises).then((resps) => {
      const tempObj = {}

      resps.forEach((resp) => {
        tempObj[resp.key] = resp.val
      })

      setAnalyticPhotoURLs((prevState) => {
        return {
          ...prevState,
          ...tempObj
        }
      })
    })
  }


  

  function centerMap(_polygon){

    if (mapRef.current) {

      const bounds = new window.google.maps.LatLngBounds();

      _polygon.forEach(coord => {
        bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
      });

      mapRef.current.panToBounds(bounds);
      mapRef.current.fitBounds(bounds);

      setMapZoom(mapRef.current.getZoom())
      //set map center
      setLocation(mapRef.current.getCenter())
    }            

  }

  async function selectLot(_key, _lots) {
    if(_key == "" || _key == undefined || _key == null){
      //setShowMap(false)
      setSelectedLot("");
      selectedLotRef.current = "";
      changeDateSliderInterval("quarter")
      setSelectedAnalyticKey("")
      navigate(demoMode ? `/Demo`:`/MapView`, { replace: true });
    }
    else if(_key != selectedLot && _key in _lots ){
      //setShowMap(true)
      setSelectedLot(_key);
      selectedLotRef.current = _key;
      const newDateSliderValue = changeDateSliderInterval("quarter")
      setSelectedAnalyticKey("")
      navigate(demoMode ? `/Demo/${_key}`:`/MapView/${_key}`, { replace: true });
      //only query if an area is selected
      centerMap(_lots[_key].polygon) 
      //if image processing has been done
      if("usedBackendSquares" in _lots[_key]){
        getRequiredPngs(_lots, _key, newDateSliderValue)       
        getRequiredGifs(_lots, _key, newDateSliderValue) 
      }        

      getLotDateAnalytics(_key, _lots, newDateSliderValue)

    }

    lastMapBoundsChange.current = 0
    


  }

  
  function get25ImageKey(_userId, _lotKey, _dateStr, _subsquareId, _format){
    return `${_userId}-${_lotKey}-${_dateStr}-${_subsquareId}-${_format}`
  }

  function get25GifImageKey(_userId, _lotKey, _dateStr, _subsquareId, xIndex, yIndex, _format){
    return `${_userId}-${_lotKey}-${_dateStr}-${_subsquareId}-${xIndex}-${yIndex}-${_format}`
  }

  function getRequiredPngs(_lots, _key, _dateSliderValue){
    const requestPath = `Backend/Lot_Polygon_25Degree_PNG_Export/${userId}/${_key}/${parseBackendDateString(_dateSliderValue, MONTHINTERVAL.current, DATAMONTHINTERVAL.current)}`

    //foreach square in _lots[_key]?.usedBackendSquares

    const itemsToRequestArray = []
    const itemsToRequestObject = {}
    

    _lots[_key]?.usedBackendSquares.forEach((square) => {
      const squareId = square?.square25
      //get subsquares from square
      
      FILEFORMATS.forEach((format) => {
        
        const fileName = `${squareId}-${parseBackendDateString(_dateSliderValue, MONTHINTERVAL.current, DATAMONTHINTERVAL.current)}.${format?.name}`
        const filePath = `${requestPath}/${fileName}`
        const imgKey = get25ImageKey(userId, _key, parseBackendDateString(_dateSliderValue, MONTHINTERVAL.current, DATAMONTHINTERVAL.current), squareId, format?.name)
        //if the key is not in the mapPngObject
        if(!(imgKey in mapPngObject) || !(imgKey in requestedMapPngObject)){
          itemsToRequestArray.push({
            filePath: filePath,
            key: imgKey,
          })
          itemsToRequestObject[imgKey] = true
        }       
      })

    })

    const allPromises = itemsToRequestArray.map((requestObj) => {
      return getPngFileUrlFromFirestoreStorage(requestObj.key, requestObj.filePath)
    })
    
    Promise.all(allPromises).then((values) => {
      const newValues = {}
      values.forEach(element => {
        newValues[element.key] = element.val
      });
      setMapPngObject(prevState => {
        return {
          ...prevState,
          ...newValues
        }
      })
    }) 

    //set requestedMapJpgObject for new polygon and current dateSlider
    setRequestedMapPngObject(prevState => {
      return {
        ...prevState,
        ...itemsToRequestObject
      }
    });

  }

  function getRequiredGifs(_lots, _key, _dateSliderValue){
    const requestPath = `Backend/Lot_Polygon_25Degree_GIF_Export/${userId}/${_key}/${parseBackendDateString(_dateSliderValue, MONTHINTERVAL.current, DATAMONTHINTERVAL.current)}`

    //foreach square in _lots[_key]?.usedBackendSquares

    const itemsToRequestArray = []
    const itemsToRequestObject = {}
    

    _lots[_key]?.usedBackendSquares.forEach((square) => {

      for (let yIndex = 0; yIndex < TRAININGGIFSECTIONS; yIndex++) {
        for (let xIndex = 0; xIndex < TRAININGGIFSECTIONS; xIndex++) {
          
          const squareId = square?.square25
          //get subsquares from square
          const fileName = `${squareId}-${parseBackendDateString(_dateSliderValue, MONTHINTERVAL.current, DATAMONTHINTERVAL.current)}-${xIndex}-${yIndex}.gif`
          const filePath = `${requestPath}/${fileName}`
          const imgKey = get25GifImageKey(userId, _key, parseBackendDateString(_dateSliderValue, MONTHINTERVAL.current, DATAMONTHINTERVAL.current), squareId, xIndex, yIndex, "gif")
          //if the key is not in the mapGifObject
          if(!(imgKey in mapGifObject) || !(imgKey in requestedMapGifObject)){
            itemsToRequestArray.push({
              filePath: filePath,
              key: imgKey,
            })
            itemsToRequestObject[imgKey] = true
          }       
        }
      }

    })

    const allPromises = itemsToRequestArray.map((requestObj) => {
      return getGifFileUrlFromFirestoreStorage(requestObj.key, requestObj.filePath)
    })
    
    Promise.all(allPromises).then((values) => {
      const newValues = {}
      values.forEach(element => {
        newValues[element.key] = element.val
      });
      setMapGifObject(prevState => {
        return {
          ...prevState,
          ...newValues
        }
      })
    }) 

    //set requestedMapJpgObject for new polygon and current dateSlider
    setRequestedMapGifObject(prevState => {
      return {
        ...prevState,
        ...itemsToRequestObject
      }
    });

  }


  function calculateAllTrainingGifs(_squareId){


    //selectedAnalyticsImgOverlayUrl = `http://localhost:5000/gifMap?dataId=${selectedAnalyticsKeyRef.current}`
    const trainingGifSections = 10
    //foreach x in trainingGifSections

    const squareLatLng = backendSquareIdToLatLng(_squareId)
    const squareCoords = createSquare(squareLatLng.lng, squareLatLng.lat, AOIDISTANCE)

    const tempTrainingOverlays = []
    
    //foreach y in trianingGifSections
    for(var y = 0; y < trainingGifSections; y++){

      for(var x = 0; x < trainingGifSections; x++){

        //calculate the bounds of the square based on the x and y and the squareCoords
        //all 100 squares will fit perfectly in the squareCoords 10x10
        //the degree difference between each square is 1/10 of the AOIDISTANCE
        const squareBounds = {
          north: squareCoords[3].lat - (y * (AOIDISTANCE/trainingGifSections)),
          south: squareCoords[3].lat - ((y + 1) * (AOIDISTANCE/trainingGifSections)),
          east: squareCoords[3].lng + ((x + 1) * (AOIDISTANCE/trainingGifSections)),
          west: squareCoords[3].lng + (x * (AOIDISTANCE/trainingGifSections)),
        }
        
        tempTrainingOverlays.push({
          x: x,
          y: y,
          bounds: squareBounds,
        })

      }
    }
    return tempTrainingOverlays
  }

  function boundsToPolygonArray(_bounds){
    const boundsPolygon = [
      [_bounds.west, _bounds.north],
      [_bounds.east, _bounds.north],
      [_bounds.east, _bounds.south],
      [_bounds.west, _bounds.south],
      [_bounds.west, _bounds.north]
    ]
    return boundsPolygon
  }


  function boundsToPolygon(_bounds){
    const boundsPolygon = [
      {
        lat: _bounds.north,
        lng: _bounds.west
      },
      {
        lat: _bounds.north,
        lng: _bounds.east
      },
      {
        lat: _bounds.south,
        lng: _bounds.east
      },
      {
        lat: _bounds.south,
        lng: _bounds.west
      },
      {
        lat: _bounds.north,
        lng: _bounds.west
      }
    ]
    return boundsPolygon
  }


  function doPolygonsIntersect(polygon1, polygon2) {

    const polygon1GeoJSON = turf.polygon([polygon1]);
    const polygon2GeoJSON = turf.polygon([polygon2]);

    return turf.booleanOverlap(polygon1GeoJSON, polygon2GeoJSON) || turf.booleanContains(polygon1GeoJSON, polygon2GeoJSON);

  };

  function doBoundsIntersect(bounds1, bounds2) {
    //check if any part of bounds2 is inside bounds1
    if(bounds1.south > bounds2.north){
      return false
    }else if(bounds1.north < bounds2.south){
      return false
    }else if(bounds1.west > bounds2.east){
      return false
    }else if(bounds1.east < bounds2.west){
      return false
    }else {
      return true
    }

  }

  function getLotDateAnalyticsKey(_lotId, _squareId, _dateValue){
    return `${_lotId}-${_squareId.replace(/\./g, '')}-${parseBackendDateString(_dateValue, MONTHINTERVAL.current, DATAMONTHINTERVAL.current)}`
  }

  function getLotDateAnalytics(_key, _lots, _newDateSliderValue){
    
    const tempLotDateAnalytics = {...lotDateAnalytics}

    const promiseList = []
    try{
      _lots[_key]?.usedSquares.forEach((square) => {
        const analyticsKey = getLotDateAnalyticsKey(_key, square, _newDateSliderValue)

        if(!(analyticsKey in tempLotDateAnalytics)){
          promiseList.push(
            firestore.collection('BackendImageProcessingFilteredLotAnalytics').doc(userId).collection('LotAnalytics').doc(analyticsKey).get()
          )
        }

      })
    }catch(err){
    }
    
    Promise.all(promiseList)
    .then((values) => {
      
      values.forEach((doc) => {
        if(doc.exists){
          tempLotDateAnalytics[doc.id] = doc.data()
        }else{
          tempLotDateAnalytics[doc.id] = {
            analytics: [],
            lotId: _key,
          }
        }
      })

      setLotDateAnalytics(tempLotDateAnalytics)

    })

  }

  function parseBackendDateString(_dateVal, _monthInterval, _dataMonthInterval){
    const startDate = toEasternTime(new Date(_dateVal));
    const endDate = toEasternTime(new Date(_dateVal)); 
    
    startDate.setMonth(startDate.getMonth() + _monthInterval - _dataMonthInterval)
    endDate.setMonth(endDate.getMonth() + _monthInterval);  
    const startDateString = startDate.toISOString().substring(0, 10)
    const endDateString = endDate.toISOString().substring(0, 10)
    return `${startDateString}-${endDateString}`
  }

  function parseBackendString(_key, _dateVal, _monthInterval, _dataMonthInterval, _replacePeriod = true){
    const dateString = parseBackendDateString(_dateVal, _monthInterval, _dataMonthInterval)

    return `${_replacePeriod ? _key.replace(/\./g, ''):_key}-${dateString}`;
  }

  

  function getPngFileUrlFromFirestoreStorage(_key, _path){

    return new Promise((resolve, reject) => {

      if(!(_key in mapPngObject) && mapPngObject?._key != ""){

        getDownloadURL(ref(storage, _path)).then(resp => {
          resolve({
            key: _key,
            val: resp
          })
        }).catch(err => {          
          resolve({
            key: _key,
            val: ""
          })
        });
      }else{
        resolve({
          key: _key,
          val: mapPngObject[_key]
        })
      }
    })
  }

  function getGifFileUrlFromFirestoreStorage(_key, _path){

    return new Promise((resolve, reject) => {

      if(!(_key in mapGifObject) && mapGifObject?._key != ""){

        getDownloadURL(ref(storage, _path)).then(resp => {
          resolve({
            key: _key,
            val: resp
          })
        }).catch(err => {          
          resolve({
            key: _key,
            val: ""
          })
        });
      }else{
        resolve({
          key: _key,
          val: mapGifObject[_key]
        })
      }
    })
  }

  
  // Call setPolygons with new edited path
  const onEdit = useCallback((key, _polygons) => {
      try{
          if (polygonRef.current[key]) {
              const nextPath = polygonRef.current[key]
                  .getPath()
                  .getArray()
                  .map(latLng => {
                  return { lat: latLng.lat(), lng: latLng.lng() };
                  });

              const tempPolygons = _polygons
              tempPolygons[key].paths = nextPath
              tempPolygons[key].metaData.area = google.maps.geometry.spherical.computeArea(polygonRef.current[key].getPath())
              setLots({...tempPolygons});
              lotRef.current = {...tempPolygons}
          }
      }catch(err){

      }
  }, [setLots]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon, key, _polygons) => {

      try{
      polygonRef.current[key] = polygon;

      const path = polygon.getPath();
      /*listenersRef.current[key].push(
          path.addListener("set_at", onEdit(key, _polygons)),
          path.addListener("insert_at", onEdit(key, _polygons)),
          path.addListener("remove_at", onEdit(key, _polygons))
      );*/

      var x = polygon.getPath()
      if ( google.maps.geometry != undefined){
        const tempPolygons = _polygons
        tempPolygons[key].metaData.area = google.maps.geometry.spherical.computeArea(polygon.getPath())
        setLots({...tempPolygons})      
        lotsRef.current = {...tempPolygons}      
      }
      }catch(err){
        console.error(err)
      }


    },
    [onEdit, setLots]
  );

  // Bind refs to current Polygon and listeners
  const onLoadAnalyticPolygon = useCallback(
    (polygon, key, _polygons) => {

      try{
      polygonRef.current[key] = polygon;

      const path = polygon.getPath();
      /*listenersRef.current[key].push(
          path.addListener("set_at", onEdit(key, _polygons)),
          path.addListener("insert_at", onEdit(key, _polygons)),
          path.addListener("remove_at", onEdit(key, _polygons))
      );*/

      var x = polygon.getPath()
      if ( google.maps.geometry != undefined){
        //const tempPolygons = _polygons
        //tempPolygons[key].metaData.area = google.maps.geometry.spherical.computeArea(polygon.getPath())
        //setPolygons({...tempPolygons})            
      }
      }catch(err){
        console.error(err)
      }


    },
    [onEdit, setLots]
  );

  function exportToExcel(_data, _fileName) {
    const dataArray = Object.keys(_data).map((key) => {
      const object = _data[key]
      return {
        id: key,
        label: object.coordinates.label,
        squareMetersChanged: object.coordinates.pixelsChanged * 100,
        north: object.coordinates.bounds.north,
        south: object.coordinates.bounds.south,
        east: object.coordinates.bounds.east,
        west: object.coordinates.bounds.west,
      }
    })

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(dataArray);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data']};
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, _fileName + fileExtension)

  };

  function exportToGeoJson() {

    const analyticsArray = selectedLotDateSquareAnalytics.filter(element => [showConstruction ? "newConstruction":"", showLandclearing ? "landclearing":"", showRenovation ? "renovation":""].includes('label' in element?.analytic ? element?.analytic?.label : element?.analytic?.manualReview?.manualLabel)).map(e => e.analytic)
    const keys = ["key", "label", "area", "address", "bounds", "polygon"]

    //foreach element in analyticsArray calculatePolygonArea based on the polygon
    analyticsArray.forEach((element) => {
      const coordinates = element.polygon.map((element) => {
        return { lat: element[1], lng: element[0] }
      })
      const area = calculateAreaFromCoordinates(coordinates)
      //set element.area to round to 0
      element.area = Math.round(area)
    })

    //order array by label then area descending 
    //first newConstruction, then landclearing, then renovation
    analyticsArray.sort((a, b) => {
      if(a.label == "newConstruction" && b.label != "newConstruction"){
        return -1
      }else if(a.label != "newConstruction" && b.label == "newConstruction"){
        return 1
      }else if(a.label == "landclearing" && b.label != "landclearing"){
        return -1
      }else if(a.label != "landclearing" && b.label == "landclearing"){
        return 1
      }else if(a.label == "renovation" && b.label != "renovation"){
        return -1
      }else if(a.label != "renovation" && b.label == "renovation"){
        return 1
      }else{
        return b.area - a.area
      }
    })

    

    const featuresArray = analyticsArray.map(e => {
      try{
        return {
          "type": "Feature",
          "properties": {
            "label": e.label != undefined ? e.label : "Label not found",
            "key": e.key != undefined ? e.key : "Key not found",
            "areaMetersSquared": e.area != undefined ? e.area : 0,
            "address": e.address != undefined ? e.address : "Address not found",
            "bounds": {
              "north": e.bounds.north != undefined ? e.bounds.north : 0,
              "south": e.bounds.south != undefined ? e.bounds.south : 0,
              "east": e.bounds.east != undefined ? e.bounds.east : 0,
              "west": e.bounds.west != undefined ? e.bounds.west : 0,
            }
          },
          "geometry": {
            "type": "Polygon",
            "coordinates": [e.polygon]
          },
          "style": {            
              "fill": getAOIColor(e.label),
          },
          
        }
      }catch(err){
        console.error(err)
        return {}
      }

    })

    //get a string of the labels that are active in the filter and add an abreviation to the name have them - separated and bracketed by () 
    //if they are all active then add "All" to the name

    const activeLabelAbbreviations = [showConstruction ? "C":"", showLandclearing ? "LC":"", showRenovation ? "R":""]
    
    var activeLabels = activeLabelAbbreviations.filter(e => e != "")
    if(activeLabels.length == 3){
      activeLabels = []
    }

    //make lot display name safe to be part of a filename by removing any non-alphanumeric characters
    var lotDisplayName = lots[selectedLot]?.displayName
    if(lotDisplayName == undefined){
      lotDisplayName = selectedLot
    }else if(lotDisplayName == ""){
      lotDisplayName = selectedLot
    }
    //replace any non-alphanumeric characters with a space except for spaces
    lotDisplayName = lotDisplayName.replace(/[^a-zA-Z0-9 ]/g, "")
  
    var name = `LotEagle_${lotDisplayName}_${getQuarter(new Date(dateSliderValue))}${activeLabels.join("-") != "" ? `(${activeLabels})`:""}`
    //replace all spaces with -
    name = name.replace(/ /g, '-')

    const geoJsonData = {
      "type": "FeatureCollection",
      "name": `${name}`,
      "crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
      "features": featuresArray
    }
    //export geoJsonData to a file
    const fileType = 'application/geo+json';
    const fileExtension = '.geojson';
    const data = new Blob([JSON.stringify(geoJsonData)], { type: fileType })
    FileSaver.saveAs(data, name + fileExtension)
  

  };


  function clearCache(){
    localStorage.setItem(`lots`, JSON.stringify({}))
  }

  function handleDateSliderChange( _newValue) {

    setDateSliderValue(_newValue);
    if(![undefined, "", null].includes(selectedLot) && ![undefined, "", null].includes(_newValue)){
      navigate(demoMode ? `/Demo/${selectedLot}/${_newValue}`: `/MapView/${selectedLot}/${_newValue}`, { replace: true });

    }

    if(typeof lots[selectedLot] === 'object'){
      //if image processing has been done
      if("usedBackendSquares" in lots[selectedLot]){
        getRequiredPngs(lots, selectedLot, _newValue)
        getRequiredGifs(lots, selectedLot, _newValue) 
        
      }

      getLotDateAnalytics(selectedLot, lots, _newValue)

    }


    // try{
    //   const activeKey = Object.keys(imageProcessingJSON[compoundKey][IMAGEPROCESSINGKEY]).find(key => imageProcessingJSON[compoundKey][IMAGEPROCESSINGKEY][key].coordinates.label == "newConstruction")
    //   selectAnalyticToView(activeKey, compoundKey, imageProcessingJSON, false)
    // }catch(err){
    //   console.error(err)
    // }

  };

  function createSquare(x, y, n) {
    let coordinates = [
      { lat: y, lng: x },
      { lat: y, lng: x + n },
      { lat: y + n, lng: x + n },
      { lat: y + n, lng: x }
    ];
    return coordinates;
  }

  function createQuadrilateral(_north, _south, _east, _west) {
    return [
      { lat: _north, lng: _west },
      { lat: _north, lng: _east },
      { lat: _south, lng: _east },
      { lat: _south, lng: _west }
    ];
  }
  
  function polygonArrayToLatLng(_polygonArray){

    //copy the first element to the end
    _polygonArray.push(_polygonArray[0])

    return _polygonArray.map((element) => {
      return {
        lat: element[1],
        lng: element[0]
      }
    })
  }

  function getAOIColor(_label){
    switch(_label) {
      case "landclearing":
        return "#03A9F4"
      case "newConstruction":
        return "#ffd600"
      case "renovation":
        return "#9c27b0"
      case "falsePositive":
        return "#34515e"
    }
  }

  function createBounds(x, y, n) {
    return {
      north: y + n,
      south: y,
      east: x + n,
      west: x
    }
  }
  
  function getQuarter(date) {
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month < 3) {
      return `Q1 ${year}`;
    } else if (month < 6) {
      return `Q2 ${year}`;
    } else if (month < 9) {
      return `Q3 ${year}`;
    } else {
      return `Q4 ${year}`;
    }
  }
  
  function findCenter(north, south, east, west) {
    const latitude = (north + south) / 2;
    const longitude = (east + west) / 2;
    return  {
              lat: latitude, 
              lng: longitude
            };
  }

  function changeDateSliderInterval(_interval){

    setDatePickerInterval(_interval)
    MONTHINTERVAL.current = datePickerIntervalOptions[_interval].monthInterval
    DATAMONTHINTERVAL.current = datePickerIntervalOptions[_interval].dataMonthInterval
    var newDateSliderValue = dateSliderValue
    switch(_interval) {
      case "month":
        //find closest month
        const closestMonth = getFirstDayOfMonths().reduce((prev, curr) => {
          return (Math.abs(curr.value - dateSliderValue) < Math.abs(prev.value - dateSliderValue) ? curr : prev);
        });
        newDateSliderValue = closestMonth.value
        handleDateSliderChange(newDateSliderValue)
        break;
      case "quarter":
        //find closest quarter that has data
        const closestQuarter = getFirstDayOfQuarters().reduce((prev, curr) => {
          return (Math.abs(curr.value - dateSliderValue) < Math.abs(prev.value - dateSliderValue) ? curr : prev);
        });



        newDateSliderValue = closestQuarter.value
        handleDateSliderChange(newDateSliderValue)
        break;
    }

    return newDateSliderValue
  }

  function getFirstDayOfMonths() {
    const currentDate = toEasternTime(new Date());
    const startDate = toEasternTime(new Date(2019, 0, 1)); // January 1st, 2019
    const months = [];

    let monthStartDate = toEasternTime(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
    while (monthStartDate < currentDate) {
      const dateVal = toEasternTime(new Date(monthStartDate))
      //set label if it lines up with a quarter
      var _label = ""
      if(dateVal.getMonth() % 3 == 0){
        _label = getQuarter(dateVal)
      }

      months.push({
        value: dateVal.getTime(),
        label: _label
      });
      monthStartDate = toEasternTime(new Date(monthStartDate.getFullYear(), monthStartDate.getMonth() + 1, 1));
    }
    months.pop()
    return months;
  }

  function getFirstDayOfQuarters() {
    const currentDate = toEasternTime(new Date());
    const startDate = toEasternTime(new Date(2019, 0, 1)); // January 1st, 2019
    const quarters = [];
  
    let quarterStartDate = toEasternTime(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
    while (quarterStartDate < currentDate) {
      const dateVal = toEasternTime(new Date(quarterStartDate))
      quarters.push({ 
        value: dateVal.getTime(),
        label: getQuarter(dateVal)
      });
      quarterStartDate = toEasternTime(new Date(quarterStartDate.getFullYear(), quarterStartDate.getMonth() + 3, 1));
    }
    // removes last element of the array
    quarters.pop();
    return quarters;
  }

  function getAnalyticImgUrl(_squareDateKey, _analyticId, _imgType){
    return new Promise((resolve, reject) => {

      const keyVal = getAnalyticImgKey(_squareDateKey, _analyticId, _imgType)

      //switch on image type
      var extension = ""
      switch(_imgType) {
        case "png":
          extension = ".png"
          break;
        case "afterPng":
          extension = "-After.png"
          break;
        case "gif":
          extension = ".gif"
          break;
      }

      getDownloadURL(ref(storage, `Backend/BackendImageProcessing/${_squareDateKey}/${IMAGEPROCESSINGKEY}/${_analyticId}${extension}`)).then(resp => {
        resolve({
          key: keyVal,
          val: resp
        })
      }).catch(err => {
        console.error(err)
        resolve({
          key: keyVal,
          val: ""
        })
      });
    })

  }

  
  function getAnalyticColor(_label){
    var fillColor = "#f9a825"
    switch(_label) {
      case "landclearing":
        fillColor = "#03A9F4"
        break;
      case "newConstruction":
        fillColor = "#f9a825"
        break;
      case "falsePositive":
        fillColor = "#212121"
        break;
      case "renovation":
        fillColor = "#9c27b0"
        break;
      default:
        fillColor = "#F44336"
        break;
    }
    return fillColor
  }

  function centerAnalytic(_bounds){
    //center map on the selected analytic
    try{
      
      const width = (_bounds.east - _bounds.west)/2
      const height = (_bounds.north - _bounds.south)/2
      

      const polygon = [
        { lat: _bounds.north + height, lng: _bounds.west + width  },
        { lat: _bounds.north + height, lng: _bounds.east - width },
        { lat: _bounds.south - height, lng: _bounds.east - width },
        { lat: _bounds.south - height, lng: _bounds.west + width }
      ];
      
      centerMap(polygon)
    }catch(err){
      console.error(err)
    }
  }

  function selectAnalyticToView(_analyicKey, _selectedLotDateSquareAnalytics){

    if(![undefined, "", null].includes(selectedLot) && ![undefined, "", null].includes(dateSliderValue) && ![undefined, "", null].includes(_analyicKey)){
      navigate(demoMode ? `/Demo/${selectedLot}/${dateSliderValue}/${_analyicKey}`: `/MapView/${selectedLot}/${dateSliderValue}/${_analyicKey}`, { replace: true });

    }

    const analyticObj = _selectedLotDateSquareAnalytics.find(e => e.analytic.key == _analyicKey)
    const bounds = analyticObj?.analytic?.bounds
    const manualLabel = analyticObj?.analytic?.manualReview?.manualLabel

    centerAnalytic(bounds)    
    setSelectedAnalyticKey(_analyicKey)

    //set the page to the selected analytic
    //get the label of the selected analytic
    var allItems = []    
    var analyticIndex = 0
    var paginationPage = 1
    toggleSidePanel(true)
    switch(manualLabel) {
      case "newConstruction":
        allItems = _selectedLotDateSquareAnalytics.filter(e => e.analytic.manualReview.manualLabel == "newConstruction")
        analyticIndex = allItems.findIndex(e => e.analytic.key == _analyicKey)
        paginationPage = Math.floor(analyticIndex / numberOfImagesPerPage) + 1
        setConstructionPage(paginationPage)
        setSidePanelTabValue(0)
        break;
      case "landclearing":
        allItems = _selectedLotDateSquareAnalytics.filter(e => e.analytic.manualReview.manualLabel == "landclearing")
        analyticIndex = allItems.findIndex(e => e.analytic.key == _analyicKey)
        paginationPage = Math.floor(analyticIndex / numberOfImagesPerPage) + 1
        setLandClearingPage(paginationPage)
        setSidePanelTabValue(1)
        break;
      case "renovation":
        allItems = _selectedLotDateSquareAnalytics.filter(e => e.analytic.manualReview.manualLabel == "renovation")
        analyticIndex = allItems.findIndex(e => e.analytic.key == _analyicKey)
        paginationPage = Math.floor(analyticIndex / numberOfImagesPerPage) + 1
        setRenovationPage(paginationPage)

        setSidePanelTabValue(2)
        break;
    }




    // //IMAGEPROCESSINGKEY
    // // check if image url already exists
    // // if(analyticPhoto3YearURL[`${_compoundKey}-${_analyicIndex}`] == undefined){

    // //   getDownloadURL(ref(storage, `BackendImageProcessing/${_compoundKey}/${IMAGEPROCESSINGKEY}/${_analyicIndex}.png`)).then(resp => {
    // //     setAnalyticPhoto3YearURL({
    // //       ...analyticPhoto3YearURL,
    // //       ...{[`${_compoundKey}-${_analyicIndex}`]: resp}
    // //     })
    // //   }).catch(err => {
    // //     console.error(err)
    // //     setAnalyticPhoto3YearURL({
    // //       ...analyticPhoto3YearURL,
    // //       ...{[`${_compoundKey}-${_analyicIndex}`]: ""}
    // //     })
    // //   });

    // // }

    // // check if image url already exists
    // if(analyticGIFURL[`${_compoundKey}-${_analyicIndex}`] == undefined){

    //   getDownloadURL(ref(storage, `BackendImageProcessing/${_compoundKey}/${IMAGEPROCESSINGKEY}/${_analyicIndex}.gif`)).then(resp => {
    //     setAnalyticGIFURL(prevState => {
    //       return {
    //               ...prevState,
    //               ...{[`${_compoundKey}-${_analyicIndex}`]: resp}
    //               }
    //     })
    //   }).catch(err => {
    //     console.error(err)
    //     setAnalyticGIFURL(prevState => {
    //       return {
    //               ...prevState,
    //               ...{[`${_compoundKey}-${_analyicIndex}`]: ""}
    //               }
    //     })
    //   });

    // }
  
  }

  function pickHigher(num1, num2) {
    const higherNum = Math.max(num1, num2);
    return Math.ceil(higherNum / 20) * 20;
  }
  
  function getFileFormat(){

    try{
      //get the current zoom level and determine the file format to use by finding the first FILEFROMAT with a minZoom that is less than the current zoom level and a maxZoom that is greater than the current zoom level
      const zoomLevel = mapRef.current.getZoom()
      const fileFormat = FILEFORMATS.find(element => element.minZoom <= zoomLevel && element.maxZoom >= zoomLevel)  
    
      return fileFormat.name
    }catch(err){
      return "png"
    }
  }

  function renderAOIElement(_element) {

    const documentId = _element?.analyticsObj?.documentId
    const analyticId = _element?.analytic?.key

    const _label = _element?.analytic?.manualReview?.manualLabel

    const gifKey = getAnalyticImgKey(documentId, analyticId, "gif")
    const afterPNGKey = getAnalyticImgKey(documentId, analyticId, "afterPng")

    var gifElement
    if(analyticPhotoURLs[gifKey] != undefined && analyticPhotoURLs[gifKey] != ""){
      gifElement = <img className='analyticsSelectorImageHolderGif' src={analyticPhotoURLs[gifKey]} />;
    }

    var photoElement
    if(analyticPhotoURLs[afterPNGKey] != undefined && analyticPhotoURLs[afterPNGKey] != ""){
      photoElement = <img className='analyticsSelectorImageHolderImg' src={analyticPhotoURLs[afterPNGKey]} />;
    }


    return  <Paper elevation={3} className='analyticsSelector' onClick={() => selectAnalyticToView(analyticId, selectedLotDateSquareAnalytics)} style={{backgroundColor: selectedAnalyticKey == analyticId ? getAOIColor(_label):'#ffffff'}}>
              <div className='analyticsSelectorImageHolder'>
                {photoElement}
                {gifElement}
              </div>
            </Paper>


  }

  function selectLowestEnabledLabelType(_showConstruction, _showLandclearing, _showRenovation){
    if(_showConstruction){
      return 0
    }else if(_showLandclearing){
      return 1
    }else if(_showRenovation){
      return 2
    }
  }

  function calculateAvailableDates(){    
    try{

      // //filter out lotDateAnalytics where analytics length is 0
      // const filteredLotDateAnalytics = Object.keys(lotDateAnalytics).filter(key => lotDateAnalytics[key].lotId == lotId && lotDateAnalytics[key].analytics.length > 0).map(key => lotDateAnalytics[key])
      const availableDates = Object.keys(_selectedLot?.availableDates[datePickerInterval]).filter(e => _selectedLot?.availableDates[datePickerInterval][e]).map(e => {
        const endYear = e.split("-")[3]
        const endMonth = e.split("-")[4] - datePickerIntervalOptions[datePickerInterval].monthInterval
        const endDay = e.split("-")[5]
        var _label = ""

        const analyticsAvailable = _selectedLot?.availableDates?.quarterAnalytics?.[e] == true

        const dateVal = toEasternTime(new Date(endYear, endMonth - 1, endDay))



        if(analyticsAvailable){
          if(dateVal.getMonth() % 3 == 0){
            _label = getQuarter(dateVal)
          }
        }else{
          if(dateVal.getMonth() == 0){
            _label = dateVal.getFullYear()
          }
        }

        return {
          value: (dateVal).getTime(),
          str: e,
          label: _label
        }
      }).sort((a, b) => a.value - b.value)
      
      return availableDates //datePickerIntervalOptions[datePickerInterval].optionsFunction()
    
    }catch(err){
      console.error(err)
      return []
    }
  }

  function calculateLotArea(_lot, _selectedLotDateSquareAnalytics){
    try{        
        const area = calculateAreaFromCoordinates(_lot.polygon)
        var totalarea = 0
        _selectedLotDateSquareAnalytics.forEach((element) => {
          const mappedPolygon = element.analytic.polygon.map((element) => {
            return { lat: element[1], lng: element[0] }
          })
          const area = calculateAreaFromCoordinates(mappedPolygon)
          totalarea += area
        })
    }catch(err){
        console.error(err)
    }

    
  }

  // RENDER //


  if(!isLoaded) {
      return <div></div>
  }
  


  const isMobile = windowWidth <= MOBILEPIXELWIDHTHRESHOLD;
  const _selectedLot = lots[selectedLot] != undefined ? lots[selectedLot]:{}
  const activeAnalytic = selectedLotDateSquareAnalytics
      .find((element) => {                            
          if(google.maps.geometry != undefined){
            const bounds = element?.analytic?.bounds
            const analyticId = element?.analytic?.key
            
            if(selectedAnalyticKey == analyticId){
              if([showConstruction ? "newConstruction":"", showLandclearing ? "landclearing":"", showRenovation ? "renovation":""].includes(element?.analytic?.manualReview?.manualLabel)){
                return true
              }
            }
          }

          return false

        })
  const dateSliderDate = toEasternTime(new Date(dateSliderValue))
  var dateMarks = calculateAvailableDates()
  
  var uniqueUsedSquares = []



  var groundOverlays
  
  try{
    groundOverlays = [0, -1, -2].map(minusYearCount => {
      
      try{

        var tempGroundOverlay

        if(selectedLot != ""){
    
          if("usedBackendSquares" in _selectedLot){
    
            //get new date from date value
            const tempDate = toEasternTime(new Date(dateSliderDate))
            const dateSliderValueMinusYear = (toEasternTime(new Date(tempDate.setFullYear(tempDate.getFullYear() + minusYearCount)))).getTime()
    
            const squares = _selectedLot?.usedBackendSquares.map(e => e.square25).map(square => {
              return {
                key: get25ImageKey(userId, selectedLot, parseBackendDateString(dateSliderValueMinusYear, MONTHINTERVAL.current, DATAMONTHINTERVAL.current), square, getFileFormat()),
                squareId: square,
                coords: backendSquareIdToLatLng(square),
                
              }
            })
            //dedupe image keys
            uniqueUsedSquares = [...new Map(squares.map(item => [item.key, item])).values()];
            tempGroundOverlay = uniqueUsedSquares.filter(e => {return (visible25Boxes.includes(e.squareId)) && Object.keys(requestedMapPngObject).includes(e.key) && mapPngObject[e?.key] != undefined}).map(key => {              
              if(mapPngObject[key?.key] != undefined){

                if(mapPngObject[key?.key] != undefined && mapPngObject[key?.key] != ""){
                  return <GroundOverlay 
                            key={key?.key}
                            url={mapPngObject[key?.key]}
                            bounds={createBounds(key?.coords?.lng, key?.coords?.lat, AOIDISTANCE)}
                            opacity={yearImgPicker == minusYearCount && showMap ? 1:0}
                            onClick={(e)=> {
                              
                            }}
                            zIndex={3000}
                            options={{
                              zIndex: 2000
                            }}/>
                }
              }

            })

            return tempGroundOverlay

          }else{

            return null

          }
        }
    
    
      }catch(err){
        console.error(err)
      }

    })
  }catch(err){
    console.error(err)
  }

  var mapGifOverlays
  const lotTimelapseAvailable = all25GifBoxes.map(e => mapGifObject[e.key]).filter(e => e != undefined && e != "").length > 0

  try{

    var tempGroundOverlay

    if(selectedLot != ""){

      if("usedBackendSquares" in _selectedLot && lotTimelapseAvailable){

        //get new date from date value
        const tempDate = toEasternTime(new Date(dateSliderDate))
        const dateSliderValueMinusYear = (toEasternTime(new Date(tempDate.setFullYear(tempDate.getFullYear())))).getTime()

        mapGifOverlays = visible25GifBoxes.map(element => {

          if(google.maps.geometry != undefined){

            const key = element.key
            const bounds = element.bounds
            const gifUrl = mapGifObject[element?.key]
            
            if(gifUrl != undefined && gifUrl != ""){
              return <GroundOverlay 
                        key={key}
                        url={gifUrl}
                        bounds={bounds}
                        opacity={showGif ? 1:0}
                        onClick={(e)=> {
                          
                        }}
                        zIndex={13001}
                        options={{
                          zIndex: 12001
                        }}/>
            }
        }

        })

        //const filteredGifs = gifs.filter(e => )

        // tempGroundOverlay = uniqueUsedSquares.filter(e => {return (visible25Boxes.includes(e.squareId)) && Object.keys(requestedMapGifObject).includes(e.key) && mapGifObject[e?.key] != undefined}).map(key => {              
        
        // })

        //return tempGroundOverlay

      }else{
        //return null
      }
    }

  }catch(err){
    console.error(err)
  }


  

  
  
  var compoundKey = ""

  try{
    compoundKey = parseBackendString(selectedLot, dateSliderValue, MONTHINTERVAL.current, DATAMONTHINTERVAL.current, true)

    
  }catch(err){
    
  }
  

  var label 
  var sidePanel
  var legendDivLocation 
  var selectedLotSubsquarepolygons = []
  if(renderBackendSquares && false){
    selectedLotSubsquarepolygons =  lots[selectedLot]?.usedBackendSquares.map(e => e.subSquare05).flat(Infinity)

  }

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 4),
    y: -(height / 4),
  })

  var googleMap = <GoogleMap           
                      zoom={mapZoom} 
                      center={location} 
                      mapContainerClassName="mapContainer"
                      mapTypeId={mapTypeId}
                      options={mapOptions}
                      onLoad={mapHandleOnLoad}
                      onCenterChanged={mapHandleCenterChanged}
                      onBoundsChanged={() => mapHandleBoundsChanged(false, false)}
                      onClick={(e)=> { 
                        selectLot("", lots)
                        // //get latitude and longitude
                        // const lng = e.latLng.lng()
                        // const lat = e.latLng.lat()

                        // //get the square id
                        // const squareId = latLngToBackendSquareId(lng, lat)

                      }}
                      // onDblClick={(e)=> {                         
                        
                      // }}
                      >
                        <Polygon
                          editable={false}
                          draggable={false}
                          paths={[{lat: 0, lng: 0}]}                  
                        />                        
                        {
                          Object.keys(lots)
                          .filter(e => lots[e]?.metaData?.active /*&& (selectedLot == "" || e == selectedLot)*/)
                          .map((key) => {
                            const lot = lots[key];
                            if(google.maps.geometry != undefined && (lot.metaData.active)){

                                return (
                                  <Polygon
                                    onLoad={(_polygon) => onLoad(_polygon, key, lots)}
                                    onClick={(() => selectLot(key, lots))}
                                    editable={false}
                                    draggable={false}
                                    paths={lot?.polygon}
                                    options={{
                                      ...rectangleOptions,
                                      fillColor: selectedLot == key ? "#4CAF50":"#E5502B",
                                      strokeColor: selectedLot == key ? "#4CAF50":"#E5502B",
                                      strokeWeight: 3,
                                      zIndex: selectedLot == key ? 1000:0
                                    }}         
                                  />
                                )
                            }
                          })          
                        }                       
                        {
                          selectedLotDateSquareAnalytics
                          .filter(element => [showConstruction ? "newConstruction":"", showLandclearing ? "landclearing":"", showRenovation ? "renovation":""].includes('label' in element?.analytic ? element?.analytic?.label : element?.analytic?.manualReview?.manualLabel))
                          .map((element) => {                           
                              if(google.maps.geometry != undefined){
                                const bounds = element?.analytic?.bounds
                                const analyticId = element?.analytic?.key

                                //get width lat lng of the square
                                const width = (bounds?.east - bounds?.west)

                                const label = 'label' in element?.analytic ? element?.analytic?.label : element?.analytic?.manualReview?.manualLabel
                                const path = 'polygon' in element?.analytic ? polygonArrayToLatLng(element?.analytic?.polygon) : createQuadrilateral(bounds?.north, bounds?.south, bounds?.east, bounds?.west)
                                
                                var zIndex = 2100
                                switch(label) {
                                  case "renovation":
                                    zIndex = 2100
                                    break;
                                  case "landclearing":
                                    zIndex = 2101
                                    break;
                                  case "newConstruction":
                                    zIndex = 2103
                                    break;
                                  
                                }

                                return (
                                  <>
                                    <Polygon          
                                      key={`${element?.analyticsObj?.lotId}-${analyticId}-analytic`}                          
                                      editable={false}
                                      draggable={false}
                                      paths={path}                                          
                                      onClick={(e)=> {
                                        selectAnalyticToView(element?.analytic.key, selectedLotDateSquareAnalytics)
                                      }}
                                      options={{
                                        ...rectangleOptions,
                                        strokeColor: getAnalyticColor(label),
                                        strokeWeight: selectedAnalyticKey == analyticId ? 5:2,
                                        fillColor: getAnalyticColor(label),
                                        fillOpacity: 0,
                                        zIndex: zIndex
                                      }}         
                                    />
                                    {
                                    //   false && selectedAnalyticKey == analyticId ? 
                                    //   <OverlayView
                                    //     // position={{ lat: bounds?.north, lng: bounds?.east }}
                                    //     bounds={{
                                    //        ne: { lat: bounds?.south, lng: bounds?.west},
                                    //        sw: { lat: bounds?.south, lng: bounds?.west}
                                    //     }}
                                        
                                    //     /*
                                    //     * 1. Specify the pane the OverlayView will be rendered to. For
                                    //     *    mouse interactivity, use `OverlayView.OVERLAY_MOUSE_TARGET`.
                                    //     *    Defaults to `OverlayView.OVERLAY_LAYER`.
                                    //     */
                                    //     mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    //     /*
                                    //     * 2. Tweak the OverlayView's pixel position. In this case, we're
                                    //     *    centering the content.
                                    //     */
                                    //     //getPixelPositionOffset={getPixelPositionOffset}
                                    //     /*
                                    //     * 3. Create OverlayView content using standard React components.
                                    //     */
                                    //   >
                                    //     <div style={{display: 'inline-block', padding: '5px', marginLeft: '-2.5px', marginTop: '-2px', borderRadius: '0px 0px 3px 3px', backgroundColor: getAnalyticColor(element?.analytic?.manualReview?.manualLabel)}}>
                                    //       <IconButton 
                                    //         onClick={(event) => {                                              
                                    //           setShowGroundOverlayGif(!showGroundOverlayGif)
                                    //           event.preventDefault()
                                    //           event.stopPropagation()
                                    //         }}
                                    //         color={'white'} 
                                    //         style={{position: 'relative'}}>
                                    //         <GifBoxIcon style={{fontSize: '1.5em', opacity: showGroundOverlayGif ? 1:0, transition: '.5s all',}} />
                                    //         <GifIcon style={{position: 'absolute', opacity: showGroundOverlayGif ? 0:1, transition: '.5s all',}}/>       
                                    //       </IconButton>
                                    //     </div>                          
                                    // </OverlayView>:<></>
                                    }
                                  </>                                  
                                )
                              }                            
                          })
                        }
                        {groundOverlays}       
                        {mapGifOverlays}

                        <GroundOverlay 
                            key={"NashvilleDemo"}
                            url={`http://localhost:3000${nashvilleDemo}`}
                            bounds={{
                              north: 36.216,
                              south: 36.1198,
                              east: -86.713,
                              west: -86.88792
                            }}
                            opacity={showSample && selectedLot == "wMctuJvCcWnZRbEfDO27" ? 1 : 0}
                            zIndex={4000}
                            options={{
                              zIndex: 4000
                            }}/>
                    </GoogleMap>

  const selectedQuarter = getQuarter(toEasternTime(new Date(dateSliderValue))).split(" ");

  var downloadExcelButton    
  var selectedAOI
  var dateSlider
  var mobileDateSlider

  // if(isMobile){
  //   downloadExcelButton = <IconButton 
  //                             className='selectedAOISave'
  //                             color={"secondary"}                                
  //                             onClick={() => exportToExcel(analytics, `${selectedLot} ${getQuarter(toEasternTime(new Date(dateSliderValue))).split(" ")[1]} ${getQuarter(toEasternTime(new Date(dateSliderValue))).split(" ")[0]}`)}
  //                             aria-label="">
  //                             <InsertDriveFileIcon /> 
  //                         </IconButton>       
    
  //   mobileDateSlider =  <FormControl sx={{ m: 1, minWidth: 120, margin: '15px', marginTop: '15px', backgroundColor: '#ffffff', }} size="small">
  //                         <InputLabel id="demo-select-small">Quarter</InputLabel>
  //                         <Select
  //                           labelId="quarter"
  //                           id="quarter"
  //                           value={dateSliderValue}
  //                           label="Quarter"
  //                           disabled={selectedLot == "" ? true:false}
  //                           onChange={(e) => { handleDateSliderChange(e.target.value)}}
  //                         >        
  //                           {
  //                             dateMarks.map(e => {
  //                               return (
  //                                 <MenuItem value={e.value}>{e.label}</MenuItem>
  //                               )
  //                             })
  //                           }                                        
  //                         </Select>
  //                       </FormControl>

  // }else{
        
  const currentDate = toEasternTime(new Date());
  const lastFullMonth = toEasternTime(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)); 

  dateSlider =  <Paper className='mapContainerSliderDiv' data-disable-interaction="true">       
                  {
                    dateMarks.length > 0 ? (
                      <>
                        <Slider
                          step={null}
                          value={dateSliderValue}
                          onChange={(e) => handleDateSliderChange(e.target.value)}
                          marks={dateMarks}
                          min={dateMarks[0].value}
                          max={lastFullMonth.getTime()}
                          color="secondary" 
                          disabled={selectedLot == "" ? true:false}
                          style={{flex: 1,}}
                        />                      
                        <Collapse 
                          in={selectedLot != "" && pricingSchedule[_selectedLot.pricingPlan]?.appFeatures?.viewQuarterlyMaps == true && pricingSchedule[_selectedLot.pricingPlan]?.appFeatures?.viewMonthlyMaps == true}>
                          <Button
                            variant='contained'
                            color='secondary'
                            startIcon={<CalendarMonthIcon />}
                            onClick={() => {
                              changeDateSliderInterval(datePickerIntervalOptions[datePickerInterval].nextInterval)
                            }}
                            style={{marginLeft: '15px', width: '200px', justifyContent: 'center',  }}>
                              {datePickerIntervalOptions[datePickerInterval]?.title}
                          </Button>
                        </Collapse>
                      </>
                    ):selectedLot != "" ? (
                      <div>This lot is still processing</div>
                    ):(
                      <div></div>
                    )
                  }

                </Paper>
  // }

  const noAnalyticsAvailable = Object.keys(selectedLotDateSquareAnalytics).length == 0
  const analyticObj = selectedLotDateSquareAnalytics.find(e => e.analytic.key == selectedAnalyticKey)

  if(false && sidePanelOpen && selectedLot != "" && !noAnalyticsAvailable){

    var paginationHolder
    var mobilePaginationHolder
    
    legendDivLocation = '865px'
    paginationHolder = <div className='paginationSelectorHolder'>
                          <Pagination count={Math.ceil(selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "newConstruction").length/numberOfImagesPerPage)} page={constructionPage} onChange={(event, value) => {setConstructionPage(value);}} style={{display: sidePanelTabValue == 0 ? 'flex':'none'}} size={isMobile?"small":""} />
                          <Pagination count={Math.ceil(selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "landclearing").length/numberOfImagesPerPage)} page={landClearingPage} onChange={(event, value) => {setLandClearingPage(value);}} style={{display: sidePanelTabValue == 1 ? 'flex':'none'}} size={isMobile?"small":""} />   
                          <Pagination count={Math.ceil(selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "renovation").length/numberOfImagesPerPage)} page={renovationPage} onChange={(event, value) => {setRenovationPage(value);}} style={{display: sidePanelTabValue == 2 ? 'flex':'none'}} size={isMobile?"small":""} />                                    
                        </div>
    
    

    // var photo3YearURL = ""
    // if(analyticPhoto3YearURL[`${compoundKey}-${selectedAnalyticKey}`] != undefined && analyticPhoto3YearURL[`${compoundKey}-${selectedAnalyticKey}`] != ""){
    //   photo3YearURL = <img className='photoViewAreaGif' src={analyticPhoto3YearURL[`${compoundKey}-${selectedAnalyticKey}`]} />;
    // }
    // var gifURL = ""
    // if(analyticGIFURL[`${compoundKey}-${selectedAnalyticKey}`] != undefined && analyticGIFURL[`${compoundKey}-${selectedAnalyticKey}`] != ""){
    //   gifURL = <img className='photoViewAreaGif' src={analyticGIFURL[`${compoundKey}-${selectedAnalyticKey}`]} />;
    // }
    // var areaOfChange 
    // try{      
    //   areaOfChange = Math.floor(analytics[selectedAnalyticKey].coordinates.pixelsChanged * 100 * 10.7639).toLocaleString();
    // }catch(err){}
    
    // try{
    //   label = analytics[selectedAnalyticKey].coordinates.label
    // }catch(err){}
    
    // if(isMobile){
    //   selectedAOI = ""
    // }else{
    //   selectedAOI = <Paper className='activeAOIArea' style={{borderTopColor: label == "landclearing" ? "#03A9F4": label == "newConstruction" ? "#ffd600":"#212121"}}>                    
    //                     <div className='activeAOIAreaUI' onClick={() => selectAnalyticToView(selectedAnalyticKey, compoundKey, imageProcessingJSON, true)} style={{}}>
    //                       <h3 className='activeAOIAreaUIKey'>{`Area ID: ${selectedAnalyticKey}`}</h3> 
    //                       <p className='activeAOIAreaUIData'>Area of Change: <br/><strong>{areaOfChange} sq ft</strong></p>
    //                     </div>                    
    //                     <div className='photoViewArea'>
    //                       {photo3YearURL}
    //                       <div className='photoViewAreaOverlay'>
    //                         <h4>{`${selectedQuarter[0]} ${parseInt(selectedQuarter[1]) - 2}, ${selectedQuarter[0]} ${parseInt(selectedQuarter[1]) - 1}, ${selectedQuarter[0]} ${parseInt(selectedQuarter[1])}`}</h4>
    //                       </div>
    //                     </div>
    //                     <div className='gifViewArea'>
    //                       {gifURL}
    //                       <div className='photoViewAreaOverlay'>
    //                         <h4>{`${selectedQuarter[0]} ${parseInt(selectedQuarter[1]) - 2} to ${selectedQuarter[0]} ${parseInt(selectedQuarter[1])}`}</h4>
    //                       </div>
    //                     </div>
    //                   </Paper>
    // }

    var toolbarHeaderElement

    if(analyticObj == undefined) {
      toolbarHeaderElement = (
        <div className={`toolbarSelectedElementHolder toolbarSelectedElementHolderNoContent`}>
          No Analytic Selected
        </div>
      )  
    }else {

      const analyticId = analyticObj?.analytic?.key
      const documentId = analyticObj?.analyticsObj?.documentId

      const gifKey = getAnalyticImgKey(documentId, analyticId, "gif")
      const pngKey = getAnalyticImgKey(documentId, analyticId, "png")

      //get fist ten characters of the analytic id
      const analyticIdShort = analyticId.substring(0, 15)

      var pngElement = <div className='toolbarSelectedElementHolderImage'></div>
      if(analyticPhotoURLs[gifKey] != undefined && analyticPhotoURLs[gifKey] != ""){
        pngElement = <div className='toolbarSelectedElementHolderImage' ><img src={analyticPhotoURLs[pngKey]} /></div>;
      }

      var gifElement = <div className='toolbarSelectedElementHolderGif'></div>
      if(analyticPhotoURLs[gifKey] != undefined && analyticPhotoURLs[gifKey] != ""){
        gifElement = <div className='toolbarSelectedElementHolderGif' ><img src={analyticPhotoURLs[gifKey]} /></div>;
      }


      toolbarHeaderElement = (
        <div className={`toolbarSelectedElementHolder`}>
          <div className='toolbarSelectedElementHolderDetails'>
            <p className='toolbarSelectedElmentHolderLabel'>Analytic ID:</p>
            <h3 className='toolbarSelectedElementHolderKey'>{`${analyticIdShort}`}</h3>
            <p className='toolbarSelectedElmentHolderLabel'>Label:</p>
            <h3 className='toolbarSelectedElementHolderKey'>{`${labelTypes[analyticObj?.analytic?.manualReview?.manualLabel]?.label}`}</h3>
          </div>
          {pngElement}
          {gifElement}
          
        </div>
      )
  }

    sidePanel = <Paper className='toolbar' elevation={4} style={{marginLeft: toolbarMarginLeft}} >
                  {mobilePaginationHolder}
                  <div className='toolbarScrollViewHolder'>
                    <div style={{ width: '100%', flex: 1, display: 'flex', flexDirection: 'column' }}>
                      {toolbarHeaderElement}
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <AppBar position="static">
                          <Tabs 
                            value={sidePanelTabValue} 
                            variant="fullWidth" 
                            indicatorColor="primary"
                            textColor="inherit"
                            onChange={(event, newValue) => setSidePanelTabValue(newValue) } >
                            <Tab label={`Construction  (${selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "newConstruction").length})`} {...a11yProps(0)} style={{backgroundColor: getAnalyticColor("newConstruction"), color: '#212121', display: showConstruction ? 'flex':'none'}}/>
                            <Tab label={`Land Clearing  (${selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "landclearing").length})`} {...a11yProps(1)} style={{backgroundColor: getAnalyticColor("landclearing"), color: '#212121', display: showLandclearing ? 'flex':'none'}}/>   
                            {/* <Tab label={`Renovation  (${selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "renovation").length})`} {...a11yProps(2)} style={{backgroundColor: getAnalyticColor("renovation"), color: '#212121', display: showRenovation ? 'flex':'none'}}/> */}
                          </Tabs>
                        </AppBar>
                      </Box>
                      <div style={{display: !showLandclearing && !showConstruction && !showRenovation ? 'none':'flex', flex: 1, overflow: 'auto',}}>
                        <div  
                          index={0} 
                          className="toolbarScrollView"
                          style={{display: sidePanelTabValue == 0 ? 'flex':'none'}}>
                            <div style={{height: '15px', width: '100%', float: 'left'}}></div>
                            {
                              paginationFilter(selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "newConstruction"), constructionPage).map(element => {
                                
                                return renderAOIElement(element)
                              })
                            }                               
                        </div>
                        <div                        
                          index={1}
                          className="toolbarScrollView"
                          style={{display: sidePanelTabValue == 1 ? 'flex':'none',}}>
                            <div style={{height: '15px', width: '100%', float: 'left'}}></div>
                            {
                              paginationFilter(selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "landclearing"), landClearingPage).map(element => {
                                return renderAOIElement(element)
                              })
                            }   
                        </div>                        
                        <div                        
                          index={1}
                          className="toolbarScrollView"
                          style={{display: sidePanelTabValue == 2 ? 'flex':'none',}}>
                            <div style={{height: '15px', width: '100%', float: 'left'}}></div>
                            {
                              paginationFilter(selectedLotDateSquareAnalytics.filter(element => element?.analytic?.manualReview?.manualLabel == "renovation"), renovationPage).map(element => {
                                return renderAOIElement(element)
                              })
                            }                             
                        </div>
                      </div>   
                      {paginationHolder}  
                    </div>
                                
                  </div>

                  
                  {/* 
                    <Button onClick={() => clearCache()}>Clear Cache</Button>
                  */}
                </Paper>
                
  }else if (false && sidePanelOpen && noAnalyticsAvailable){

    var dateSelctorArea 
    if(isMobile){
      legendDivLocation = '10px'
      dateSelctorArea = <div className='paginationSelectorHolder' style={{justifyContent: 'flex-end'}}>
                          {mobileDateSlider}
                        </div>
    }else{
      legendDivLocation = '895px'
    }

    sidePanel = <Paper className='toolbar' elevation={4} style={{marginLeft: toolbarMarginLeft}} >                  
                  <div className='toolbarNoContentHolder'>
                    {dateSelctorArea}
                    <div className='toolbarNoContent'>
                      <h3>No Analytics Available for this quarter</h3>
                    </div>
                  </div>
                </Paper>
  }else{

    if(isMobile){
      legendDivLocation = '10px'
    }else{
      legendDivLocation = '10px'
    }

  }


  


  var loadingOverlayView

  if(Object.keys(lots).length == 0) {
    loadingOverlayView =  <div className="loadingOverlayView">
                            <CircularProgress color="secondary" />
                            <h2 >Loading Data</h2>
                          </div>

  }

  
  var viewElements
  var legendToggle
  

  if(isMobile){
    viewElements =  <>
                      {googleMap}
                      {mobileDateSlider}
                    </>
    legendToggle =  <div className='legendDivToggle' onClick={() => setLegendShown(!legendShown)}>
                      <ExpandLessIcon color={"primary"} style={{rotate: !legendShown ? '180deg':'0deg', transition: 'all 1s ease-in-out'}}/>
                      
                    </div>
  }else{
    viewElements =  <>
                      {sidePanel}
                      {googleMap}
                    </>  
  }

  

  return (
    <>
    <div className='pageContent' style={{backgroundColor: isMobile ? '#ffffff':'inherit'}}>       
      <Paper
        elevation={4}
        className='mapViewHeader'>
          <Autocomplete
            disablePortal
            
            id="combo-box-demo"
            value={selectedLot == "" ? null:selectedLot}
            getOptionLabel={(option) => {
              //limit to 20 characters
              const labelStr = `${lots[option]?.displayName}`
              //add elipses if string is longer than 30 characters but allow for 2 extra characters if the elipsis is not needed
              return labelStr.length > 35 ? `${labelStr.substring(0, 32)}...`:labelStr


            }}
            onChange={(event, newValue) => {
              selectLot(newValue, lots)
            }}
            options={Object.keys(lots).filter(e => lots[e]?.metaData?.active)}
            sx={{ width: 300 }}
            renderInput={(params) => {
             return <TextField 
                      {...params} 
                      label="Selected Lot" 
                      />
            }}
          />             
          {
            true || isMobile ? null : (
              <Collapse 
                in={selectedLot != "" && datePickerInterval == "quarter" && pricingSchedule[_selectedLot.pricingPlan]?.appFeatures?.viewAnalytics == true}>
                <Button
                  variant='contained'
                  color='secondary'
                  startIcon={<ViewInAr />}
                  onClick={() => {
                    toggleSidePanel(!sidePanelOpen)
                  }}
                  style={{marginLeft: '15px', width: '175px', justifyContent: 'flex-start', }}>
                    {!sidePanelOpen ? 'View Analytics':'Hide Analytics'}
                </Button>
              </Collapse>
            )
          }
          
          <div className="currentQuarter">
            <p>Viewing:</p>
            <h4 >
              {getQuarter(toEasternTime(new Date(dateSliderValue)))}
            </h4>
          </div>
          <div className="currentQuarter">
            <p>Analytics:</p>
            <h4 >
              {noAnalyticsAvailable ? "Not Available":"Available"}
            </h4>
          </div>
          
          
          <div style={{flex: 1}}></div>
          <Button
            variant='contained'
            color='secondary'
            startIcon={<DownloadIcon />}
            onClick={() => exportToGeoJson()}
            style={{display: noAnalyticsAvailable ? 'none':'flex', }}>
              Download Analytics
          </Button>
          
          
      </Paper>
      <div className='mapContainerDiv'>   
        {loadingOverlayView}     
        {viewElements}    
        <Paper className={`legendDiv ${isMobile ? 'important-border-radius':''}`} elevation={2} style={{left: '10px', display: !userInStreetView ? 'block':'none' }}>          
                    
          <div style={{flexDirection: 'column'}}>
            <div className={'legendDivMapTypeSelector'} style={{display: 'flex'}}>
              <Button
                onClick={() => {setGoogleMapType('roadmap')}}
                variant={'text'}    
                color={mapTypeId == 'roadmap' ? 'secondary':'primary'}                
                style={{flex: 1, borderRadius: '0px', height: '40px',}}>
                  Default
              </Button>
              <Button
                onClick={() => {setGoogleMapType('satellite')}}
                variant={'text'}    
                color={mapTypeId == 'satellite' ? 'secondary':'primary'}
                style={{flex: 1, borderRadius: '0px', height: '40px',}}>
                  Satellite
              </Button>              
            </div>            
            <FormGroup style={{maxHeight: isMobile && !legendShown ? '0px':'350px', opacity: isMobile && !legendShown ? 0:1, overflow: 'hidden', padding: selectedLot == "" || !legendShown ? '0px 10px': '10px', paddingTop: '0px', transition: isMobile ? 'all 1s ease-in-out, opacity 0.3s ease-out':''}}>                                  
              <FormControlLabel 
                control={<Switch 
                            color="secondary" 
                            checked={showMap}
                            onChange={(e) => {
                              setShowMap(e.target.checked)

                            }}
                            size="small"/>
                        } 
                label="Lot Imagery" 
                style={{
                        margin: 0,
                        display: selectedLot == "" ? 'none':'block'                      
                      }}/>
              <FormControlLabel 
                control={<Switch 
                            color="secondary" 
                            checked={showGif && lotTimelapseAvailable}
                            disabled={!lotTimelapseAvailable}
                            onChange={(e) => {
                              setShowGif(e.target.checked)
                              showGiftRef.current = e.target.checked
                              mapHandleBoundsChanged(true, false)
                            } }
                            size="small"/>
                        } 
                label="Lot Timelapse" 
                style={{
                        margin: 0,
                        display: selectedLot == "" ? 'none':'block'                      
                      }}/>
              <FormControlLabel 
                control={<Switch 
                            color="secondary" 
                            checked={showSample && lotTimelapseAvailable}
                            disabled={!lotTimelapseAvailable}
                            onChange={(e) => {
                              setShowSample(e.target.checked)                              
                            } }
                            size="small"/>
                        } 
                label="Sample Imagery" 
                style={{
                        margin: 0,
                        display: selectedLot != "wMctuJvCcWnZRbEfDO27" ? 'none':'block'                      
                      }}/>
              <h5 style={{margin: 0, marginTop: '5px', fontWeight: 500, color: showMap ? '#000000':'#757575', display: selectedLot == "" ? 'none':'block' }}>Quick Review</h5> 
              <Stack 
                spacing={2} 
                direction="row" 
                sx={{ mb: 1 }} 
                alignItems="center" 
                style={{paddingBottom: 15, paddingLeft: '18px', paddingRight: '18px', display: 'flex', display: selectedLot == "" ? 'none':'block' }}>                             
                <Slider 
                  size="small" 
                  aria-label="Year Picker" 
                  color="secondary" 
                  value={yearImgPicker} 
                  disabled={!showMap}
                  min={-2}
                  step={1}
                  max={0}
                  marks={[
                    {
                      value: -2,
                      label: dateSliderDate.getFullYear() - 2,
                    },
                    {
                      value: -1,
                      label: dateSliderDate.getFullYear() - 1,
                    },
                    {
                      value: 0,
                      label: dateSliderDate.getFullYear(),
                    }
                  ]}
                  onChange={(event, number) => { 

                    if("usedBackendSquares" in lots[selectedLot]){
                      //get new date from date value
                      const tempDate = toEasternTime(new Date(dateSliderDate))
                      const minusYearTime = (toEasternTime(new Date(tempDate.setFullYear(tempDate.getFullYear() + number)))).getTime()
                      getRequiredPngs(lots, selectedLot, minusYearTime)
                    }
                    setYearImgPicker(number)
                  }}
                  style={{margin: 0}}
                  sx={{
                    '& .MuiSlider-markLabel': {
                      color: !showMap ? theme.palette.disabled.main:theme.palette.primary.main,
                    },
                  }}/>
              </Stack>        
              <div style={{height: 1, width: '100%', backgroundColor: '#212121', marginTop: 5, marginBottom: 10, display: selectedLot == "" ? 'none':'block'}}></div>
              <h5 style={{margin: 0, fontWeight: 500, display: selectedLot == "" ? 'none':'block', color: showMap ? '#000000':'#757575'}}>Set Brightness</h5>
              <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center" style={{marginBottom: 0, display: selectedLot == "" ? 'none':'flex'}}>
                <IconButton 
                  onClick={() => changeBrightness(brightness - .5)} 
                  disabled={!showMap}
                  style={{margin: 0}}>
                  <Brightness5Icon 
                    fontSize="small" 
                    color={!showMap ? "disabled":"primary"}/>
                </IconButton>
                <Slider 
                  size="small" 
                  aria-label="Brightness" 
                  color="primary" 
                  value={brightness} 
                  disabled={!showMap}
                  min={0}
                  step={.5}
                  max={10}
                  onChange={(event, number) => { 
                    changeBrightness(number)
                  }}
                  style={{margin: 0}}/>
                <IconButton 
                  onClick={() => changeBrightness(brightness + .5)} 
                  disabled={!showMap}
                  style={{margin: 0}}>
                  <Brightness7Icon 
                    fontSize="small" 
                    color={!showMap ? "disabled":"primary"}/>
                </IconButton>
              </Stack>


              <div style={{height: 1, width: '100%', backgroundColor: '#212121', marginTop: 5, marginBottom: 5, display: noAnalyticsAvailable ? 'none':'block'}}></div>
              <FormControlLabel control={<Switch 
                                            color="newConstruction" 
                                            checked={showConstruction}
                                            onChange={(e) => {
                                              setShowConstruction(e.target.checked)
                                              if(e.target.checked){
                                                setSidePanelTabValue(0)
                                              }else{
                                                if(sidePanelTabValue == 0){
                                                  setSidePanelTabValue(selectLowestEnabledLabelType(false, showLandclearing, showRenovation))                                  
                                                }
                                              }
                                            }}
                                            size="small"/>
                                        } label="Construction" style={{margin: 0, display: noAnalyticsAvailable ? 'none':'block'}}/>          
              <FormControlLabel control={<Switch 
                                            color="landclearing" 
                                            checked={showLandclearing}
                                            onChange={(e) => {
                                              setShowLandclearing(e.target.checked)
                                              if(e.target.checked){
                                                setSidePanelTabValue(1)
                                              }else{
                                                if(sidePanelTabValue == 1){
                                                  setSidePanelTabValue(selectLowestEnabledLabelType(showConstruction, false, showRenovation))                                                
                                                }
                                              }
                                            }}
                                            size="small"/>
                                        } label="Land Clearing" style={{margin: 0, display: noAnalyticsAvailable ? 'none':'block'}}/>        
              {/* <FormControlLabel control={<Switch 
                                            color="renovation" 
                                            checked={showRenovation}
                                            onChange={(e) => {
                                              setShowRenovation(e.target.checked)
                                              if(e.target.checked){
                                                setSidePanelTabValue(2)
                                              }else{
                                                if(sidePanelTabValue == 2){
                                                  setSidePanelTabValue(selectLowestEnabledLabelType(showConstruction, showLandclearing, false))                                             
                                                }
                                              }
                                            }}
                                            size="small"/>
                                        } label="Renovation" style={{margin: 0, display: noAnalyticsAvailable ? 'none':'block'}}/>        
               */}
            </FormGroup>   
          </div>
          {legendToggle}
        </Paper>    
      </div>
      {dateSlider}      
    </div>

    </>
  )

}


export default MapView