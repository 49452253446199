import React from 'react'
import './Footer.css'
import icon from '../../assets/advent-labs-icon.png'
import logo from '../../assets/Logo_withtagline.svg'


export default function Footer() {
    //get current year
    const currentYear = new Date().getFullYear()
    return (
        <div className="footer">
            <div className="footerDivider"></div>
            <div className="footerContent">
                <div className="footerAction">                   
                    <div className="footerTitleHolder">
                        <div className="footerTitle">
                            <img src={logo} alt="Logo" className="footerLogo"/>     
                        </div>
                        
                    </div>
                                
                </div>
                <div style={{flex: 1}}></div>
                <div className="footerLegal">
                    © {currentYear} Lot Eagle. All Rights Reserved
                </div>
            </div>
            
        </div>
    )
}
