import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './ViewLot.css'
import { GoogleMap, useLoadScript, Marker, Polygon, GroundOverlay, OverlayView } from '@react-google-maps/api';
import googleMapsStyle from '../../assets/googleMapsStyle.json'
import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Tabs, Tab, CircularProgress, Divider, TableContainer, 
    Table, TableHead, TableRow, TableCell, TableBody, Chip, Skeleton, Dialog, DialogTitle, 
    Alert, DialogContent, DialogActions, DialogContentText, } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import { calculateSqurareKmFromSquareMeters } from '../../functions/calculateSquareKmFromSquareMeters';
import { subscriptionStatuses } from '../SubscriptionStatuses/SubscriptionStatuses';
import { CardElement, useStripe, useElements, } from '@stripe/react-stripe-js';
import { fetchFromAPI } from '../../functions/fetchFromAPI';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import LayersIcon from '@mui/icons-material/Layers';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import DataDisplay from '../DataDisplay/DataDisplay';
import WidgetsIcon from '@mui/icons-material/Widgets';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CropFreeIcon from '@mui/icons-material/CropFree';
import DownloadIcon from '@mui/icons-material/Download';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import StartIcon from '@mui/icons-material/Start';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import pricingSchedule from '../../assets/pricingSchedule.json';
import MenuIcon from '@mui/icons-material/Menu';
import EventIcon from '@mui/icons-material/Event';
import CropDinIcon from '@mui/icons-material/CropDin';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PieChartIcon from '@mui/icons-material/PieChart';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

import { HighlightOff } from '@mui/icons-material';

import { formatAsDollar } from '../../functions/formatAsDollar';
import { create } from '@mui/material/styles/createTransitions';
import HeaderTitleEditable from '../HeaderTitleEditable/HeaderTitleEditable';

function ViewLot(props) {

    const firestore = firebase.firestore();
    const orgObj = props.organizationObj

    const adminMode = props.adminMode
    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const { lotId, tab } = useParams()
    const stripe = useStripe();

    const mapRef = React.useRef(null);
    const [mapZoom, setMapZoom] = useState(15) //default zoom

    const [lotDisplayName, setLotDisplayName] = useState("")
    const [lotCreatedDate, setLotCreatedDate] = useState(0)
    const [lotTotalArea, setLotTotalArea] = useState(0)
    const [lotExactArea, setLotExactArea] = useState(0)

    const [editLotTitleLoading, setEditLotTitleLoading] = useState(false)

    const [polygonBounds, setPolygonBounds] = React.useState([{lat: 0, lng: 0}]);
    const [location, setLocation] = useState({lat: 28.5015, lng: -81.38541,})
    
    const [fbBillingObj, setFbBillingObj] = useState(null)
    const [billingLoading, setBillingLoading] = useState(true)

    const [stripeSubscriptionObj, setStripeSubscriptionObj] = useState(null)
    const [stripeBillingLoading, setStripeBillingLoading] = useState(true)
    const [stripeInvoiceArray, setStripeInvoiceArray] = useState([])
    
    const [stripePaymentMethods, setStripePaymentMethods] = useState(null)
    const [stripePaymentMethodsLoading, setStripePaymentMethodsLoading] = useState(true)
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
    const [showSelectedPaymentMethodDialog, setShowSelectedPaymentMethodDialog] = useState(false)

    const [openCancelSubscriptionDialog, setOpenCancelSubscriptionDialog] = useState(false)
    const [openReactivateSubscriptionDialog, setOpenReactivateSubscriptionDialog] = useState(false)

    const navigate = useNavigate()


    const tabNames = ["View", "Subscription"]

    const [selectedTab, setSelectedTab] = useState(tabNames.indexOf(tab) != -1 ? tabNames.indexOf(tab) : 0)


    const rectangleOptions = {
        fillColor: "#000000",
        fillOpacity: .4,
        strokeColor: "#000000",
        strokeOpacity: 1,
        strokeWeight: 2,
        zIndex: 1
    }

    const mapOptions = {
        styles: googleMapsStyle
    }

    const { isLoaded } = useLoadScript({    
        googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"
     })

    const stripeBillingDateOptions = { 
        year: 'numeric', 
        month: 'long', 
        day: 'numeric',
        timeZone: 'UTC', 
    }

    const invoiceStatuses = {
        draft: {
            label: <Chip size='small' color='stripeYellow' label='Draft'/>,
            action: () => {},
        },
        deleted: {
            label: <Chip size='small' color='stripeRed' label='Deleted'/>,
            action: () => {},
        },
        open: {
            label: <Chip size='small' color='stripeYellow' label='Open'/>,
            action: (_invoiceId) => { return <Button variant='contained' size='small' color='stripeGreen' startIcon={<AutorenewIcon />} onClick={() => {retrySubscriptionInvoice(_invoiceId)}}>Retry Payment</Button>},
        },
        paid: {
            label: <Chip size='small' color='stripeGreen' label='Paid'/>,
            action: () => {},
        },
        uncollectible: {
            label: <Chip size='small' color='stripeRed' label='Uncollectible'/>,
            action: (_invoiceId) => { return <Button variant='contained' size='small' color='stripeGreen' startIcon={<AutorenewIcon />} onClick={() => {retrySubscriptionInvoice(_invoiceId)}}>Retry Payment</Button>},
        },
        void: {
            label: <Chip size='small' color='stripeGray' label='Void'/>,
            action: () => {},
        },
    }



    useEffect(() => {
        if(orgObj?.selectedOrganization != null){
            getFirebaseBillingInfo()            
        }
    }, [])

    useEffect(() => {
        // if the user orgObj?.userIsOrgSuperAdmin then allow them to see tab 1
        if(selectedTab === 1){
            if(orgObj?.userIsOrgSuperAdmin){                
                if(stripePaymentMethods == null && stripeSubscriptionObj?.status != 'canceled'){
                    getOrgPaymentMethods()
                }

            }else{
                setSelectedTab(0)
            }
        }

        if(orgObj?.userIsOrgSuperAdmin){
            if(stripeSubscriptionObj == null){
                getStripeBillingInfo(false)
            }
        }

    }, [selectedTab, fbBillingObj])



    React.useEffect(() => {

        setTimeout(() => {
            if (mapRef.current) {
                const bounds = new window.google.maps.LatLngBounds();
          
                polygonBounds.forEach(coord => {
                  bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
                });
          
                mapRef.current.panToBounds(bounds);
                mapRef.current.fitBounds(bounds);

                setMapZoom(mapRef.current.getZoom())
                //set map center
                setLocation(mapRef.current.getCenter())
              }            
        }, 5);
      }, [polygonBounds, selectedTab]);
    
    React.useEffect(() => {

        if (mapRef.current) {
            //set map zoom
            setMapZoom(mapRef.current.getZoom())
            //set map center
            setLocation(mapRef.current.getCenter())
        }

    }, [selectedTab])


    

    function getFirebaseBillingInfo(){

        setBillingLoading(true)
        var ref = firestore.collection('Billing').doc(orgObj?.selectedOrganization).collection('Lots').doc(lotId);        
        ref.onSnapshot((doc) => {           
            setBillingLoading(false) 
            if(doc.exists){
                var billingObj = doc.data()

                setFbBillingObj(billingObj)

                //set all of the variables
                setLotDisplayName(billingObj.displayName)
                setLotCreatedDate(billingObj?.dateCreated?.seconds * 1000)
                setLotTotalArea(billingObj.totalArea)
                //round to two decimal places
                setLotExactArea(calculateSqurareKmFromSquareMeters(billingObj.exactArea))
                setPolygonBounds(billingObj.polygon)
                
                
            }else{ 
                
                //navigate to all lots
                //navigate('/AllLots')
                createAlert("error", "Lot not found")
            }
        })        
    }

    function getOrgPaymentMethods() {

        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
        }

        fetchFromAPI("organization/wallet/listCards", {method: 'POST', body: fetchBody})
        .then((data) => {
            if(data?.length > 0){
                setStripePaymentMethods(data)

            }else{
                setStripePaymentMethods([])
            }
        })
        .catch((error) => {
            console.error("Error getting payment methods: ", error);
            const message = error.message || "There was an error getting the payment methods"
            setStripePaymentMethods([])
            createAlert("error", message)
        })
        .finally(() => {
            setStripePaymentMethodsLoading(false)
        })
    }

    function getStripeBillingInfo(showPaymentMethodsUi){
        setStripeBillingLoading(true)
        if(fbBillingObj == null){
            return
        }else if(fbBillingObj?.subscriptionId == undefined){
            return
        }

        const dataObj = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: fbBillingObj?.subscriptionId
        }

        //fetch the stripe billing info
        fetchFromAPI('organization/subscription/get', {method: 'POST', body: dataObj})
        .then((data) => {
            const { subscription, invoices } = data

            setStripeSubscriptionObj(subscription)
            setStripeInvoiceArray(invoices)
            setStripeBillingLoading(false)
            if(showPaymentMethodsUi){
                setStripePaymentMethodsLoading(false)
            }
        })
        .catch((error) => {
            console.error("Error getting lot subscription: ", error);
            const message = error.message || "There was an error getting the lot subscription"
            createAlert("error", message)

        })

    }

    function retrySubscriptionInvoice(_invoiceId){

        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: fbBillingObj?.subscriptionId,
            invoiceId: _invoiceId
        }

        setStripeBillingLoading(true)
        fetchFromAPI("organization/subscription/retryInvoicePayment", {method: 'POST', body: fetchBody})
        .then(async (data) => {
            const paymentIntent = data
            
            if(paymentIntent.status == "success"){
                createAlert("success", "Invoice paid successfully")
                getStripeBillingInfo(false)
            }else if(paymentIntent.status == "requires_confirmation" || paymentIntent.status == "requires_action"){
                
                const { error } = await stripe.confirmCardPayment(paymentIntent.client_secret);

                if(error){
                    console.error("Error retrying invoice payment: ", error);
                    createAlert("error", error.message)
                    setStripeBillingLoading(false)
                }else{
                    createAlert("success", "Invoice paid successfully")
                    getStripeBillingInfo(false)
                }

            }else{
                console.error("Error retrying invoice payment: ", paymentIntent);
                createAlert("error", "There was an error retrying the invoice payment")
                setStripeBillingLoading(false)
            }
            
        })
        .catch((error) => {
            console.error("Error retrying invoice payment: ", error);
            const message = error.message || "There was an error retrying the invoice payment"
            createAlert("error", message)
            setStripeBillingLoading(false)
        })


    }
    
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    function downloadGeoJson(){

        const polygon = fbBillingObj?.polygon
        const polygonArray = []
        polygon.forEach(point => {
            polygonArray.push([point.lng, point.lat])
        })
        //make sure the first and last point are the same
        //check if the first and last point are the same
        if(polygonArray[0][0] != polygonArray[polygonArray.length - 1][0] || polygonArray[0][1] != polygonArray[polygonArray.length - 1][1]){
            polygonArray.push(polygonArray[0])
        }

        const geoJson = {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "properties": {},
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [polygonArray]
                    }
                }
            ]
        }
        const blob = new Blob([JSON.stringify(geoJson)], {type: "application/json"});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${lotDisplayName}.geojson`;
        document.body.appendChild(a);
        a.click();
        a.remove();

    }


    function changeTabs(_newTab){
        setSelectedTab(_newTab)

    }

    function updateSubscriptionDefaultPaymentMethod(){

        setStripePaymentMethodsLoading(true)
        setStripeBillingLoading(true)
        setShowSelectedPaymentMethodDialog(false)
        setTimeout(() => {
            setSelectedPaymentMethod(null)                
        }, 100);
        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: fbBillingObj?.subscriptionId,
            paymentMethodId: selectedPaymentMethod.id
        }
        fetchFromAPI("organization/subscription/updatePaymentMethod", {method: 'POST', body: fetchBody})
        .then((data) => {
            createAlert("success", "Payment method updated")
            
            
            getStripeBillingInfo(true)
            
        })
        .catch((error) => {
            console.error("Error updating payment method: ", error);
            const message = error.message || "There was an error updating the payment method"
            createAlert("error", message)
            setStripePaymentMethodsLoading(false)
        })

    }

    function cancelSubscription(){

        setStripeBillingLoading(true)
        setOpenCancelSubscriptionDialog(false)
        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: fbBillingObj?.subscriptionId
        }
        fetchFromAPI("organization/subscription/cancel", {method: 'POST', body: fetchBody})
        .then((data) => {
            createAlert("success", "Subscription cancelled")
            getStripeBillingInfo(false)
        })
        .catch((error) => {
            console.error("Error cancelling subscription: ", error);
            const message = error.message || "There was an error cancelling the subscription"
            createAlert("error", message)
            setStripeBillingLoading(false)  
        })

    }

    function reactivateSubscription(){

        setStripeBillingLoading(true)
        setOpenReactivateSubscriptionDialog(false)
        const fetchBody = {
            organizationId: orgObj?.selectedOrganization,
            subscriptionId: fbBillingObj?.subscriptionId
        }
        fetchFromAPI("organization/subscription/reactivate", {method: 'POST', body: fetchBody})
        .then((data) => {
            createAlert("success", `Subscription for ${ lotDisplayName } reactivated`)
            getStripeBillingInfo(false)
        })
        .catch((error) => {
            console.error("Error reactivating subscription: ", error);
            const message = error.message || "There was an error reactivating the subscription"
            createAlert("error", message)
            setStripeBillingLoading(false)  
        })
    }

    function updateLotTitle(_newTitle){
        return new Promise((resolve, reject) => {
            try{
                //make sure the new title is not the same as the old title and is between 3 and 50 characters
                if(_newTitle.length < 3 || _newTitle.length > 50){
                    createAlert("error", "The lot name must be between 3 and 50 characters")
                    reject()
                }else if(_newTitle == lotDisplayName){
                    createAlert("error", "The lot name must be different than the current name")
                    reject()
                }else{
                    setEditLotTitleLoading(true)
                    const fetchBody = {
                        organizationId: orgObj?.selectedOrganization,
                        subscriptionId: fbBillingObj?.subscriptionId,
                        displayName: _newTitle
                    }
                    fetchFromAPI("organization/subscription/updateLotDisplayName", {method: 'POST', body: fetchBody}) 
                    .then((data) => {
                        createAlert("success", "Lot name updated")
                        setLotDisplayName(_newTitle)
                        setEditLotTitleLoading(false)
                        resolve()
                    })
                    .catch((error) => {
                        console.error("Error updating lot title: ", error);
                        const message = error.message || "There was an error updating the lot title"
                        createAlert("error", message)
                        setEditLotTitleLoading(false)
                        reject()
                    })
                }
            }catch(error){
                console.error("Error updating lot title: ", error);
                const message = error.message || "There was an error updating the lot title"
                createAlert("error", message)
                setEditLotTitleLoading(false)
                reject()
            }
        })
    }

    return (
        <div className="viewLotContent">
            <div className='viewLotContentScroller'>
                <Alert variant="filled" severity={stripeSubscriptionObj?.status != "canceled" ? "warning":"info"} style={{margin: '15px', display: stripeSubscriptionObj?.cancel_at != null ? 'flex':'none'}} >
                    This lot's subcription has been cancelled { stripeSubscriptionObj?.status != "canceled" ? `and will end on ${calculateDateFromMillisecond(stripeSubscriptionObj?.cancel_at * 1000)}`:'' } 
                </Alert>
                <Paper elevation={2} className='viewLotContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <IconButton
                            onClick={() => {navigate(`/Organization/Lots`)}}>
                            <LayersIcon color="primary"/>                        
                        </IconButton>
                        <HeaderTitleEditable label="Lot Name" value={lotDisplayName} permissionToEdit={orgObj?.userIsOrgSuperAdmin} saveChanges={updateLotTitle} loading={editLotTitleLoading} />                        
                        <div style={{flex: 1}}></div>
                        
                                         
                    </div>     
                                    
                    <Tabs 
                        value={selectedTab} 
                        onChange={(event, newValue) => changeTabs(newValue)} 
                        aria-label="basic tabs example"
                        style={{
                            display: 'flex',
                        }}>
                        <Tab 
                            label="View Lot" 
                            icon={<CropFreeIcon />} 
                            iconPosition="start"  
                            {...a11yProps(0)} />
                        <Tab 
                            label="Subscription" 
                            icon={<CreditCardIcon />} 
                            iconPosition="start"  
                            {...a11yProps(1)} 
                            style={{
                                display: orgObj?.userIsOrgSuperAdmin ? 'flex':'none',
                            }}/>
                    </Tabs>
                    <Divider 
                        style={{
                            display: 'flex',
                        }}/>
                    <div
                        role="tabpanel"
                        hidden={selectedTab !== 0}
                        id={`simple-tabpanel-0`}
                        aria-labelledby={`simple-tab-0`}
                        className="viewLotTabPannel">
                        {selectedTab === 0 && (
                            <div 
                                className='viewLotGeneralTabScrollArea'>
                                {!isLoaded && (
                                    <div className='viewLotMapContainer'>
                                        <CircularProgress />    
                                    </div>
                                )}
                                {isLoaded && (
                                    <GoogleMap           
                                        zoom={mapZoom} 
                                        center={location} 
                                        mapContainerClassName="viewLotMapContainer"
                                        options={mapOptions}
                                        onLoad={map => (mapRef.current = map)}>
                                        <Polygon
                                            editable={false}
                                            draggable={false}
                                            paths={polygonBounds}                
                                            options={{
                                                ...rectangleOptions,
                                                fillColor: "#f9a825",
                                                strokeColor: "#f9a825",
                                                strokeWeight: 3,
                                            }}/>                        
                                        </GoogleMap>  
                                )}
                                <div className='viewLotButtonHolder'>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<DownloadIcon/>}
                                        onClick={() => {
                                            downloadGeoJson()                                            
                                        }}
                                        style={{marginRight: '15px'}}>
                                        Download GeoJson
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<ViewInArIcon/>}
                                        onClick={() => {
                                            //navigate to Map view passing the lot id
                                            navigate(`/MapView/${lotId}`)
                                        }}>
                                        Map View
                                    </Button>
                                </div>
                                <DataDisplay loading={billingLoading} icon={CropDinIcon} title="Total Area" data={`${lotExactArea} km²`}></DataDisplay>
                                <DataDisplay loading={billingLoading} icon={EventIcon} title="Date Created" data={calculateDateFromMillisecond(lotCreatedDate)}></DataDisplay>    
                                
                                                                                     
                            </div>
                        )}
                    </div>
                    <div
                        role="tabpanel"
                        hidden={selectedTab !== 1}
                        id={`simple-tabpanel-0`}
                        aria-labelledby={`simple-tab-0`}
                        className="viewLotTabPannel">
                        {selectedTab === 1 && (
                            <div 
                                className='viewLotGeneralTabScrollArea'>
                                <div className='viewLotRefeshButtonHolder'>
                                    <DataDisplay loading={stripeBillingLoading} icon={DonutLargeIcon} title="Subscription Status" data={subscriptionStatuses[stripeSubscriptionObj?.status]}></DataDisplay>                                
                                
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<AutorenewIcon/>}
                                        onClick={() => {
                                            getStripeBillingInfo(false)
                                            getOrgPaymentMethods()
                                        }}>
                                        Refresh
                                    </Button>
                                </div>
                                <DataDisplay loading={stripeBillingLoading} icon={EventIcon} title="Created Date" data={(new Date(stripeSubscriptionObj?.created * 1000)).toLocaleString("en-US", stripeBillingDateOptions)}></DataDisplay>
                                
                                <h4 style={{display: stripeSubscriptionObj?.status == 'canceled' ? 'none':'flex'}}>Payment Method</h4>
                                <div className="createLotPaymentOptionsArea" style={{overflowX: stripePaymentMethodsLoading ? 'hidden':'auto', display: stripeSubscriptionObj?.status == 'canceled' ? 'none':'flex'}}>
                                {
                                    stripePaymentMethodsLoading ?
                                        <Skeleton variant="rectangular" width={2000} height={280} />
                                        :
                                        <div 
                                            className='createLotPaymentOptionsAreaHolder'
                                            style={{width: `${(stripePaymentMethods?.length + 1)*430}px`}}>
                                            {
                                                stripePaymentMethods?.sort((a, b) => {

                                                    // if the stripeSubscriptionObj.default_payment_method == paymentMethod.id the that item should be first
                                                    // if the item isDefaultPaymentMethod then it should be second (it can be first if it is the default payment method as well)
                                                    if(a.id == stripeSubscriptionObj?.default_payment_method){
                                                        return -1
                                                    }else if(b.id == stripeSubscriptionObj?.default_payment_method){
                                                        return 1
                                                    }else if(a.userDefaultPaymentMethodSet){
                                                        return -1
                                                    }else if(b.userDefaultPaymentMethodSet){
                                                        return 1
                                                    }else{
                                                        return 0
                                                    }

                                                                            
                                                }).map((paymentMethod) => {
                                                    return (
                                                        <PaymentMethod 
                                                            key={paymentMethod.id}
                                                            selected={stripeSubscriptionObj?.default_payment_method == paymentMethod.id} 
                                                            loading={false} 
                                                            create={false} 
                                                            organizationId={orgObj?.selectedOrganization} 
                                                            paymentMethodObj={paymentMethod} 
                                                            createAlert={createAlert} 
                                                            refreshWallet={getOrgPaymentMethods}
                                                            manageOnClick={() => {
                                                                if(paymentMethod.id != stripeSubscriptionObj?.default_payment_method){
                                                                    setSelectedPaymentMethod(paymentMethod)
                                                                    setShowSelectedPaymentMethodDialog(true)
                                                                }
                                                            }}/>
                                                    )
                                                })
                                            }
                                            <PaymentMethod 
                                                key={"create"}
                                                selected={false}
                                                loading={stripePaymentMethodsLoading} 
                                                create={true} 
                                                organizationId={orgObj?.selectedOrganization} 
                                                paymentMethodObj={{
                                                    userDefaultPaymentMethodSet: stripePaymentMethods?.length > 0 ? stripePaymentMethods?.[0]?.userDefaultPaymentMethodSet == true : false
                                                }}
                                                createAlert={createAlert} 
                                                refreshWallet={getOrgPaymentMethods}/>
                                        </div>
                                }                            
                                </div>

                                <h4>Invoices</h4>
                                <Paper elevation={3} style={{marginRight: '5px', marginLeft: '5px', marginBottom: '50px', width: 'calc(100% - 10px)', overflow: 'hidden', display: stripeBillingLoading ? 'block':'none'}} >
                                    <Skeleton variant="rectangular" width={2000} height={60+(60*stripeInvoiceArray.length)} />
                                </Paper>
                                <TableContainer component={Paper} elevation={3} style={{marginRight: '5px', marginLeft: '5px', marginBottom: '50px', width: 'calc(100% - 10px)', display: !stripeBillingLoading ? 'block':'none'}} >
                                    <Table aria-label="simple table">
                                        <TableHead >
                                            <TableRow style={{minHeight: '60px !important'}}>
                                                <TableCell>Amount</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Invoice Number</TableCell>
                                                <TableCell>Period</TableCell>                                                
                                                <TableCell>Created</TableCell>
                                                <TableCell>Manage</TableCell>
                                            </TableRow>
                                            </TableHead>
                                        <TableBody>
                                        {stripeInvoiceArray.map((invoice) => {

                                            var periodStartDate = new Date(invoice.period_start * 1000)
                                            var periodEndDate = new Date(invoice.period_end * 1000)
                                            //if period start and end date are the same then check the invoice.lines.data[0].period.start and end
                                            if(periodStartDate.getDate() == periodEndDate.getDate() && periodStartDate.getMonth() == periodEndDate.getMonth() && periodStartDate.getFullYear() == periodEndDate.getFullYear()){
                                                periodStartDate = typeof invoice?.lines.data[0]?.period?.start == 'number' ? new Date(invoice?.lines.data[0]?.period?.start * 1000) : periodStartDate
                                                periodEndDate = typeof invoice?.lines.data[0]?.period?.end == 'number' ? new Date(invoice?.lines.data[0]?.period?.end * 1000) : periodEndDate
                                            }


                                            return (
                                                <TableRow
                                                key={invoice.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, minHeight: '60px !important' }}>
                                                    <TableCell component="th" scope="row">
                                                        {`${(new Intl.NumberFormat('en-US', {style: 'currency', currency: invoice.currency.toUpperCase(),}).format(invoice.amount_due/100))} ${invoice.currency.toUpperCase()}`}
                                                    </TableCell>
                                                    <TableCell>{invoiceStatuses[invoice.status].label}</TableCell>
                                                    <TableCell>{invoice.number}</TableCell>
                                                    <TableCell>{periodStartDate.toLocaleDateString("en-US", stripeBillingDateOptions)} - {periodEndDate.toLocaleDateString("en-US", stripeBillingDateOptions)}</TableCell>
                                                    <TableCell>{(new Date(invoice.created * 1000)).toLocaleDateString("en-US", stripeBillingDateOptions)}</TableCell>
                                                    <TableCell>{invoiceStatuses[invoice.status]?.action(invoice.id)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                {
                                    //if the subcription has been canceled
                                    stripeSubscriptionObj?.status == 'canceled' ?
                                    <h4 style={{marginBottom: '5px'}}>Subscription Cancelled {calculateDateFromMillisecond(stripeSubscriptionObj?.canceled_at * 1000) }</h4>
                                    :stripeSubscriptionObj?.cancel_at_period_end ?
                                    <>
                                        {
                                            //if the stripesubscriptonobj.canceled_at is set the display
                                            stripeSubscriptionObj?.cancel_at != null ?
                                            <>
                                                <h4 style={{marginBottom: '5px'}}>Subscription Cancelled</h4>
                                                {
                                                    (() => {                                                        
                                                        switch(stripeSubscriptionObj?.status){
                                                            case 'paused':
                                                                return <p>Your subscription has been paused</p>
                                                            default:
                                                                return <p>This lot's subcription has been cancelled and will end on {calculateDateFromMillisecond(stripeSubscriptionObj?.cancel_at * 1000)}</p>                                                                
                                                        }
                                                    })() //inline function notation
                                                }
                                                
                                            </>
                                            :null
                                        }
                                        
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<RestartAltIcon/>}
                                            onClick={() => {
                                                setOpenReactivateSubscriptionDialog(true)
                                            }}
                                            disabled={stripeBillingLoading}>
                                            Reactivate Subscription
                                        </Button>
                                    </>
                                    :
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        startIcon={<CancelIcon/>}
                                        onClick={() => {
                                            setOpenCancelSubscriptionDialog(true)
                                            
                                        }}
                                        disabled={stripeBillingLoading}>
                                        Cancel Subscription
                                    </Button>
                                }
                                
                            </div>                            

                        )}
                    </div>
                    
                </Paper>                
            </div>            
            
            <Footer></Footer>

            <Dialog
                open={showSelectedPaymentMethodDialog}
                onClose={() => setShowSelectedPaymentMethodDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                {"Update this subscription's selected payment method?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`All future invoices for ${lotDisplayName} will be charged to the card ending in ${selectedPaymentMethod?.card?.last4}.`}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setShowSelectedPaymentMethodDialog(false)}>Cancel</Button>
                <Button variant='contained' color='secondary' onClick={updateSubscriptionDefaultPaymentMethod} autoFocus>
                    Update
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openCancelSubscriptionDialog}
                onClose={() => setOpenCancelSubscriptionDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to cancel this lot's subscription?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Cancelling the subscription for ${lotDisplayName} will stop all future charges for this lot, you will still be able to acces your exiting data.`}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpenCancelSubscriptionDialog(false)}>Nevermind</Button>
                <Button variant='contained' startIcon={<CancelIcon />} onClick={cancelSubscription} autoFocus>
                    Cancel Subscription
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openReactivateSubscriptionDialog}
                onClose={() => setOpenReactivateSubscriptionDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                {"Would you like to reactivate your subscription?"}
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {`Reactivating the subscription for ${lotDisplayName} will resume billing for this lot.`}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={() => setOpenReactivateSubscriptionDialog(false)}>Nevermind</Button>
                <Button variant='contained' color="secondary" startIcon={<RestartAltIcon />} onClick={reactivateSubscription} autoFocus>
                    Reactivate Subscription
                </Button>
                </DialogActions>
            </Dialog>

            
            
        </div>
    )

}


export default ViewLot