import firebase from 'firebase/compat/app';
import settings from '../settings.json'


const API = settings?.localhost == true ? 'http://localhost:8080' : 'https://stripewebserver-jwxamgsnma-uc.a.run.app';

/**
 * A helper function to fetch data from your API.
 * It sets the Firebase auth token on the request.
 */
export async function fetchFromAPI(endpointURL, opts) {
  const { method, body } = { method: 'POST', body: null, ...opts };

  const auth = firebase.auth();


  const user = auth.currentUser;
  const token = user && (await user.getIdToken());


  const res = await fetch(`${API}/${endpointURL}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  // check if the request was successful
  if (!res.ok) {
    //get message from body
    const { error, message } = await res.json();
    if(message){
      throw new Error(message);
    }else if(error){
      throw new Error(error);
    }else{
      throw new Error(`An error occurred while fetching data. Error: ${res.statusText}`);
    }
  }

  return res.json();
}
