

export function formatAsDollar(amount) {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2, // This means that fractions might be omitted if they are zero.
        maximumFractionDigits: 2  // Up to 3 decimal places
    }).format(amount);
}
