

export  function dateToLocaleUTCDateString(date, locales, options) {
    try{
        const timeDiff = date.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(date.valueOf() + timeDiff);
        return adjustedDate.toLocaleDateString(locales, options);
    }catch(e){
        console.error(e)
        return "Error formatting date"
    }

}
