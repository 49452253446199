import * as turf from '@turf/turf';

export function calculatePolygonsIntersect(_polygon, _polygonArray){

  const checkPolygon = turf.polygon([_polygon])
  const polygonArray = _polygonArray.map(polygon => turf.polygon([polygon]))

  var intersects = false
  polygonArray.forEach(polygon => {
    if(intersects) {
      return
    }else{
      const intersection = turf.intersect(checkPolygon, polygon)
      if (intersection){
        intersects = true
      }
      return
    }
  })


  return intersects

}