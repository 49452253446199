
export function analyticsIdToDate(_analyticsId){
    //take the last 10 characters of the dateId
    const splitId = _analyticsId.split('-')
    const year = parseInt(splitId[1])
    const month = parseInt(splitId[2])
    const day = parseInt(splitId[3])

    //return the utc date
    var date = new Date(Date.UTC(year, month, day))
    //subtract 1 from the month because months are 0 indexed and minus one more for the previous month
    date.setMonth(date.getMonth() - 2)
    return date
}