import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './GetStarted.css'

import AccountCircleIcon from '@mui/icons-material/AccountCircle';


import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';



function GetStarted(props) {

    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const navigate = useNavigate()


    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account'
        });
        props.auth.signInWithPopup(provider).then((result) => {
            window.location.replace("/mapView");
        });;
    }



    return (
        <div className="getStartedContent">
            <div className='getStartedContentScroller'>
                <Paper elevation={2} className='getStartedContentPaper'>
                    <h1>Welcome to Lot Eagle</h1>
                    <h3>The future of construction and land clearing monitoring</h3>
                    <p>
                        Dive into the world of real-time satellite data, tailored to the needs of real estate professionals and urban planners. With our platform, you can efficiently track mid to large-sized projects, access insightful analytics, and stay ahead of the curve. Whether you're a data aggregator, developer, or local government agency, Lot Eagle offers a comprehensive solution that's both efficient and cost-effective. Ready to transform the way you view land development?
                    </p>
                    <p>
                        Get started with us today!
                    </p>
                    <Button variant="contained" color="secondary" onClick={() => signInWithGoogle()} startIcon={<AccountCircleIcon />}>Sign Up</Button>
                </Paper>
            </div>            
            <Footer></Footer>
        </div>
    )

}


export default GetStarted