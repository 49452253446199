

export function dateIdToUTCDateObject(_dateId){
    //take the last 10 characters of the dateId
    const splitId = _dateId.split('-')
    const year = splitId[3]
    const month = splitId[4]
    const day = splitId[5]
    //create a utc date
    const utcDate = new Date(Date.UTC(year, month - 2, day))
    //return the utc date
    return utcDate


}