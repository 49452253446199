import firebase from 'firebase/compat/app';
import settings from '../settings.json'


/**
 * A helper function to fetch data from your API.
 * It sets the Firebase auth token on the request.
 */
export async function setUserDataFirestore(_user) {
  
    return new Promise((resolve, reject) => {

        
        console.log("Setting user data for user: ", _user.uid);
        if(!_user) {
            // set user metadata at /UserData/{userId}
            const userRef = firebase.firestore().collection('UserData').doc(_user.uid);
            userRef.set({
                email: _user.email,
                displayName: _user.displayName,
                photoURL: _user.photoURL,
                lastSeen: firebase.firestore.FieldValue.serverTimestamp(),
                ...params
            }, { merge: true })
            .then(() => {
                resolve("User data successfully written!")
            })
            .catch((error) => {
                reject(error);
            });

        }else{
            reject("User is not logged in");
        }
    })

}
