

export function dateIdToAnalyticsRequestIdShorthand(_requestType, _dateId){

    //take the last 10 characters of the dateId
    const splitId = _dateId.split('-')
    const year = parseInt(splitId[3])
    const month = parseInt(splitId[4])
    const day = parseInt(splitId[5])

    //return the utc date
    return `${_requestType}-${year}-${month}-${day}`


}