

export function findClosestObjectId(array, target, _dir) {
  let closestKey = 0
  let closestValue = array[closestKey]

  if(_dir == "lat"){
    

    for (let i = 0; i < array.length; i++) {
      if (array[i] >= target && array[i] < closestValue) {
        closestValue = array[i];
        closestKey = i;      
      }
    }

  }else if (_dir == "lng"){

    for (let i = 0; i < array.length; i++) {
      if (array[i] <= target && array[i] > closestValue) {
        closestValue = array[i];
        closestKey = i;      
      }
    }
  }

  return closestKey;

}
  