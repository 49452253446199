

export function calculateDateFromMillisecond(millisecond) {
    
    //convert millisecond to int if it is a string
    try {
        if (typeof millisecond === 'string' || millisecond instanceof String) {
            millisecond = parseInt(millisecond)
        }
        // take a millisecond and return a date string of the type Month Day, Year
        var date = (new Date(millisecond))
        
        
        var dateString = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric', })
        return dateString
    } catch (error) {
        console.log(error)
        return 'Invalid Date'
    }
  }