import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './Invite.css'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import CreditCardIcon from '@mui/icons-material/CreditCard'
import WidgetsIcon from '@mui/icons-material/Widgets';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AuthInterface from '../AuthInterface/AuthInterface';



function Invite(props) {

    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const organizationObj = props.organizationObj;
    const navigate = useNavigate()
    const routeParams = useParams();

    const [acceptInviteLoading, setAcceptInviteLoading] = useState(false)
    

    const orgId = routeParams.orgId
    const password = routeParams.password

    function acceptInvite() {

        setAcceptInviteLoading(true)

        const inviteAcceptRef = firebase.firestore().collection("Operations").doc("Invites").collection(orgId).doc("Organization_HR").collection("AnalystResponse").doc(props.user.uid)
        inviteAcceptRef.set({
            orgId: orgId,
            password: password,
            email: currentUserEmail,
            userId: props.user.uid,
            status: "accepted",
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp()

        })
        .then(() => {
            
            setTimeout(() => {
                setAcceptInviteLoading(false)
                createAlert("success", "Invite accepted. Please refresh the page, click the menu in the top-left and navigate to “All Lots” to get started.")

            }, 4500);

            setTimeout(() => {
                organizationObj?.getUserOrganizations(currentUser)
            }, 5000);
            setTimeout(() => {
                organizationObj?.getUserOrganizations(currentUser)
            }, 10000);
            setTimeout(() => {
                organizationObj?.getUserOrganizations(currentUser)
            }, 20000);
            setTimeout(() => {
                organizationObj?.getUserOrganizations(currentUser)
            }, 30000);

            
        })
        .catch((error) => {
            setAcceptInviteLoading(false)
            createAlert("error", "Error accepting invite")
            console.error("Error writing document: ", error);
        });
    }
    
    var inviteUI = null

    if(!loggedIn) {
        inviteUI = <div className="inviteElementHolderLogin">
                <h3>Would you like to accept this invite?</h3>
                <p>Sign in or create an account to accept this invite</p>
                
                <AuthInterface auth={props.auth} user={props.user} createAlert={createAlert}/>
                <AuthInterface auth={props.auth} user={props.user} createAlert={createAlert} loadPage={"createAccount"}/>

            </div>

    }else{

        if(currentUser.email != email){
            inviteAdminUI = <div className="inviteAdminElementHolderLogin">
                    <h3>{pageData[pageId].subHeader}</h3>
                    <p>Looks like you signed in with the wrong email. Sign in using <strong>{email}</strong> to accept this invite</p>                
                    <Button 
                        variant='contained'
                        color="secondary"
                        startIcon={<Logout />}
                        onClick={() => {
                            auth.signOut()
                                .then(() => {
                                    createAlert("success", "Signed out successfully")

                                })
                                .catch((error) => {
                                    createAlert("error", "Error signing out")
                                })
                        }}>
                            Logout
                    </Button>
                </div>
        }else{

            inviteUI = <div className="inviteElementHolderLogin">
                    <h3>Would you like to accept this invite?</h3>
                    <p>By accepting you will be added to the organization that sent the invite. Click the button to start exploring with Lot Eagle!</p>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        disabled={acceptInviteLoading}
                        onClick={() => {
                            acceptInvite()
                        }}>
                            Accept Invite
                    </Button>

                </div>
        }
    }
      
    

    return (
        <div className="inviteContent">
            <div className='inviteContentScroller'>
                <Paper elevation={2} className='inviteContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <ReceiptIcon style={{marginRight: '10px'}}/>
                        Invite
                    </div>   
                    <div className='inviteElementHolder'>
                        {inviteUI}
                        
                    </div>                 
                </Paper>
                
            </div>            
            <Footer></Footer>
        </div>
    )

}


export default Invite