


export function calculatePolygonBounds(_polygon) {
    //takes list of lat lng and returns north east and south west bounds
    var north = -90;
    var south = 90;
    var east = -180;
    var west = 180;
    
    _polygon.forEach((point) => {
        if (point.lat > north) {
            north = point.lat;
        }
        if (point.lat < south) {
            south = point.lat;
        }
        if (point.lng > east) {
            east = point.lng;
        }
        if (point.lng < west) {
            west = point.lng;
        }
    });

    return {
        north: north,
        south: south,
        east: east,
        west: west
    }
}