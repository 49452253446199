import * as turf from '@turf/turf';

export function calculateSquareLotsOverlapMask(_activeSquarePolygon, _lotPolygonArray){

    const activeSquarePolygon = _activeSquarePolygon.map(coord => {
      return [coord.lng, coord.lat] 
    })

    //if the square is not closed, close it
    if(_activeSquarePolygon[0].lng !== _activeSquarePolygon[_activeSquarePolygon.length-1].lng || _activeSquarePolygon[0].lat !== _activeSquarePolygon[_activeSquarePolygon.length-1].lat){
      //push the last coordinate to close the polygon
      activeSquarePolygon.push([_activeSquarePolygon[0].lng, _activeSquarePolygon[0].lat])
    }

    

    const lotPolygons = _lotPolygonArray.map(polygon => {
      const minArray = polygon.map(coord => {
        return [coord.lng, coord.lat] 
      })
      //if the square is not closed, close it
      if(polygon[0].lng !== polygon[polygon.length-1].lng || polygon[0].lat !== polygon[polygon.length-1].lat){
        //push the last coordinate to close the polygon
        minArray.push([polygon[0].lng, polygon[0].lat])
      }
      return turf.polygon([minArray])
    })

    // Assuming you have a square and an array of polygons
    const square = turf.polygon([activeSquarePolygon]); // Define your square coordinates here
    

    try{
      // Step 1: Detect Overlapping Polygons and create a union of their overlaps with the square
      let unionOfOverlaps = null;
      lotPolygons.forEach(poly => {
        const overlap = turf.intersect(square, poly);
        if (overlap) {
          unionOfOverlaps = unionOfOverlaps ? turf.union(unionOfOverlaps, overlap) : overlap;
        }
      });

      
      return unionOfOverlaps  

    }catch(err){
      console.error(err)
      return null
    }

    

  }