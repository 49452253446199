const moment = require('moment-timezone');

export function toEasternTime(date) {

    // Assuming `date` is a JavaScript Date object or a valid date string recognized by Moment.js
    // This will convert the date to Eastern Time, keeping the same point in time but displaying it as Eastern Time
    const offset = date.getTimezoneOffset()
    //check if the date is before GMT if so add a day
    if(offset < 0){
        //date.setDate(date.getDate() + 1)
        const tempDate = new Date(date);

        tempDate.setHours(tempDate.getHours() + (-1 * offset/60) + 1);

        return moment(tempDate).tz("America/New_York").toDate();
    }else{
        return moment(date).tz("America/New_York").toDate();
    }

}
  