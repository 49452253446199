import React, { useState } from 'react';
import { Box, Typography, Skeleton, FormControl, InputLabel, Select, MenuItem, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

function DataDisplayEditableSelect({ icon: IconComponent, title, display, selectItems = [], loading, value, onSelectValueChange = () => {}, onSave = () => {}}) {

  const [isEditing, setIsEditing] = useState(false);

  return (
    <Box display="flex" alignItems="center" gap={2} style={{marginTop: '15px', alignItems: 'flex-start', height: '45px'}} >
      <Box 
        style={{cursor: 'pointer'}}
        onClick={() => setIsEditing(!isEditing)}>
        <IconComponent style={{fontSize: '18px', lineHeight: '20px'}}/>
      </Box>
      <Box>
        {
          isEditing ? (
            <div style={{
                display: 'flex',
                flexDirection: 'row'
              }}>
              <FormControl style={{marginRight: '15px'}}>
                <InputLabel 
                    id="demo-simple-select-label"
                    color="primary"
                    size="small">{title}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label="Billing Point of Contact"   
                    size='small'                         
                    onChange={(event) => {
                      onSelectValueChange(event.target.value)

                    }}
                    color="secondary">
                    {
                        selectItems.map((e) => {

                            return <MenuItem value={e.value} disabled={e.disabled == true}>{e.display}</MenuItem>
                        })
                    }                      
                </Select>   
              </FormControl>  
              <IconButton
                onClick={() => {
                  setIsEditing(false);
                  onSave();
                }}>
                <SaveIcon />
              </IconButton>

            </div>
          ) : (
            <div
              style={{cursor: 'pointer'}}
              onClick={() => setIsEditing(!isEditing)}>
              <Typography variant="subtitle1" style={{fontSize: '16px', lineHeight: '20px', fontWeight: '500'}}>{title}</Typography>
              {
                loading ? (
                  <Skeleton variant="text" width={100} height={20} />
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    {display}
                  </Typography>
                )
              }
            </div>
          )
        }
        
      </Box>
    </Box>
  );
}

export default DataDisplayEditableSelect;
