import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './InviteAdmin.css'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import { Logout } from '@mui/icons-material'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import WidgetsIcon from '@mui/icons-material/Widgets';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AuthInterface from '../AuthInterface/AuthInterface';



function inviteAdmin(props) {

    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const organizationObj = props.organizationObj;
    const adminHRObj = props.adminHRObj

    
    const pageId = props.pageId;

    const navigate = useNavigate()
    const routeParams = useParams();

    const password = routeParams.password
    const email = routeParams.email

    const [acceptInviteLoading, setAcceptInviteLoading] = useState(false)

    const pageData = {
        "AdminHR": {
            responseCollection: "/Operations/Invites/Admin/InternalHR/AnalystResponse",
            header: "Map Review Team Invite",
            subHeader: "Would you like to accept this Map Review Team invite?",
            description: "By accepting you will be added to Lot Eagle's Map Review Team. Click the button to start labeling with Lot Eagle!"
        },

    }

    function acceptinviteAdmin() {

        setAcceptInviteLoading(true)

        const inviteAdminAcceptRef = firebase.firestore().collection(pageData[pageId].responseCollection).doc(props.user.uid)
        inviteAdminAcceptRef.set({
            password: password,
            email: currentUserEmail,
            userId: props.user.uid,
            status: "accepted",            
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {            
            setTimeout(() => {
                setAcceptInviteLoading(false)
                createAlert("success", "Invite accepted. ")
            }, 4500);                  
        })
        .catch((error) => {
            createAlert("error", "Error accepting invite")
            console.error("Error writing document: ", error);
            setAcceptInviteLoading(false)
        });
    }
    
    var inviteAdminUI = null

    if(!loggedIn) {
        inviteAdminUI = <div className="inviteAdminElementHolderLogin">
                <h3>{pageData[pageId].subHeader}</h3>
                <p>Sign in or create an account using <strong>{email}</strong> to accept this invite</p>                
                <AuthInterface auth={props.auth} user={props.user} createAlert={createAlert}/>
                <AuthInterface auth={props.auth} user={props.user} createAlert={createAlert} loadPage={"createAccount"}/>
            </div>

    }else{

        if(currentUser.email != email){
            inviteAdminUI = <div className="inviteAdminElementHolderLogin">
                    <h3>{pageData[pageId].subHeader}</h3>
                    <p>Looks like you signed in with the wrong email. Sign in using <strong>{email}</strong> to accept this invite</p>                
                    <Button 
                        variant='contained'
                        color="secondary"
                        startIcon={<Logout />}
                        onClick={() => {
                            auth.signOut()
                                .then(() => {
                                    createAlert("success", "Signed out successfully")

                                })
                                .catch((error) => {
                                    createAlert("error", "Error signing out")
                                })
                        }}>
                            Logout
                    </Button>
                </div>
        }else{

            inviteAdminUI = <div className="inviteAdminElementHolderLogin">
                    <h3>{pageData[pageId].subHeader}</h3>
                    <p>{pageData[pageId].description}</p>
                    <Button 
                        variant="contained" 
                        color="secondary" 
                        disabled={acceptInviteLoading}
                        onClick={() => {
                            acceptinviteAdmin()
                        }}>
                            Accept Invite
                    </Button>

                </div>
        }
    }
      
    

    return (
        <div className="inviteAdminContent">
            <div className='inviteAdminContentScroller'>
                <Paper elevation={2} className='inviteAdminContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <ReceiptIcon style={{marginRight: '10px'}}/>
                        {pageData[pageId].header}
                    </div>   
                    <div className='inviteAdminElementHolder'>
                        {inviteAdminUI}
                        
                    </div>                 
                </Paper>
                
            </div>            
            <Footer></Footer>
        </div>
    )

}


export default inviteAdmin