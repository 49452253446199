import settings from '../settings.json';
import { squareIdToLatLng } from './squareIdToLatLng'

export function createAdminSquareFromId(_id) {
  
  const { lat, lng } = squareIdToLatLng(_id)
  const bounds = {
    north: lat + settings.squareSize,
    south: lat,
    east: lng + settings.squareSize,
    west: lng,
  }
  const squareObj = {
    id: _id,
    lat: lat,
    lng: lng,
    bounds: bounds,
    polygon: [
      {lat: bounds.north, lng: bounds.west},
      {lat: bounds.north, lng: bounds.east},
      {lat: bounds.south, lng: bounds.east},
      {lat: bounds.south, lng: bounds.west},
      {lat: bounds.north, lng: bounds.west},
    ],
  }
 
  return squareObj
}