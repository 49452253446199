import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './Organization.css'
import DataDisplayEditableSelect from '../DataDisplayEditableSelect/DataDisplayEditableSelect';
import DataDisplay from '../DataDisplay/DataDisplay';
import Footer from '../Footer/Footer'
import { fetchFromAPI } from '../../functions/fetchFromAPI';
import PaymentMethod from '../PaymentMethod/PaymentMethod';
import { Button, IconButton, Paper, Divider, Tabs, Tab , TextField, Dialog, 
         DialogActions, DialogContent, DialogContentText, DialogTitle, Select,
        FormControl, InputLabel, MenuItem, Box } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';
import BusinessIcon from '@mui/icons-material/Business';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupIcon from '@mui/icons-material/Group';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import LayersIcon from '@mui/icons-material/Layers';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import DefaultAccount from '../../assets/DefaultAccount.png'
import e from 'cors';
import HeaderTitleEditable from '../HeaderTitleEditable/HeaderTitleEditable';

function Organization(props) {

    const auth = props.auth;
    const { tab } = useParams()
    const adminMode = props.adminMode;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn
    const orgObj = props.organizationObj
    const userIsOrgSuperAdmin = orgObj.userIsOrgSuperAdmin;

    const [orgName, setOrgName] = useState("")
    const [orgBillingPointOfContact, setOrgBillingPointOfContact] = useState("")
    const [orgBillingPointOfContactLoading, setOrgBillingPointOfContactLoading] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState([])
    const [getPaymentMethodsLoading, setGetPaymentMethodsLoading] = useState(false)
    const navigate = useNavigate()

    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)

    const [sendInviteDialogOpen, setSendInviteDialogOpen] = useState(false)
    const [inviteEmail, setInviteEmail] = useState("")
    const [users, setUsers] = useState({})
    const [updateOrgNameLoading, setUpdateOrgNameLoading] = useState(false)



    const tabNames = ["Users", "Billing"]

    const [selectedTab, setSelectedTab] = useState(tabNames.indexOf(tab) != -1 ? tabNames.indexOf(tab) : 0)


    useEffect(() => {
        init()

    }, [orgObj?.selectedOrganization])


    function init(){
        getUsers()
        getOrgPaymentMethods()
        setOrgName(orgObj?.orgObj?.displayName != null ? orgObj?.orgObj?.displayName : "")
        setOrgBillingPointOfContact(orgObj?.orgObj?.paymentSettings?.billingPointOfContact != null ? orgObj?.orgObj?.paymentSettings?.billingPointOfContact : "")

    }

    function changeTabs(newValue) {
        setSelectedTab(newValue)
    }

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    function getUsers() {
        const tempData = {}
        //get the users for the organization
        firebase.firestore().collection("Organizations").doc(orgObj?.selectedOrganization).collection("Users").get()
        .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                tempData[doc.id] = doc.data()
            });
            setUsers(tempData)
        })
        .catch((error) => {
            console.error("Error getting documents: ", error);
        });
    }

    function generatePassword() {

        var length = 20,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;

    }

    function sendEmailInvite() {

        //create a new invite in Organizations/{orgId}/invites
        

        const password = generatePassword()

        var inviteDocRef = firebase.firestore().collection("Operations").doc("Invites").collection(orgObj?.selectedOrganization).doc("Organization_HR").collection("Analyst").doc(password)
        //add the invite to the collection
        inviteDocRef.set({
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
            email: inviteEmail,
            orgId: orgObj?.selectedOrganization,
            orgName: orgObj?.orgObj?.displayName,
            password: password
        })
        .then((docRef) => {
            //create an alert
            createAlert("success", "An email invite has been sent to " + inviteEmail)

            setInviteEmail("")
            setSendInviteDialogOpen(false)

        })
        .catch((error) => {
            console.error("Error adding document: ", error);
            //create an alert
            createAlert("error", "There was an error sending the invite")
            orgObj?.getUserOrganizations(currentUser)
            setInviteEmail("")
            setSendInviteDialogOpen(false)

        });

    }

    function updateBillingPointOfContact(value) {

        if(userIsOrgSuperAdmin){
            setOrgBillingPointOfContactLoading(true)
            //update the billing point of contact
            firebase.firestore().collection("Organizations").doc(orgObj?.selectedOrganization).update({
                'paymentSettings.billingPointOfContact': value,
                'dateUpdated': firebase.firestore.FieldValue.serverTimestamp()
            })
            .then(() => {
                
                const fetchBody = {
                    organizationId: orgObj?.selectedOrganization, 
                }

                //send a notification to the backend to update the billing point of contact
                fetchFromAPI("organization/updateCustomerEmail", {method: 'POST', body: fetchBody})
                .then((data) => {
                    createAlert("success", "Billing Point of Contact updated")
                })
                .catch((error) => {
                    console.error("Error updating billing point of contact: ", error);
                    createAlert("error", "There was an error updating the billing point of contact")
                    orgObj?.getUserOrganizations(currentUser)
                })
                .finally(() => {
                    setOrgBillingPointOfContactLoading(false)
                })
            })
            .catch((error) => {                
                setOrgBillingPointOfContactLoading(false)
                console.error("Error updating document: ", error);
                createAlert("error", "There was an error updating the billing point of contact")
                orgObj?.getUserOrganizations(currentUser)
            })
            
        }
    }

    async function saveOrganizationName(_updatedName) {

        return new Promise((resolve, reject) => {
        
            if(userIsOrgSuperAdmin){

                if(_updatedName.length == 0){
                    createAlert("error", "Organization name cannot be empty")
                    return
                }
                setUpdateOrgNameLoading(true)
                //update the organization name
                firebase.firestore().collection("Organizations").doc(orgObj?.selectedOrganization).update({
                    displayName: _updatedName
                })
                .then(() => {
                    
                    const fetchBody = {
                        organizationId: orgObj?.selectedOrganization, 
                        
                    }

                    //send a notification to the backend to update the billing point of contact
                    fetchFromAPI("organization/updateCustomerEmail", {method: 'POST', body: fetchBody})
                    .then((data) => {
                        
                    })
                    .catch((error) => {
                        
                    })
                    .finally(() => {
                        
                        setOrgName(_updatedName)
                        createAlert("success", "Organization name updated")
                        orgObj?.getUserOrganizations(currentUser)
                        setUpdateOrgNameLoading(false)
                        resolve()
                    })

                })
                .catch((error) => {
                    console.error("Error updating document: ", error);
                    createAlert("error", "There was an error updating the organization name")
                    orgObj?.getUserOrganizations(currentUser)
                    setUpdateOrgNameLoading(false)
                    reject()
                })
                
            }else{
                
                reject()
            }
        })
    }

    function getOrgPaymentMethods() {

        if(orgObj.userIsOrgSuperAdmin){

            setGetPaymentMethodsLoading(true)
            const fetchBody = {
                organizationId: orgObj?.selectedOrganization,
            }

            fetchFromAPI("organization/wallet/listCards", {method: 'POST', body: fetchBody})
            .then((data) => {

                if(data?.length > 0){
                    setPaymentMethods(data)
                }else{
                    setPaymentMethods([])
                }
            })
            .catch((error) => {
                console.error("Error getting payment methods: ", error);
                setPaymentMethods([])
                createAlert("error", "There was an error getting the payment methods")
                orgObj?.getUserOrganizations(currentUser)
            })
            .finally(() => {
                setGetPaymentMethodsLoading(false)
            })
        }

    }

    function calculateUserDisplayName(user) {
        try{
            if(user.displayName != null){
                return user.displayName
            } else if(user.email != null){
                return user.email
            } else {
                return "No Name"
            }
        }catch(e){            
            return ""
        }
    }

    const organizationHasLots = typeof orgObj?.orgObj?.stripeSubscription == 'object' ? Object.keys(orgObj?.orgObj?.stripeSubscription).length > 0:false

    return (
        <div className="organizationContent">
            <div className='organizationContentScroller'>
                <Paper elevation={2} className='organizationContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <BusinessIcon style={{marginRight: '10px'}}/>
                        <h2>Organization - </h2>
                        <HeaderTitleEditable label={"Organization Name"} value={orgName} permissionToEdit={userIsOrgSuperAdmin} saveChanges={saveOrganizationName} loading={updateOrgNameLoading} />
                        <div style={{flex: 1}}></div>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<LayersIcon />}
                            onClick={() => {
                                navigate(`/Organization/Lots`)
                            }}
                            style={{display: organizationHasLots ? 'flex':'none'}}>
                            All Lots
                        </Button>

                    </div>

                    <Tabs 
                        value={selectedTab} 
                        onChange={(event, newValue) => changeTabs(newValue)} 
                        aria-label="basic tabs example"
                        style={{
                            display: 'flex',
                        }}>
                        <Tab 
                            label="Users" 
                            icon={<GroupIcon />} 
                            iconPosition="start"  
                            {...a11yProps(0)} />
                        <Tab 
                            label="Billing" 
                            icon={<CreditCardIcon />} 
                            iconPosition="start"  
                            {...a11yProps(1)} />
                    </Tabs>
                    <Divider 
                        style={{
                            display: 'flex',
                        }}/>

                    <div className='organizationTabContent' style={{display: selectedTab == 0 ? 'flex':'none'}}>
                        <div className='organizationContentArea'>
                            <div className='organizationContentHeader'>
                                <h3>Admins</h3>
                                
                            </div>
                            <div className='organizationContentUserArea'>
                            {
                                //foreach orgObj.superAdmins
                                Object.entries(orgObj?.orgObj?.superAdmins).map(([key, value]) => {
                                    if(value === true){
                                        const userObj = users[key]
                                        if(userObj != null){
                                            var photoURL = userObj?.photoURL
                                            //if the user photoURL is null, use the default image
                                            if(userObj?.photoURL == null){
                                                photoURL = DefaultAccount
                                            }

                                            return (
                                                <Paper className='organizationContentUser' elevation={3}>
                                                    <img src={photoURL} />
                                                    <h2>{userObj?.displayName}</h2>
                                                    <h4>{userObj?.email}</h4>
                                                    <p>{`Last Signin: ${calculateDateFromMillisecond(userObj?.lastUpdated?.seconds * 1000)}`}</p>

                                                </Paper>
                                            )
                                        }
                                    }
                                })
                            }                        
                            </div>

                        </div>

                        <div className='organizationContentArea'>
                            <div className='organizationContentHeader'>
                                <h3>Analysts</h3>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{display: userIsOrgSuperAdmin ? "flex" : "none"}}
                                    onClick={() => {
                                        setSendInviteDialogOpen(true)
                                    }}
                                    startIcon={<AddCircleIcon />}>
                                    Add Analyst 
                                </Button>
                            </div>
                            <div className='organizationContentUserArea'>
                            {
                                //foreach orgObj.superAdmins
                                Object.entries(orgObj?.orgObj?.analysts).map(([key, value]) => {
                                    if(value === true){
                                        const userObj = users[key]
                                        if(userObj != null && orgObj?.orgObj?.superAdmins?.[key] != true){
                                            var photoURL = userObj?.photoURL
                                            //if the user photoURL is null, use the default image
                                            if(userObj?.photoURL == null){
                                                photoURL = DefaultAccount
                                            }

                                            return (
                                                <Paper className='organizationContentUser' elevation={3}>
                                                    <img src={photoURL} />
                                                    <h2>{userObj?.displayName}</h2>
                                                    <h4>{userObj?.email}</h4>
                                                    <p>{`Last Signin: ${calculateDateFromMillisecond(userObj?.lastUpdated?.seconds * 1000)}`}</p>

                                                </Paper>
                                            )
                                        }
                                    }
                                })
                            }                        
                            </div>

                        </div>

                    </div>
                    <div className='organizationTabContent' style={{display: selectedTab == 1 ? 'flex':'none'}}>
                        <div className='organizationContentArea'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginTop: '15px',
                                alignItems: 'flex-start'
                            }}>

                            {
                                userIsOrgSuperAdmin ?
                                    <DataDisplayEditableSelect 
                                        loading={orgBillingPointOfContactLoading} 
                                        icon={AccountBalanceIcon} 
                                        title="Billing Point of Contact" 
                                        display={calculateUserDisplayName(users[orgBillingPointOfContact])} 
                                        value={orgBillingPointOfContact} 
                                        selectItems={Object.values(users).map(u => {return {value: u.uid, display: calculateUserDisplayName(u), disabled: !(orgObj?.orgObj?.analysts?.[u.uid] == true || orgObj?.orgObj?.superAdmins?.[u.uid] == true)}})}
                                        onSelectValueChange={(value) => {
                                            setOrgBillingPointOfContact(value)
                                        }}
                                        onSave={() => {
                                            updateBillingPointOfContact(orgBillingPointOfContact)
                                        }}>

                                    </DataDisplayEditableSelect>
                                :
                                    <DataDisplay 
                                        loading={false} 
                                        icon={AccountBalanceIcon} 
                                        title="Billing Point of Contact" 
                                        data={calculateUserDisplayName(users[orgObj?.orgObj?.paymentSettings?.billingPointOfContact])}>
                                    </DataDisplay>
                                
                            }

                            {
                                userIsOrgSuperAdmin ?
                                <>
                                    <h3>Payment Methods</h3>
                                    <div className='organizationContentPaymentMethodArea'>
                                        {
                                            paymentMethods.sort((a, b) => {
                                                //sort by isDefaultPaymentMethod to be first
                                                if(a?.isDefaultPaymentMethod){
                                                    return -1
                                                }else if(b?.isDefaultPaymentMethod){
                                                    return 1
                                                }else{
                                                    return 0
                                                }                                                
                                            }).map((paymentMethod) => {
                                                return (
                                                    <PaymentMethod 
                                                        key={paymentMethod.id}
                                                        selected={paymentMethod?.isDefaultPaymentMethod} 
                                                        loading={false} 
                                                        create={false} 
                                                        organizationId={orgObj?.selectedOrganization} 
                                                        paymentMethodObj={paymentMethod} 
                                                        createAlert={createAlert} 
                                                        refreshWallet={getOrgPaymentMethods}
                                                        />
                                                )
                                            })
                                        }
                                        <PaymentMethod 
                                            key="createPaymentMethod"
                                            selected={false}
                                            loading={getPaymentMethodsLoading} 
                                            create={true} 
                                            organizationId={orgObj?.selectedOrganization} 
                                            paymentMethodObj={{
                                                userDefaultPaymentMethodSet: paymentMethods?.length > 0 ? paymentMethods[0]?.userDefaultPaymentMethodSet == true : false
                                            }} 
                                            createAlert={createAlert} 
                                            refreshWallet={getOrgPaymentMethods}/>
                                    </div>
                                </>
                                :
                                null
                            }
                            
                        </div> 
                    </div>
                </Paper>
                
            </div>            
            <Footer></Footer>
            <Dialog
                open={sendInviteDialogOpen}
                onClose={() => setSendInviteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Add an Analyst to {orgName}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{marginBottom: '15px'}}>
                        Once the Analyst accepts the invite they will have access to your organization's data
                    </DialogContentText>
                    <TextField
                        helperText="Please enter the Analyst's email address."
                        id="demo-helper-text-aligned"
                        label="Email"
                        type="email"
                        fullWidth
                        value={inviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSendInviteDialogOpen(false)}>Cancel</Button>
                    <Button 
                        onClick={() => sendEmailInvite()} 
                        autoFocus 
                        color="secondary"
                        variant="contained"
                        disabled={inviteEmail.length == 0}>
                        Send Invite Email
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}


export default Organization