import React, { useRef, useState, useEffect } from 'react';
import firebase from 'firebase/compat/app'
import { singInWithEmailAndPassword } from 'firebase/auth'
import { Box, AppBar, Toolbar, IconButton, Typography, Button, Tooltip, Menu, MenuItem, ListItemIcon, Paper, Dialog, TextField, Divider, InputAdornment, FormControl, InputLabel, Input  } from '@mui/material';
import './AuthInterface.css';
import DefaultAccount from '../../assets/DefaultAccount.png'

import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close'; 
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GoogleIcon from '@mui/icons-material/Google';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SendIcon from '@mui/icons-material/Send';


function AuthInterface(props) {
    const auth = props.auth;

    const loadPage = props.loadPage

    var appInstantiationObj = {}

    if(loadPage == "createAccount"){
      appInstantiationObj = {
        showLoginUI: false,
        showCreateAccountUI: true,
        showForgotPasswordUI: false,
        initialUI: {
          icon: <AddCircleOutlineIcon fontSize="small" style={{marginRight: '10px'}}/>,
          text: "Create Account",
          action: () => { openDialog(); showCreateAccount() },
          close: () => { clearForm(); setAuthDialogOpen(false) }
        }
      }
    }else{
      appInstantiationObj = {
        showLoginUI: true,
        showCreateAccountUI: false,
        showForgotPasswordUI: false,
        initialUI: {
          icon: <AccountCircleIcon fontSize="small" style={{marginRight: '10px'}}/>,
          text: "Sign In",
          action: openDialog,
          close: closeDialog
        }
      }
    }


    const createAlert = props.createAlert
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [authDialogOpen, setAuthDialogOpen] = React.useState(false);
    const [displayName, setDisplayName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loadingEmailAuth, setLoadingEmailAuth] = useState(false)
    const [loadingCreateAccount, setLoadingCreateAccount] = useState(false)
    const [loadingSendRecoveryEmail, setLoadingSendRecoveryEmail] = useState(false)

    const [showLoginUI, setShowLoginUI] = useState(appInstantiationObj.showLoginUI)
    const [showCreateAccountUI, setShowCreateAccountUI] = useState(appInstantiationObj.showCreateAccountUI)
    const [showForgotPasswordUI, setShowForgotPasswordUI] = useState(appInstantiationObj.showForgotPasswordUI)

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };



    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account'
        });

        auth.signInWithPopup(provider)
        .then((result) => {
          createAlert("success", "Signed in successfully")
        })
        .catch((error) => {
          createAlert("error", "Error signing in")
        })

    }

    function makeFirebaseAuthRespHumanReadable(error) {

      var message = ""
      switch (error.code) {
        case "auth/invalid-login-credentials":
          message = "Invalid login credentials"
          break;
      
        default:
          message = error.message
          break;
      }
      
      return message
    }

    function signInWithEmail(e) {

      e.preventDefault()
      setLoadingEmailAuth(true)

      firebase.auth().signInWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in
          var user = userCredential.user;
          // ...
          createAlert("success", "Signed in successfully")
          setLoadingEmailAuth(false)
          appInstantiationObj.initialUI.close()
          
        })
        .catch((error) => {
          
          var errorMessage = makeFirebaseAuthRespHumanReadable(error);
          createAlert("error", errorMessage)
          setLoadingEmailAuth(false)
          
        });

    }

    function createAccountWithEmail(e) {

      e.preventDefault()
      setLoadingCreateAccount(true)

      firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          // Signed in 
          var user = userCredential.user;
          // update display name
          user.updateProfile({
            displayName: displayName
          }).then(() => {
            // Update successful
            // ...
          })
          .catch((error) => {
            // An error occurred
            console.error(error)
          })
          .finally(() => {
            createAlert("success", "Account created successfully")
            setLoadingCreateAccount(false)
            appInstantiationObj.initialUI.close()
          })
        })
        .catch((error) => {
          var errorMessage = makeFirebaseAuthRespHumanReadable(error);
          createAlert("error", errorMessage)
          setLoadingCreateAccount(false)
        });

    }

    function submitForgotPassword (e) {

      e.preventDefault()
      setLoadingSendRecoveryEmail(true)

      firebase.auth().sendPasswordResetEmail(email)
      .then(() => {
        createAlert("success", "Recovery email sent successfully")
        setLoadingSendRecoveryEmail(false)
        hideForgotPassword()
        
      })
      .catch((error) => {
        var errorMessage = makeFirebaseAuthRespHumanReadable(error);
        createAlert("error", errorMessage)
        setLoadingSendRecoveryEmail(false)
      });


    }


    function clearForm() {  
      setEmail('')
      setPassword('')
    }

    function openDialog() {
      setAuthDialogOpen(true)
    }

    function closeDialog() {
      clearForm()
      hideCreateAccount()
      setAuthDialogOpen(false)
    }

    function showCreateAccount() {
      setShowCreateAccountUI(true)
      setShowLoginUI(false)
    }

    function hideCreateAccount() {
      setShowCreateAccountUI(false)
      setShowLoginUI(true)
    }

    function showForgotPassword() {
      setShowForgotPasswordUI(true)
      setShowLoginUI(false)
    }

    function hideForgotPassword() {
      setShowForgotPasswordUI(false)
      setShowLoginUI(true)
    }

    function signOut() {

      appInstantiationObj.initialUI.close()

      auth.signOut()
      .then(() => {
        createAlert("success", "Signed out successfully")

      })
      .catch((error) => {
        createAlert("error", "Error signing out")
      })

    }

    if(auth.currentUser){
  
      const open = Boolean(anchorEl);
      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
    
      var profileImg = auth.currentUser.photoURL

      if (profileImg == null) {
        profileImg = DefaultAccount
      }

      return (
        <Box style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip >
            <img 
              src={profileImg} 
              className="profileImg" 
              onClick={handleClick}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              />
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              style: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >          
            <MenuItem
              onClick={() => signOut()}>
              <ListItemIcon>
                <LogoutIcon fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>          
          </Menu>
        </Box>          
      )
    }else {
      return (
        <>
          <Button color="inherit" onClick={() => {appInstantiationObj.initialUI.action()}}>
            {appInstantiationObj.initialUI.icon} {appInstantiationObj.initialUI.text}
          </Button>
          <Dialog onClose={() => {appInstantiationObj.initialUI.close()}} open={authDialogOpen}>
            <Paper elevation={5} className="authInterfaceLoginHolder">
              <IconButton style={{position: 'absolute', top: '10px', right: '10px'}} onClick={() => {appInstantiationObj.initialUI.close()}}>
                <CloseIcon />
              </IconButton>
              <Box className="authInterfaceLogin" style={{display: showLoginUI ? 'flex':'none', flex: 1,}}>
                <div style={{flex: 2}}></div>
                <Typography variant="h4" style={{marginBottom: '20px', fontWeight: '500'}}>Sign in</Typography>
                <div style={{flex: 1}}></div>
                <form onSubmit={signInWithEmail}>
                  <TextField 
                    label="Email"
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    name="email"
                    autoComplete="email"
                    size="small"
                    fullWidth
                    style={{marginBottom: '15px'}}
                    variant="standard"
                  />
                  <FormControl variant="standard" fullWidth={true} style={{marginBottom: '20px'}}>
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                      id="standard-adornment-password"
                      variant="standard"
                      value={password}
                      onChange={(e) => {setPassword(e.target.value)}}
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility />:<VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <div className="authInterfaceLoginEmailActionHolder"> 
                    <Button 
                      color="primary" 
                      variant="text" 
                      size="small"
                      onClick={() => { showForgotPassword() }}>
                          Forgot password?
                    </Button>
                    <Button 
                      color="primary" 
                      variant="text" 
                      size="small" 
                      onClick={() => showCreateAccount()}>
                        Create account
                    </Button>

                  </div>

                  <Button type="submit" variant="outlined" color="primary" style={{marginBottom: '10px',}} fullWidth startIcon={<AccountCircleIcon />} disabled={loadingEmailAuth}>Sign in</Button>
                </form>
                <Divider style={{margin: '20px 0', color: '#757575'}}>Or</Divider>


                <Button variant="contained" color="google" onClick={() => {signInWithGoogle()}} startIcon={<GoogleIcon />}>Sign in with Google</Button>
              </Box>
              <Box className="authInterfaceCreateAccount" style={{display: showCreateAccountUI ? 'flex':'none', flex: 1,}}>
                <div style={{flex: 2}}></div>
                <Typography variant="h4" style={{marginBottom: '20px', fontWeight: '500'}}>Create Account</Typography>
                <div style={{flex: 1}}></div>
                <form onSubmit={createAccountWithEmail}>
                  <TextField
                    label="Display Name"
                    type="text"
                    id="displayName"
                    value={displayName}
                    onChange={(e) => {setDisplayName(e.target.value)}}
                    name="displayName"
                    autoComplete="displayName"
                    size="small"
                    fullWidth
                    style={{marginBottom: '15px'}}
                    variant="standard">
                  </TextField>
                  <TextField 
                    label="Email"
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    name="email"
                    autoComplete="email"
                    size="small"
                    fullWidth
                    style={{marginBottom: '15px'}}
                    variant="standard"
                  />
                  <FormControl variant="standard" fullWidth={true} style={{marginBottom: '20px'}}>
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                      id="standard-adornment-password"
                      variant="standard"
                      value={password}
                      onChange={(e) => {setPassword(e.target.value)}}
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility />:<VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  
                           

                  <Button type="submit" variant="contained" color="primary" style={{marginBottom: '10px',}} fullWidth startIcon={<AddCircleOutlineIcon />} disabled={loadingCreateAccount}>Create Account</Button>
                </form>
                <Divider style={{margin: '20px 0', color: '#757575'}}>Already have an account?</Divider>


                <Button variant="text" color="primary" onClick={() => {hideCreateAccount()}} startIcon={<ArrowBackIosIcon/>}>Back to sign in</Button>
              </Box>
              <Box className="authInterfaceCreateAccount" style={{display: showForgotPasswordUI ? 'flex':'none', flex: 1,}}>
                <div style={{flex: 2}}></div>
                <Typography variant="h4" style={{marginBottom: '20px', fontWeight: '500'}}>Forgot Password</Typography>
                <Typography variant="p" style={{marginBottom: '20px', fontWeight: '100'}}>Send an account recovery link to your email.</Typography>
                
                <div style={{flex: 1}}></div>
                <form onSubmit={submitForgotPassword}>
                  <TextField 
                    label="Email"
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => {setEmail(e.target.value)}}
                    name="email"
                    autoComplete="email"
                    size="small"
                    fullWidth
                    style={{marginBottom: '15px'}}
                    variant="standard"
                  />
                           

                  <Button type="submit" variant="contained" color="primary" style={{marginBottom: '10px',}} fullWidth startIcon={<SendIcon />} disabled={loadingSendRecoveryEmail}>Send Recovery Email</Button>
                </form>
                <Divider style={{margin: '20px 0', color: '#757575'}}>Already have an account?</Divider>


                <Button variant="text" color="primary" onClick={() => {hideForgotPassword()}} startIcon={<ArrowBackIosIcon/>}>Back to sign in</Button>
              </Box>
            </Paper>
            
          </Dialog>
        </>
      )
    }
  }

  export default AuthInterface;