import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { Button, IconButton, Paper, TextField, Modal, Box, Typography, Slider, Switch, FormGroup, FormControlLabel, Pagination } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';


import { GoogleMap, useLoadScript, Rectangle, Polygon, GroundOverlay, OverlayView } from '@react-google-maps/api';
import colors from '../../colors'
import { useParams } from 'react-router-dom';
import './GifReview.css';
import firebase from 'firebase/compat/app';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import InfiniteScroll from 'react-infinite-scroll-component';

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

//import jsonData from '../../PopulationRenders/Geometry/USA-AZ-Phoenix.json'

import googleMapsStyle from '../../assets/googleMapsStyle.json'
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ConstructionIcon from '@mui/icons-material/Construction';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import SaveIcon from '@mui/icons-material/Save';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import FindArea from '../FindArea/FindArea';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';

import { toEasternTime } from '../../functions/toEasternTime';

function GifReview(props) {

  const user = props.user;
  const routeParams = useParams();

  const paginationPerPage = 8;
  const firestore = firebase.firestore();
  const costPerKm2 = 0;
  const [mapref, setMapRef] = React.useState(null);
  const [location, setLocation] = useState({lat: 28.5015, lng: -81.38541,})
  const currentLocation  = useRef({lat: 28.5015, lng: -81.38541});
  
  const selectedPolygon = routeParams.polygonId;
  const [polygonObject, setPolygonObject] = useState({})
  const [mapLoaded, setMapLoaded] = useState(false);
  const dateSliderValue  = parseInt(routeParams.dateSliderValue)
  const [imageSliderValue, setImageSliderValue] = useState(2);
  const [mapJpgObject, setMapJpgObject] = useState({})
  const [showMap, setShowMap] = useState(true);
  const [showAOIs, setShowAOIs] = useState(true);
  const [imageProcessingJSON, setImageProcessingJSON] = useState({})
  const [selectedAnalyticIndex, setSelectedAnalyticIndex] = useState(0)
  const [sidePanelOpen, setSidePanelOpen] = useState(true);
  const [analyticPhotoURL, setAnalyticPhotoURL] = useState({})
  const [analyticGIFURLs, setAnalyticGIFURLs] = useState({})
  const [scrollbarCount, setScrollbarCount] = useState({
    0: 0,
    1: 0,
    2: 0,
    3: 0
  });
  const [selectedAOIVisible, setSelectedAOIVisible] = useState(true)
  const [allVisible, setAllVisible] = useState(true)
  const [untouchedVisible, setUntouchedVisible] = useState(false)
  const [landClearingVisible, setLandClearingVisible] = useState(false)
  const [constructionVisible, setConstructionVisible] = useState(false)
  const [renovationVisible, setRenovationVisible] = useState(false)
  const [falsePositiveVisible, setFalsePositiveVisible] = useState(false)
  const [showGroundOverlayGif, setShowGroundOverlayGif] = useState(false)

  const [backgroundImage, setBackgroundImage] = useState("")
  const [backgroundImageTMinus1, setBackgroundImageTMinus1] = useState("")
  const [backgroundImageTMinus2, setBackgroundImageTMinus2] = useState("")
  const [showBackgroundImage, setShowBackgroundImage] = useState(false)
  const [backendImageProcessingObject, setBackendImageProcessingObject] = useState({})
  const [manualBackendImageProcessingObject, setmanualBackendImageProcessingObject] = useState({});
  const [selectedAOI, setSelectedAOI] = useState(0)
  const polygonRef = useRef({});
  const listenersRef = useRef({});

  var updatedPolygons = useRef({});

  const [untouchedPage, setUntouchedPage] = useState(1)
  const [allPage, setAllPage] = useState(1)

  const mapOptions = {
    styles: googleMapsStyle
  }

  const labels = ['landclearing', 'newConstruction', 'renovation', 'falsePositive']
  
  const AOIDISTANCE = .25
  const MONTHINTERVAL = 3
  const DATAMONTHINTERVAL = 12
  const IMAGEPROCESSINGKEY = "75-25.0-9"
  const storage = getStorage();
  // Define refs for Polygon instance and listeners
  const rectangleOptions = {
    fillColor: "#000000",
    fillOpacity: 0,
    strokeColor: "#000000",
    strokeOpacity: 1,
    strokeWeight: 2,
    zIndex: 1
  }

  const { isLoaded } = useLoadScript({    
    googleMapsApiKey: "AIzaSyBs0dLGozEgNjp2OjVuCiBPXZ6pRf9VMoo"
  })

  
  useEffect(() => {

    getPolygonObject(selectedPolygon)

    getPolygonImageProcessingAnalytics(selectedPolygon, dateSliderValue)
    
    
  }, [])

  useEffect(() => {

    if (Object.keys(manualBackendImageProcessingObject).length > 0) {
      // save changes when page changes
      saveManualLabelChanges(selectedPolygon, dateSliderValue)
    }



  }, [allPage])

  function handleKeyDown(event) {
    // your code here
    const key = event
    const aoiId = parseInt(selectedAOI)
    switch (key) {
      case '1':
        setImageSliderValue(0)
        break;
      case '2':
        setImageSliderValue(1)
        break;
      case '3':
        setImageSliderValue(2)  
        break;
      case '4':
        setImageSliderValue(3)
        break;
      case 'p':
        setShowGroundOverlayGif(!showGroundOverlayGif)
        break;
      case ' ':
        changeSelectedAOI(aoiId, 1)
        break;
      case 'z':
        changeSelectedAOI(aoiId, -1 )
        break;
      case '7':
        updateAOILabel(selectedAOI, 'landclearing')
        break;
      case '8':
        updateAOILabel(selectedAOI, 'newConstruction')
        break;
      case '9':
        updateAOILabel(selectedAOI, 'renovation')
        break;
      case '0':
        updateAOILabel(selectedAOI, 'falsePositive')
        break;
    }
  }

  
  const handleOnLoad = map => {
    setMapRef(map);
  };

  const handleCenterChanged = () => {
    if (mapref) {
      currentLocation.current = {lat:mapref.center.lat(), lng: mapref.center.lng()}
    }
  };

  function padNumber(number) {    
    return number.toFixed(3).toString().padStart(7, '0');
  }
  

  function roundDownToNth(num) {
    return Math.floor(num * (1/AOIDISTANCE)) / (1/AOIDISTANCE);
  }
  

  function calculateLotEagleLatLngConversion(_lat, _lng){
    return padNumber(roundDownToNth(_lng) + 180) + '-' + padNumber(roundDownToNth(_lat) + 90)
  }


  function getPolygonObject(_key){
    
    firestore.collection('Backend')
    .doc(_key)
    .get()
    .then((doc) => {   

      const data = doc.data();
      if(data != undefined){

        setPolygonObject(data)
        
      }


    })
    .catch((error) => {
        console.error("Error getting documents: ", error);        
    });  

  }

  function getPolygonImageProcessingAnalytics(_key, _dateVal){

    const compoundKey = parseBackendString(_key, parseInt(_dateVal), MONTHINTERVAL, DATAMONTHINTERVAL)

    const dateTMinus1 = toEasternTime(toEasternTime(new Date(_dateVal)));
    dateTMinus1.setFullYear(dateTMinus1.getFullYear() - 1);
    const compoundKeyTMinus1 = parseBackendString(_key, parseInt(dateTMinus1.getTime()), MONTHINTERVAL, DATAMONTHINTERVAL)

    const dateTMinus2 = toEasternTime(toEasternTime(new Date(_dateVal)));
    dateTMinus2.setFullYear(dateTMinus2.getFullYear() - 2);
    const compoundKeyTMinus2 = parseBackendString(_key, parseInt(dateTMinus2.getTime()), MONTHINTERVAL, DATAMONTHINTERVAL)

    //get polygon image
    getDownloadURL(ref(storage, `Backend/${compoundKey}.png`)).then(resp => {
      setBackgroundImage(resp)
    }).catch(err => {
      console.error(err)        
    });
    //get polygon image t-1
    getDownloadURL(ref(storage, `Backend/${compoundKeyTMinus1}.png`)).then(resp => {
      setBackgroundImageTMinus1(resp)
    }).catch(err => {
      console.error(err)        
    });
    //get polygon image t-2
    getDownloadURL(ref(storage, `Backend/${compoundKeyTMinus2}.png`)).then(resp => {
      setBackgroundImageTMinus2(resp)
    }).catch(err => {
      console.error(err)        
    });

    firestore.collection('BackendImageProcessingManual')
    .doc(compoundKey)
    .get()
    .then((doc) => {   

      const data = doc.data()
      if(data != undefined){
        setmanualBackendImageProcessingObject(JSON.parse(data[IMAGEPROCESSINGKEY]))
      }

      firestore.collection('BackendImageProcessing')
      .doc(compoundKey)
      .get()
      .then((doc2) => {   

        const data2 = doc2.data();
        if(data2 != undefined){
          const tempObject2 = {}
          Object.keys(data2).forEach(key => {
            tempObject2[key] = JSON.parse(data2[key])
          })

          setBackendImageProcessingObject(tempObject2[IMAGEPROCESSINGKEY])
          setTimeout(() => {
            loadMoreElements(tempObject2[IMAGEPROCESSINGKEY], 0, false)
            setTimeout(() => {
              changeAOI(tempObject2[IMAGEPROCESSINGKEY], 0)            
            }, 500);
          }, 100);
          
        }
      })
      .catch((error) => {
          console.error("Error getting documents: ", error);        
      });  
    

    })
    .catch((error) => {
        console.error("Error getting documents: ", error);        
    });  


    

  }    


  function parseBackendString(_key, _dateVal, _monthInterval, _dataMonthInterval){
    const startDate = toEasternTime(toEasternTime(new Date(_dateVal)));
    const endDate = toEasternTime(toEasternTime(new Date(_dateVal))); 
    
    startDate.setMonth(startDate.getMonth() + _monthInterval - _dataMonthInterval)
    endDate.setMonth(endDate.getMonth() + _monthInterval);  

    return `${_key.replace(/\./g, '')}-${startDate.toISOString().substring(0, 10)}-${endDate.toISOString().substring(0, 10)}`;
  }

  function getPolygonGIF(_key){

    return new Promise((resolve, reject) => {

      const compoundKey = parseBackendString(selectedPolygon, dateSliderValue, MONTHINTERVAL, DATAMONTHINTERVAL)
      getDownloadURL(ref(storage, `BackendImageProcessing/${compoundKey}/${IMAGEPROCESSINGKEY}/${_key}.gif`)).then(resp => {
        resolve({
          key: _key,
          val: resp
        })
      }).catch(err => {
        console.error(err)
        resolve({
          key: _key,
          val: ""
        })
      });

    })
  }

  function clearCache(){
    localStorage.setItem(`polygons${user.uid}`, JSON.stringify({}))
  }

  
  function getAOIColor(_label){
    switch(_label) {
      case "landclearing":
        return "#03A9F4"
      case "newConstruction":
        return "#ffd600"
      case "renovation":
        return "#9c27b0"
      case "falsePositive":
        return "#34515e"

    }
  }

  
  function getQuarter(date) {
    const month = date.getMonth();
    const year = date.getFullYear();
    if (month < 3) {
      return `Q1 ${year}`;
    } else if (month < 6) {
      return `Q2 ${year}`;
    } else if (month < 9) {
      return `Q3 ${year}`;
    } else {
      return `Q4 ${year}`;
    }
  }
  
  function findCenter(north, south, east, west) {
    const latitude = (north + south) / 2;
    const longitude = (east + west) / 2;
    return  {
              lat: latitude, 
              lng: longitude
            };
  }

  function createSquare(x, y, n) {
    let coordinates = [
      { lat: y, lng: x },
      { lat: y, lng: x + n },
      { lat: y + n, lng: x + n },
      { lat: y + n, lng: x }
    ];
    return coordinates;
  }
  

  function getFirstDayOfQuarters() {
    const currentDate = toEasternTime(new Date());
    const startDate = toEasternTime(new Date(2019, 0, 1)); // January 1st, 2019
    const quarters = [];
  
    let quarterStartDate = toEasternTime(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
    while (quarterStartDate < currentDate) {
      const dateVal = toEasternTime(new Date(quarterStartDate))
      quarters.push({ 
        value: dateVal.getTime(),
        label: getQuarter(dateVal)
      });
      quarterStartDate = toEasternTime(new Date(quarterStartDate.getFullYear(), quarterStartDate.getMonth() + 3, 1));
    }
    // removes last element of the array
    quarters.pop();
    return quarters;
  }
  
  function saveManualLabelChanges(_key, _dateVal) {

    const compoundKey = parseBackendString(_key, parseInt(_dateVal), MONTHINTERVAL, DATAMONTHINTERVAL)

    if(Object.keys(updatedPolygons.current).length > 0) {
      firestore.collection('UpdateBackendPolygonImageQueue')
      .add({
            processed: false,
            polygon: selectedPolygon,
            compoundKey: compoundKey,
            changeObj: updatedPolygons.current,
            createdDate: firebase.firestore.FieldValue.serverTimestamp()
           })
      .then((resp) => {   
        updatedPolygons.current = {}
      })
      .catch((error) => {
          console.error("Error setting documents 2: ", error);        
      });
    }


    firestore.collection('BackendImageProcessingManual')
    .doc(compoundKey)
    .set({
          [IMAGEPROCESSINGKEY]: JSON.stringify(manualBackendImageProcessingObject)
        }, {merge: true})
    .then((resp) => {   

    })
    .catch((error) => {
        console.error("Error getting documents: ", error);        
    });  

  }

  function setUnsavedToFalsePositive(){

    if(confirm("Please Confirm") == true){

      const tempObject = {...manualBackendImageProcessingObject}
      Object.keys(mergedBackednProcessingObject).filter((e) => !Object.keys(manualBackendImageProcessingObject).includes(e)).map(key => {
        tempObject[key] = backendImageProcessingObject[key]
        tempObject[key].coordinates.label = "falsePositive";
        setmanualBackendImageProcessingObject(tempObject)
      })
    }
    
  }


  function selectAnalyticToView(_analyicIndex, _compoundKey){

    const coords = imageProcessingJSON[_compoundKey][IMAGEPROCESSINGKEY][_analyicIndex].coordinates.bounds

    setLocation(findCenter(coords.north, coords.south, coords.east, coords.west))
    setSelectedAnalyticIndex(_analyicIndex)
    //IMAGEPROCESSINGKEY
    // check if image url already exists
    if(analyticPhotoURL[`${_compoundKey}-${_analyicIndex}`] == undefined){

      getDownloadURL(ref(storage, `BackendImageProcessing/${_compoundKey}/${IMAGEPROCESSINGKEY}/${_analyicIndex}.png`)).then(resp => {
        setAnalyticPhotoURL({
          ...analyticPhotoURL,
          ...{[`${_compoundKey}-${_analyicIndex}`]: resp}
        })
      }).catch(err => {
        console.error(err)
        setAnalyticPhotoURL({
          ...analyticPhotoURL,
          ...{[`${_compoundKey}-${_analyicIndex}`]: ""}
        })
      });

    }

    // check if image url already exists
    if(analyticGIFURL[`${_compoundKey}-${_analyicIndex}`] == undefined){

      getDownloadURL(ref(storage, `BackendImageProcessing/${_compoundKey}/${IMAGEPROCESSINGKEY}/${_analyicIndex}.gif`)).then(resp => {
        setAnalyticGIFURL({
          ...analyticGIFURL,
          ...{[`${_compoundKey}-${_analyicIndex}`]: resp}
        })
      }).catch(err => {
        console.error(err)
        setAnalyticGIFURL({
          ...analyticGIFURL,
          ...{[`${_compoundKey}-${_analyicIndex}`]: ""}
        })
      });

    }
  
  }

  function pickHigher(num1, num2) {
    const higherNum = Math.max(num1, num2);
    return Math.ceil(higherNum / 20) * 20;
  }
  
  function loadMoreElements(_backendImageProcessingObject, _i, _filterByLabel){

    const tempScrollbarCount = scrollbarCount;
    tempScrollbarCount[_i] = tempScrollbarCount[_i] + 20
    setScrollbarCount(tempScrollbarCount)

    

    const promises = [];
    Object.keys(_backendImageProcessingObject).filter((e) => _filterByLabel ? _backendImageProcessingObject[e].coordinates.label == labels[_i]:true).slice(0,_filterByLabel ? tempScrollbarCount[_i]:20000).forEach((key) => {
      if(!(key in analyticGIFURLs)){
        promises.push(getPolygonGIF(key))
      }
    })
    Promise.all(promises).then((values) => {
      const tempObj = {}
      values.forEach((val) => {
        tempObj[val.key] = val.val
      })
      setAnalyticGIFURLs({
        ...analyticGIFURLs,
        ...tempObj
      })

    })

  }

  function createQuadrilateral(_north, _south, _east, _west) {
    return [
      { lat: _north, lng: _west },
      { lat: _north, lng: _east },
      { lat: _south, lng: _east },
      { lat: _south, lng: _west }
    ];
  }

  function updateAOILabel(_key, _label){
    const tempObj = manualBackendImageProcessingObject
    if(!(_key in tempObj)){
      tempObj[_key] = backendImageProcessingObject[_key]
    }
    tempObj[_key].coordinates.label = _label;
    setmanualBackendImageProcessingObject({
      ...tempObj
    })
    
  }

  function updateAOIBounds(_key, _bounds){
    const tempObj = manualBackendImageProcessingObject
    if(!(_key in tempObj)){
      tempObj[_key] = backendImageProcessingObject[_key]
    }
    tempObj[_key].coordinates.bounds = _bounds;
    setmanualBackendImageProcessingObject({
      ...tempObj
    })
    
  }

  function createBounds(x, y, n) {
    return {
      north: y + n,
      south: y,
      east: x + n,
      west: x
    }
  }

  function changeAOI(_backendImageProcessingObject, _key) {
    setSelectedAOI(_key)
    const coords = _backendImageProcessingObject[_key].coordinates.bounds
    setLocation(findCenter(coords.north, coords.south, coords.east, coords.west))
  }

  function createAOI(location){


  }

  function paginationFilter(data, pageNumber) {
    let startIndex = (pageNumber - 1) * paginationPerPage;
    let endIndex = startIndex + paginationPerPage;
    return data.slice(startIndex, endIndex);
  }

 
  function changeSelectedAOI(_currentVal, _direction){
    
    const tempMergedBackednProcessingObject = {
      ...backendImageProcessingObject,
      ...manualBackendImageProcessingObject
    } 
    /*
    const currentLabel = tempMergedBackednProcessingObject[_currentVal].coordinates.label
    const currentLabelIndex = labels.indexOf(currentLabel);
    const mergedSubset = Object.keys(tempMergedBackednProcessingObject).filter((e) => tempMergedBackednProcessingObject[e].coordinates.label == labels[currentLabelIndex])
    const mergedIndex = mergedSubset.indexOf(_currentVal.toString())
    const nextIndex = mergedIndex + _direction    
    const nextAOIIndex = parseInt(mergedSubset[nextIndex])
    //verify newval is in bounds of options
    if(nextIndex >= 0 && nextIndex < mergedSubset.length){
      changeAOI(tempMergedBackednProcessingObject, nextAOIIndex)
    }*/
    const index = _currentVal + _direction;

    changeAOI(tempMergedBackednProcessingObject, index)

    const paginationIndex = Object.keys(tempMergedBackednProcessingObject).indexOf(index.toString())
    
    if(Math.floor(paginationIndex / paginationPerPage) + 1 != allPage){
      setAllPage(Math.floor(paginationIndex / paginationPerPage) + 1)
    }


  }

  function renderGifElement(_key, _backendImageProcessingObject){
    const label = _backendImageProcessingObject[_key].coordinates.label
    var gifURL
    if(analyticGIFURLs[_key] != "" && analyticGIFURLs[_key] != undefined){
      gifURL = <img className='photoViewAreaImg' src={analyticGIFURLs[_key]} />;
    }
    const isTouched = _key in manualBackendImageProcessingObject

    return (
      <Paper 
        className='gifItem' 
        style={{
          backgroundColor: selectedAOI == _key ? "#f9a825":"#ffffff",
          borderColor: isTouched ? "#f44336":"#212121"
        }}
        elevation={3}
        onClick={() => changeAOI(_backendImageProcessingObject, _key)}>
        <div className='gifHolder'>
          {gifURL}
        </div>
        <div className='gifItemButtons'>
          <IconButton 
            color={label == "landclearing" ? label:"primary"}
            onClick={() => updateAOILabel(_key, 'landclearing')}
            aria-label="Land Clearing">
            <AgricultureIcon />
          </IconButton>
          <IconButton 
            color={label == "newConstruction" ? label:"primary"}
            onClick={() => updateAOILabel(_key, 'newConstruction')}
            aria-label="New Construction">
            <EngineeringIcon />
          </IconButton>
          <IconButton 
            color={label == "renovation" ? label:"primary"}
            onClick={() => updateAOILabel(_key, 'renovation')}
            aria-label="Renovation">
            <ConstructionIcon />
          </IconButton>
          <IconButton 
            color={label == "falsePositive" ? label:"primary"}
            onClick={() => updateAOILabel(_key, 'falsePositive')}
            aria-label="delete">
            <BrokenImageIcon />
          </IconButton>
        </div>

      </Paper>
    )
  }


  function onBoundsChanged(_key){
    
    try{
      const bounds = polygonRef.current[_key].bounds
      const boundObj = {
        north: bounds.getNorthEast().lat(),
        south: bounds.getSouthWest().lat(),
        east: bounds.getNorthEast().lng(),
        west: bounds.getSouthWest().lng(),
      }
    

      if (JSON.stringify(mergedBackednProcessingObject[_key].coordinates.bounds) != JSON.stringify(boundObj) && JSON.stringify(backendImageProcessingObject[_key].coordinates.bounds) != JSON.stringify(boundObj)) {

        updatedPolygons.current[_key] = boundObj
        updateAOIBounds(_key, boundObj)

      }
    }catch(err){

    }

    
  }

  const onLoadAnalyticPolygon = useCallback(
    (polygon, key) => {
      polygonRef.current[key] = polygon;

    },
    []
  );

  


  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  

  const dateMarks = getFirstDayOfQuarters()


  var analytics = {}
  var compoundKey = ""
  var sidePanel

  const mergedBackednProcessingObject = {
    ...backendImageProcessingObject,
    ...manualBackendImageProcessingObject
  }
  


  var googleMap
  var backgroundImageOverlay
    
  if(isLoaded){

    var polygonArea

    if(polygonObject.coords != undefined){
      polygonArea = <Rectangle                      
                      editable={false}
                      draggable={false}
                      bounds={createSquare(polygonObject.coords.longitude, polygonObject.coords.latitude, AOIDISTANCE)}
                      options={{
                        ...rectangleOptions,
                        fillColor: "#f9a825",
                        strokeColor: "#f9a825",
                        strokeWeight: 3,
                      }}         
                    />

      backgroundImageOverlay =  <>
                                  <GroundOverlay 
                                    key={"backgroundImageOverlay"}
                                    url={backgroundImage}
                                    style={{}}
                                    bounds={createBounds(polygonObject.coords.longitude, polygonObject.coords.latitude, AOIDISTANCE)}
                                    opacity={imageSliderValue == 2 ? 1:0}/>
                                  <GroundOverlay 
                                    key={"backgroundImageOverlayTminus1"}
                                    url={backgroundImageTMinus1}
                                    style={{}}
                                    bounds={createBounds(polygonObject.coords.longitude, polygonObject.coords.latitude, AOIDISTANCE)}
                                    opacity={imageSliderValue == 1 ? 1:0}/>
                                  <GroundOverlay 
                                    key={"backgroundImageOverlayTminus2"}
                                    url={backgroundImageTMinus2}
                                    style={{}}
                                    bounds={createBounds(polygonObject.coords.longitude, polygonObject.coords.latitude, AOIDISTANCE)}
                                    opacity={imageSliderValue == 0 ? 1:0}/>
                                </>

    }

    var gifURL
    if(analyticGIFURLs[selectedAOI] != "" && analyticGIFURLs[selectedAOI] != undefined && showGroundOverlayGif){
      gifURL = <GroundOverlay 
                  key={selectedAOI}
                  url={analyticGIFURLs[selectedAOI]}
                  style={{opacity: .2}}
                  bounds={mergedBackednProcessingObject[selectedAOI].coordinates.bounds}
                  opacity={1}/>  
    }

    

    googleMap = <div className='gifMapView'>
                  <GoogleMap 
                    zoom={16} 
                    center={location} 
                    mapContainerClassName="gifMapContainer"
                    options={mapOptions}
                    onLoad={handleOnLoad}
                    onCenterChanged={handleCenterChanged}
                    onClick={()=> {}}
                    onDblClick={(e)=> { createAOI(e)}}>
                      <Rectangle
                        editable={false}
                        draggable={false}
                        bounds={{
                          north: 0,
                          south: 0,
                          east: 0,
                          west: 0
                        }}                  
                      />               
                      
                      {backgroundImageOverlay}
                      {gifURL}    
                      {
                          Object.keys(mergedBackednProcessingObject).map(key => {
                            const polygon = mergedBackednProcessingObject[key].coordinates;
                            if(google.maps.geometry != undefined && showAOIs && polygon != undefined){
                              return (
                                <Rectangle
                                  key={key}
                                  onLoad={(polygon) => {onLoadAnalyticPolygon(polygon, key)}}
                                  onBoundsChanged={() => {onBoundsChanged(key)}}
                                  onClick={(() => changeSelectedAOI(parseInt(key), 0))}                                
                                  editable={key == selectedAOI}
                                  draggable={key == selectedAOI}
                                  bounds={polygon.bounds}
                                  options={{
                                    ...rectangleOptions,
                                    fillColor: getAOIColor(polygon.label),
                                    strokeColor: getAOIColor(polygon.label),
                                    strokeWeight: selectedAOI == key ? 2:2,
                                    opacity: 0
                                  }}         
                                />
                              )
                          }
                          })
                        }
                  </GoogleMap>   
                </div>
  }

  const allCount = Object.keys(mergedBackednProcessingObject).length;
  const untouchedCount = Object.keys(mergedBackednProcessingObject).filter((e) => !Object.keys(manualBackendImageProcessingObject).includes(e)).length
  const landClearingCount = Object.keys(mergedBackednProcessingObject).filter((e) => mergedBackednProcessingObject[e].coordinates.label == labels[0]).length
  const constructionCount = Object.keys(mergedBackednProcessingObject).filter((e) => mergedBackednProcessingObject[e].coordinates.label == labels[1]).length
  const renovationCount = Object.keys(mergedBackednProcessingObject).filter((e) => mergedBackednProcessingObject[e].coordinates.label == labels[2]).length
  const falsePositiveCount = Object.keys(mergedBackednProcessingObject).filter((e) => mergedBackednProcessingObject[e].coordinates.label == labels[3]).length
 
  var selectedAOIGif 
  if(analyticGIFURLs[selectedAOI] != "" && analyticGIFURLs[selectedAOI] != undefined){
    selectedAOIGif =  <img onClick={() => changeSelectedAOI(parseInt(selectedAOI), 0)} className='photoViewAreaImg' src={analyticGIFURLs[selectedAOI]} />
  }

  var label 
  var areaOfChange 
  try{
    label = mergedBackednProcessingObject[selectedAOI].coordinates.label
    areaOfChange = Math.floor(mergedBackednProcessingObject[selectedAOI].coordinates.pixelsChanged * 100 * 10.7639).toLocaleString();
  }catch(err){}

  return (
    <div className='gifPageContent' onKeyDown={(e) => handleKeyDown(e.key)} tabIndex="100" >       
      <div className='gifReviewDiv' onKeyDown={(e) => handleKeyDown(e.key)} tabIndex="100">
        <div className='gifReviewDivSelectedArea'>
          <div className='selectedAOIHeaderArea'>         
            <div className='selectedAOIHeaderAreaTitle'>
              <h3 style={{margin: 0}}>{polygonObject.commonName}</h3>              
            </div>            
            <Button 
                className='selectedAOISave'
                color={"secondary"}
                variant="contained"
                onClick={() => saveManualLabelChanges(selectedPolygon, dateSliderValue)}
                aria-label="Land Clearing" startIcon={<SaveIcon />}>
                  Save Changes
              </Button>
            <Button 
              className='selectedAOISave'
              color={"primary"}
              variant="contained"
              onClick={() => setUnsavedToFalsePositive()}
              aria-label="" startIcon={<DeleteIcon />}>
                Set Unsaved to False Positive
            </Button>
          </div>
          <div className='selectedAOIArea'>
            <div className='selectedAOIMetadataArea'> 
              <p style={{margin: 0, marginTop: '5px'}}>Selected AOI: <strong>{selectedAOI}</strong></p>
              <p style={{margin: 0, marginTop: '5px'}}>Area of Change: <br/><strong>{areaOfChange} sq ft</strong></p>
              <FormGroup style={{flexDirection: 'row'}}>
                <FormControlLabel control={<Switch 
                                              color="secondary" 
                                              checked={showGroundOverlayGif}
                                              onChange={(e) => {setShowGroundOverlayGif(e.target.checked)} }/>
                                          } label="Show Overlay" />
              </FormGroup>
              <FormGroup style={{flexDirection: 'row'}}>
                
                <Slider
                  value={imageSliderValue}
                  onChange={(e, value) => {setImageSliderValue(value)}}                                       
                  step={null}
                  marks={[
                    {
                      value: 0,
                      label: '-2 Years',
                    },
                    {
                      value: 1,
                      label: '-1 Year',
                    },
                    {
                      value: 2,
                      label: '0 Year',
                    },
                    {
                      value: 3,
                      label: 'None',
                    },
                  ]}
                  min={0}
                  max={3}
                  color="secondary"
                />
                
              </FormGroup>             
            </div>
            <div className='selectedAOIPhotoArea'>
              {selectedAOIGif}
            </div>
            <div className="selectedAOIButtonArea">
              <Button 
                className='selectedAOIButton'
                color={label == "landclearing" ? label:"primary"}
                variant="contained"
                onClick={() => updateAOILabel(selectedAOI, 'landclearing')}
                aria-label="Land Clearing" startIcon={<AgricultureIcon />}>
                  Land Clearing
              </Button>
              <Button 
                className='selectedAOIButton'
                color={label == "newConstruction" ? label:"primary"}
                variant="contained"
                onClick={() => updateAOILabel(selectedAOI, 'newConstruction')}
                aria-label="New Construction" startIcon={<EngineeringIcon />}>
                  New Construction
              </Button>
              <Button 
                className='selectedAOIButton'
                color={label == "renovation" ? label:"primary"}
                variant="contained"
                onClick={() => updateAOILabel(selectedAOI, 'renovation')}
                aria-label="Renovation" startIcon={<ConstructionIcon />}>
                  Renovation
              </Button>
              <Button 
                className='selectedAOIButton'
                color={label == "falsePositive" ? label:"primary"}
                variant="contained"
                onClick={() => updateAOILabel(selectedAOI, 'falsePositive')}
                aria-label="delete" startIcon={<BrokenImageIcon />}>
                  False Positive
              </Button>
            </div>
          </div>
        </div>
        <div className='gifReviewDivScrollArea'>
          <Accordion disableGutters elevation={0} square expanded={allVisible} onChange={() => {setAllVisible(!allVisible)}} >
            <AccordionSummary 
              aria-controls="panel1d-content" 
              id="panel1d-header"
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <h4>All {allCount}</h4>
            </AccordionSummary>
            <AccordionDetails>
              
              {
                paginationFilter(Object.keys(mergedBackednProcessingObject), allPage).map(key => {
                  return renderGifElement(key, mergedBackednProcessingObject)
                })
              }
              <div style={{width: '100%', float: 'left', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px'}}>
                <Pagination count={Math.ceil(allCount/paginationPerPage)} page={allPage} onChange={(event, value) => {setAllPage(value);}}/>
              </div>
            </AccordionDetails>

          </Accordion>
          <Accordion disableGutters elevation={0} square expanded={untouchedVisible} onChange={() => {setUntouchedVisible(!untouchedVisible)}} >
            <AccordionSummary 
              aria-controls="panel1d-content" 
              id="panel1d-header"
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
              style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
              <h4>UnTouched {untouchedCount}</h4>
            </AccordionSummary>
            <AccordionDetails>
              
              {
                paginationFilter(Object.keys(mergedBackednProcessingObject).filter((e) => untouchedVisible &&  !Object.keys(manualBackendImageProcessingObject).includes(e)), untouchedPage).map(key => {
                  return renderGifElement(key, mergedBackednProcessingObject)
                })
              }
              <div style={{width: '100%', float: 'left', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px'}}>
                <Pagination count={Math.ceil(untouchedCount/paginationPerPage)} page={untouchedPage} onChange={(event, value) => {setUntouchedPage(value);}}/>
              </div>
            </AccordionDetails>

          </Accordion>
          <Accordion disableGutters elevation={0} square expanded={landClearingVisible} onChange={() => {setLandClearingVisible(!landClearingVisible)}} >
            <AccordionSummary 
              aria-controls="panel1d-content" 
              id="panel1d-header"
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}>
              <h4>Land Clearing {scrollbarCount[0] > landClearingCount ? landClearingCount:scrollbarCount[0]}/{landClearingCount}</h4>
            </AccordionSummary>
            <AccordionDetails>
              {
                Object.keys(mergedBackednProcessingObject).filter((e) => landClearingVisible && mergedBackednProcessingObject[e].coordinates.label == labels[0]).slice(0,scrollbarCount[0]).map(key => {
                  return renderGifElement(key, mergedBackednProcessingObject)
                })
              }
              <Button variant="text" color="primary" onClick={() => loadMoreElements(mergedBackednProcessingObject, 0, true)} style={{width: '100%', }}>Load More</Button>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters elevation={0} square expanded={constructionVisible} onChange={() => {setConstructionVisible(!constructionVisible)}} >
            <AccordionSummary 
              aria-controls="panel1d-content" 
              id="panel1d-header"
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}>
              <h4>Construction {scrollbarCount[1] > constructionCount ? constructionCount:scrollbarCount[1]}/{constructionCount}</h4>
            </AccordionSummary>
            <AccordionDetails>
              {
                Object.keys(mergedBackednProcessingObject).filter((e) => constructionVisible && mergedBackednProcessingObject[e].coordinates.label == labels[1]).slice(0,scrollbarCount[1]).map(key => {
                  return renderGifElement(key, mergedBackednProcessingObject)
                })
              }
              <Button  variant="text" color="primary" onClick={() => loadMoreElements(mergedBackednProcessingObject, 1, true)} style={{width: '100%', }}>Load More</Button>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters elevation={0} square expanded={renovationVisible} onChange={() => {setRenovationVisible(!renovationVisible)}} >
            <AccordionSummary 
              aria-controls="panel1d-content" 
              id="panel1d-header"
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}>
              <h4>Renovation {scrollbarCount[2] > renovationCount ? renovationCount:scrollbarCount[2]}/{renovationCount}</h4>
            </AccordionSummary>
            <AccordionDetails>
              {
                Object.keys(mergedBackednProcessingObject).filter((e) => renovationVisible && mergedBackednProcessingObject[e].coordinates.label == labels[2]).slice(0,scrollbarCount[2]).map(key => {
                  return renderGifElement(key, mergedBackednProcessingObject)
                })
              }
              <Button variant="text" color="primary" onClick={() => loadMoreElements(mergedBackednProcessingObject, 2, true)} style={{width: '100%', }}>Load More</Button>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters elevation={0} square expanded={falsePositiveVisible} onChange={() => {setFalsePositiveVisible(!falsePositiveVisible)}} >
            <AccordionSummary 
              aria-controls="panel1d-content" 
              id="panel1d-header"
              expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}>
              <h4>False Positive {scrollbarCount[3] > falsePositiveCount ? falsePositiveCount:scrollbarCount[3]}/{falsePositiveCount}</h4>
            </AccordionSummary>
            <AccordionDetails>
              {
                Object.keys(mergedBackednProcessingObject).filter((e) => falsePositiveVisible && mergedBackednProcessingObject[e].coordinates.label == labels[3]).slice(0,scrollbarCount[3]).map(key => {
                  return renderGifElement(key, mergedBackednProcessingObject)
                })
              }
              <Button variant="text" color="primary" onClick={() => loadMoreElements(mergedBackednProcessingObject, 3, true)} style={{width: '100%', }}>Load More</Button>
            </AccordionDetails>
          </Accordion>
        </div>
  


      </div>      

      {googleMap}

    </div>
  )

}


export default GifReview