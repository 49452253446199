import React, { useEffect, useState } from 'react';
import { TextField, IconButton, Skeleton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

function HeaderTitleEditable({ label, value, permissionToEdit, saveChanges, loading }) {

  const [editing, setEditing] = useState(false);
  const [updatedValue, setUpdatedValue] = useState(value);
  const [touchecd, setTouched] = useState(false)

  
  useEffect(() => {
    if(value != updatedValue && !touchecd){
        setUpdatedValue(value)
        setTouched(true)
    }
  }, [value])

  return (
    loading ? <Skeleton height={60} width={210} /> :
    editing ? 
            <>
                <TextField
                    label={label}
                    type="text"
                    value={updatedValue}
                    onChange={(e) => setUpdatedValue(e.target.value)}
                    size='small'
                    style={{marginRight: '10px'}}
                    />
                    <IconButton 
                        onClick={() => {
                            saveChanges(updatedValue)
                            .then(() => {
                                setEditing(false)
                            })
                            .catch((error) => {
                                console.error(error)
                            })

                        }}
                        disabled={value == updatedValue}>
                        <SaveIcon />
                    </IconButton>
                    <IconButton onClick={() => {
                        setEditing(false)
                    }}>
                        <CancelIcon />
                    </IconButton>
            </>
            :
            <h2 className={permissionToEdit ? "clickable":"" } onClick={() => {
                if(permissionToEdit){
                    setEditing(!editing)
                }
            }}>{value}</h2>    
  );
}

export default HeaderTitleEditable;
