import React, { useState } from 'react';
import { Button, Alert, Paper, TextField, Modal, Box, Typography, Slider, Switch, FormGroup, FormControlLabel, Pagination } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import './ContactUsForm.css';

const ContactUsForm = (props) => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState(props.email != null ? props.email : '');
  const [message, setMessage] = useState('');
  const [emailUpdated, setEmailUpdated] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [submitting, setSubmitting] = useState(false);


  const handleSubmit = (event) => {
    setSubmitting(true)
    event.preventDefault();
    const db = firebase.firestore();
    db.collection('contactUsSubmissions').add({
      name,
      email,
      message,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      respondedState: 0
    })
    .then(() => {
      setDisplaySuccess(true);
    })
    .catch((error) => {
      setDisplayError(true);
    })
    .finally(() => {
      setName('');
      setEmail('');
      setMessage('');
      setSubmitting(false)

      setTimeout(() => {
        setDisplaySuccess(false);
        setDisplayError(false);
      }, 5000);
    });

  };

  function formInvalid(){

    //name is empty
    if (name.length === 0) {
      return true
    }
    //if email is not formatted as a real email
    if (email.length === 0 || !email.includes('@') || !email.includes('.')) {
      return true
    }
    //message is empty
    if (message.length === 0) {
      return true
    }
    
    return false
  }


  var successAlert 
  var errorAlert

  if (displaySuccess) {
    successAlert = <Alert variant="filled" severity="success" style={{marginTop: 10,}}> 
                      Your message has been sent! We will get back to you as soon as possible.
                    </Alert>
  }
  if (displayError) {
    errorAlert = <Alert variant="filled" severity="error" style={{marginTop: 10,}}> 
                    There was an error sending your message. Please try again later.
                  </Alert>
  }


  return (
    <form onSubmit={handleSubmit} className='ContactUsFormComponent'>

        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => { setEmail(e.target.value); setEmailUpdated(true) }}
        />
        <TextField
          label="Message"
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          variant="contained"
          color={"secondary"}
          type="submit"
          disabled={submitting || formInvalid()}
        >
          Submit
        </Button>
        {successAlert}
        {errorAlert}
        
    </form>
  );
};

export default ContactUsForm;