import React, { useEffect, useRef, useState } from 'react';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';

const YouTubeVideo = ({ videoId, isStarted }) => {
  const playerRef = useRef(null);
  const videoLoaded = useRef(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const userTouchedElement = useRef(false)
  const userTouchedMuteButton = useRef(false);

  const onPlayerReady = (event) => {
    // You can auto-mute here if needed
    event.target.mute();

    if(isStarted){
        setIsPlaying(true);
        event.target.playVideo();
    }
  };

  useEffect(() => {
    // ... existing YouTube API setup ...

    // Control play state based on isPlaying prop
    if (playerRef.current) {
        try{
            if (isStarted) {
                setIsPlaying(true);
                playerRef.current.playVideo();
              } else {
                setIsPlaying(false);
                playerRef.current.pauseVideo();
              }
        }catch(e){
        }
    }
  }, [isStarted]);


  useEffect(() => {
    // This function loads the IFrame Player API code asynchronously.
    const loadYT = () => {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = () => {
        playerRef.current = new window.YT.Player('player', {
          videoId: videoId,
          events: {
            onReady: onPlayerReady,
          },
          playerVars: {
            controls: 0,
            modestBranding: 1,
            rel: 0,
            loop: 1,
            playlist: videoId,
          }
        });
      };
    };

    if (!window.YT) {
      loadYT();
    } else if (window.YT && window.YT.Player) {
      playerRef.current = new window.YT.Player('player', {
        videoId: videoId,
        events: {
          onReady: onPlayerReady,
        },
        playerVars: {
            controls: 0,
            modestBranding: 1,
            rel: 0,
            loop: 1,
            playlist: videoId,
          }
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.destroy();
      }
    };
  }, [videoId]);

  const muteVideo = () => {
    playerRef.current.mute();
  };

  const unmuteVideo = () => {
    playerRef.current.unMute();
  };

  const playVideo = () => {
    playerRef.current.playVideo();
  }
  const pauseVideo = () => {
    playerRef.current.pauseVideo();
  }

  return (
    <div style={{width: '100%', height: '100%', backgroundColor: '#eeeeee'}}>
        <div id="player" style={{width: 'calc(100% + 4px)', height: 'calc(100% + 4px)', margin: '-2px'}}></div>
        <div 
            onClick={() => {
              if(isPlaying){
                //if just touching for the first time, unmute
                if(userTouchedElement.current == false && userTouchedMuteButton.current == false){
                  userTouchedElement.current = true;
                  unmuteVideo();
                  setIsMuted(false);
                }else{
                  pauseVideo();
                  setIsPlaying(false);
                }
              }else{
                playVideo();
                setIsPlaying(true);
                if(userTouchedMuteButton.current == false){
                    //unmuteVideo();
                    unmuteVideo();
                    setIsMuted(false);
                }
              }
            }}
            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer'}}>
        
        </div>
        <IconButton
            onClick={() => {
                userTouchedMuteButton.current = true;
                if(isMuted){
                    unmuteVideo();
                    setIsMuted(false);
                }else{
                    muteVideo();
                    setIsMuted(true);
                }
            }}
            style={{position: 'absolute', bottom: '1em', left: '1em'}}
            siex="large"
        >
            {
                isMuted ? <VolumeOffIcon style={{color: 'white', fontSize: '1em'}} />:<VolumeUpIcon style={{color: 'white', fontSize: '1em'}} />
            }
        </IconButton>
    </div>
  );
};

export default YouTubeVideo;
