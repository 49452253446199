import * as turf from '@turf/turf';

export function calculateSquareLotOverlap(_square, _lot){


  const squarePolygon = _square.map(coord => {
    return [coord.lng, coord.lat] 
  })
  //push the last coordinate to close the polygon
  squarePolygon.push([_square[0].lng, _square[0].lat])

  const lotPolygon = _lot.map(coord => {
    return [coord.lng, coord.lat] 
  })
  //push the last coordinate to close the polygon
  lotPolygon.push([_lot[0].lng, _lot[0].lat])

  const square = turf.polygon([squarePolygon]); // Define your square coordinates here
  const lot = turf.polygon([lotPolygon]); // Define your lot coordinates here
  try{
    // Step 1: Detect Overlapping Polygons and create a union of their overlaps with the square
    let overlap = turf.intersect(square, lot);
    if (overlap) {
      return overlap
    }else{
      return null
    }
  }catch(err){
    console.error(err)
    return null
  }

    
  return _lot

  }