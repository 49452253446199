import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import './Home.css';
import firebase from 'firebase/compat/app'
import HeroPhoto from '../../assets/interchange-min.jpg'
import heroLogo from '../../assets/Logo_withtagline.svg'
import mapImg from '../../assets/howItWorks/map.jpg'
import beforeImg from '../../assets/howItWorks/before.jpg'
import afterImg from '../../assets/howItWorks/after.jpg'
import mergedImg from '../../assets/howItWorks/merged-removebg.png'
import REDaA from '../../assets/AboutUs/REDaA.png'
import CityPlanning from '../../assets/AboutUs/CityPlanning.png'
import conservation from '../../assets/AboutUs/conservation.png'

import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import FeedIcon from '@mui/icons-material/Feed';
import MailIcon from '@mui/icons-material/Mail';
import CallIcon from '@mui/icons-material/Call';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MapIcon from '@mui/icons-material/Map';
import realEstateInvestors from '../../assets/AboutUs/realEstateInvestors.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { useParams, useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import SwipeableTextMobileStepper from '../SwipeableTextMobileStepper/SwipeableTextMobileStepper';
import ContactUsForm from '../ContactUsForm/ContactUsForm';

import blogData from '../BlogHome/blogdata.json'

import YouTubeVideo from './YouTubeVideo';

function Home(props) {

    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const navigate = useNavigate()

    const videoRef = useRef(null);
    const [videoStarted, setVideoStarted] = useState(false);

    const [beforeImgPercent, setBeforeImgPercent] = useState(100)
    const [afterImgPercent, setAfterImgPercent] = useState(100)
    const [changeImgPercent, setChangeImgPercent] = useState(100)


    useEffect(() => {

        var videoStarted = false

        const observer = new IntersectionObserver(
          (entries) => {
            const [entry] = entries;
            // Autoplay if the video iframe is in view
            if (entry.isIntersecting && !videoStarted) {
                videoStarted = true
                setVideoStarted(true)
            }
          },
          {
            root: null, // viewport
            threshold: 0.5, // 50% should be visible
          }
        );
    
        if (videoRef.current) {
          observer.observe(videoRef.current);
        }
    
        return () => {
          if (videoRef.current) {
            observer.unobserve(videoRef.current);
          }
        };
      }, []);
    


    var blogPosts = []
    
    try{

        //get latest 3 blog posts
        const blogDataKeys = Object.keys(blogData)
        blogDataKeys.sort((a, b) => {
            //sort by date
            return new Date(blogData[b]['postedDate']) - new Date(blogData[a]['postedDate'])
        })

        blogPosts = blogDataKeys.slice(0, 3).map(key => {
            return blogData[key]
        })

    }catch(err){

    }


    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account'
        });
        props.auth.signInWithPopup(provider).then((result) => {
            window.location.replace("/mapView");
        });
    }

    var mapViewButton = <Button onClick={() => {navigate("/GetStarted")}} variant="contained" size='large' color="secondary" style={{}}>
                            Get started for free
                        </Button>

    if(props.user != null){
        mapViewButton = <Button onClick={() => {navigate("/mapView")}} variant="contained" size='large' color="secondary" style={{}} startIcon={<MapIcon />}>
                            Map View
                        </Button>
    }


    return (
        <div className="HomeContent">

            <Paper className='HeroImg' style={{backgroundImage: `url(${HeroPhoto})`, borderRadius: 0,}} elevation={5}>
                <div>
                    <img src={heroLogo} alt="Logo" className="heroImgLogoBG" style={{filter: 'brightness(0%) blur(15px)', opacity: .5}}/>   
                    <img src={heroLogo} alt="Logo" className="heroImgLogoBG"/>   
                    <img src={heroLogo} alt="Logo" className="heroImgLogo"/>   
                     
                </div>  
            </Paper>

            <div className='elevatorPitchVideoHolder' ref={videoRef}>
                <YouTubeVideo videoId="Y2gBfsacOWs" isStarted={videoStarted} />                
            </div>

            <div className='Demo' >
                <div className='howItWorks'>
                    <Paper className='howItWorksContent' elevation={5} style={{borderTopLeftRadius: '1em', borderBottomRightRadius: '1em', borderTopRightRadius: '4em', borderBottomLeftRadius: '4em'}}>
                        <div className='howItWorksDataDiv'>
                            <h1 style={{fontSize: '30px'}}>Lead the Market with<br/>Satellite-Driven<br/>Real Estate Insights</h1>
                            <div className='sliderArea' style={{flex: 1}}>                                
                                                      
                                <Button
                                    variant="contained"
                                    color={afterImgPercent == 0 && beforeImgPercent != 0 ? "secondary":"primary"}
                                    style={{marginBottom: '10px', width: '100%'}}
                                    onClick={() => {setBeforeImgPercent(100); setAfterImgPercent(0); setChangeImgPercent(0)}}>
                                    Two Years Ago
                                </Button>
                                <Button
                                    variant="contained"
                                    color={afterImgPercent == 100 && changeImgPercent == 0 ? "secondary":"primary"}
                                    style={{marginBottom: '10px', width: '100%'}}
                                    onClick={() => {setBeforeImgPercent(100); setAfterImgPercent(100); setChangeImgPercent(0)}}
                                    disabled={false && beforeImgPercent == 0}>
                                    Present Day
                                </Button>
                                <Button
                                    variant="contained"
                                    color={changeImgPercent == 100 ? "secondary":"primary"}
                                    style={{marginBottom: '10px', width: '100%'}}
                                    onClick={() => {setBeforeImgPercent(100); setAfterImgPercent(100); setChangeImgPercent(100)}}
                                    disabled={false && (beforeImgPercent == 0 || afterImgPercent == 0)}>
                                    Changes Detected
                                </Button>

                               

                            </div>
                        </div>
                        <div style={{display: 'flex', flex: 1, position: 'relative'}}>
                            <div className='howItWorksImgArea'>
                                <div className="howItWorksPhotoHolder" style={{width: `${beforeImgPercent}%`}}>
                                    <img src={beforeImg} className="howItWorksPhoto"></img>                             
                                </div>
                                <div className="howItWorksPhotoHolder" style={{width: `${afterImgPercent}%`}}>
                                    <img src={afterImg} className="howItWorksPhoto"></img>                             
                                </div>
                                <div className="howItWorksPhotoHolder" style={{width: `${changeImgPercent}%`, backgroundColor: "rgba(0,0,0,.5)"}}>
                                    <img src={mergedImg} className="howItWorksPhoto"></img>                             
                                </div>
                            </div>
                            <div className='howItWorksImgAreaShadow' style={{backgroundColor: `rgba(0, 0, 0, ${.1 - ((beforeImgPercent/100) * 2 )} )`}}>
                                {/*<h1 style={{opacity: 1 - ((beforeImgPercent/100) * 2), display: 1 - ((beforeImgPercent/100) * 2) > 0 ? 'block':'none'}}>Select <span style={{color: "#f9a825"}}>Q4 2020</span></h1>*/}
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>


            <div className='AboutUs'>
                <Paper className='AboutUsContent' elevation={5} >
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <InfoIcon style={{marginRight: '10px'}}/>
                        About Lot Eagle
                    </div>
                    <div className="AboutUsContentArea"> 
                        <div style={{flex: 2, maxWidth: '400px'}}>
                            <SwipeableTextMobileStepper images={[
                                {
                                    
                                    imgPath: REDaA,
                                },                                
                                {
                                    imgPath: CityPlanning,
                                },
                                {
                                    imgPath: conservation,
                                },
                                {
                                    imgPath: realEstateInvestors,
                                },
                                ]}>
                                
                            </SwipeableTextMobileStepper>
                        </div>
                        <div style={{flex: 3, lineHeight: '1.5em', }} className='AboutUsContentAreaText'>                            
                            <h2 style={{"margin": '0px', 'marginBottom': '10px'}}>Satellite Powered - Construction and Land Clearing Detection</h2>
                            <h3 style={{width: '100%', marginBottom: '1em' }}>
                                Lot Eagle specializes in using Artificial Intelligence (AI) combined with satellite imagery to accurately detect changes in land and construction sites. Our approach provides a more efficient alternative to traditional methods, which often require a lot of manual work. We operate as a Software as a Service/Data as a Service (SaaS/DaaS) platform, making us a reliable and up-to-date source for those who need real estate data and analytics. Our services are ideal for Real Estate Data & Analytics Companies, City Planners, Environmental Conservationists, and Real Estate Investors seeking timely and accurate information.
                            </h3>                            
                        </div>
                        
                    </div>

                </Paper>
            </div>   
            
            <div className='BlogSection'>
                <Paper className='AboutUsContent' elevation={5} >
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <MailIcon style={{marginRight: '10px'}}/>
                        Contact Us
                    </div>
                    
                    <div className="AboutUsContentArea"> 
                        <div style={{flex: 3, lineHeight: '1.5em'}} className='ContactUsContentAreaText'>                            
                            <h3 style={{width: '100%', marginTop: '1em' }}>
                                Excited about Lot Eagle? We are too! We'd love to hear from you.
                            </h3>
                            <div style={{marginBottom: '10px'}}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<MailIcon />}
                                    onClick={() => {navigate('/ContactUs/')}}>
                                    Contact Us
                                </Button>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
            <Footer></Footer>
        </div>
    )

}


export default Home