import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './AdminHR.css'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import { calculateDateFromMillisecond } from '../../functions/calculateDateFromMillisecond';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PeopleIcon from '@mui/icons-material/People';
import DefaultAccount from '../../assets/DefaultAccount.png'

function AdminHR(props) {

    const adminMode = props.adminMode
    const auth = props.auth;
    const createAlert = props.createAlert
    const currentUser = props.auth.currentUser
    const adminHRObj = props?.adminHRObj
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const navigate = useNavigate()

    const [sendInviteMapReviewTeamDialogOpen, setSendInviteMapReviewTeamDialogOpen] = useState(false)

    const [inviteEmail, setInviteEmail] = useState("")
    const [users, setUsers] = useState({})
    const [selectedAnalystKey, setSelectedAnalystKey] = useState("")
    const [manageAnalystDialogOpen, setManageAnalystDialogOpen] = useState(false)
    const [deactivateAnalystDialogOpen, setDeactivateAnalystDialogOpen] = useState(false)



    useEffect(() => {

        getUsers()

    }, [])


    function generatePassword() {

        var length = 20,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;

    }

    function sendHrEmailInvite() {
        const password = generatePassword()

        var inviteDocRef = firebase.firestore().collection("Operations").doc("Invites").collection("Admin").doc("InternalHR").collection("Analyst").doc(password)
        //add the invite to the collection
        inviteDocRef.set({
            dateCreated: firebase.firestore.FieldValue.serverTimestamp(),
            email: inviteEmail,
            password: password,            
        })
        .then((docRef) => {
            //create an alert
            createAlert("success", "An email invite has been sent to " + inviteEmail)

            setInviteEmail("")

            setSendInviteMapReviewTeamDialogOpen(false)

        })
        .catch((error) => {
            console.error("Error adding document: ", error);
            //create an alert
            createAlert("error", "There was an error sending the invite")

        });   
    }

    function promoteUserToManager(_key, _setAsManager) {
        setDeactivateAnalystDialogOpen(false)
        setManageAnalystDialogOpen(false)
        setTimeout(() => {
            setSelectedAnalystKey("")
        }, 1500);
        //set the user as a manager
        firebase.firestore().collection("Admin").doc("InternalHR").update({
            [`analystManagers.${_key}`]: _setAsManager,
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
            //create an alert
            if(_setAsManager){
                createAlert("success", "The analyst has been promoted to manager")
            } else {
                createAlert("success", "The analyst has been demoted from manager")
            }        
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
            //create an alert
            createAlert("error", "There was an error updating the analyst manager status")

        });
    }

    function deactivateAnalyst(_key) {
        setDeactivateAnalystDialogOpen(false)
        setManageAnalystDialogOpen(false)
        setTimeout(() => {
            setSelectedAnalystKey("")
        }, 1500);
        //deactivate the user
        firebase.firestore().collection("Admin").doc("InternalHR").update({
            [`analystManagers.${_key}`]: false,
            [`analysts.${_key}`]: false,
            dateUpdated: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
            //create an alert
            createAlert("success", "The analyst has been deactivated")
            
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
            //create an alert
            createAlert("error", "There was an error deactivating the analyst")

        });

    }

    function getUsers() {
        const tempData = {}
        //get the users for the organization
        firebase.firestore().collection("Admin").doc("InternalHR").collection("Users")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    tempData[doc.id] = doc.data()
                });
                setUsers(tempData)
            })

    }

    const analysts = adminHRObj?.adminHRObject?.analysts != null ? adminHRObj?.adminHRObject?.analysts : {}
    const analystManagers = adminHRObj?.adminHRObject?.analystManagers != null ? adminHRObj?.adminHRObject?.analystManagers : {}

    
    const analystManagersKeys = Object.keys(analystManagers).filter((key) => analystManagers[key] === true)
    const analystKeys = Object.keys(analysts).filter((key) => analysts[key] === true).filter((key) => analystManagers[key] !== true)


    return (
        <div className="adminHRContent">
            <div className='adminHRContentScroller'>
                <Paper elevation={2} className='adminHRContentPaper'>
                    <div className="sectionHeader" style={{borderBottomColor: '#f9a825',}}>
                        <PeopleIcon style={{marginRight: '10px'}}/>
                        Tasking HR
                    </div>
                    <div className='adminHRInfoArea'>                     
                    </div>
                    <div className='adminHRContentArea'>
                        <div className='adminHRContentHeader'>
                            <h3>Supervisor Team</h3>

                        </div>
                        <div className='adminHRContentUserArea'>
                        {
                            analystManagersKeys
                            .sort((a, b) => {
                                //sort by display name then by email
                                return users[a]?.displayName?.localeCompare(users[b]?.displayName) || users[a]?.email?.localeCompare(users[b]?.email)
                            })
                            .map((key) => {
                                const value = analystManagers?.[key]
                                if(value === true){
                                    const userObj = users[key]
                                    if(userObj != null ){
                                        var photoURL = userObj?.photoURL
                                        //if the user photoURL is null, use the default image
                                        if(userObj?.photoURL == null){
                                            photoURL = DefaultAccount
                                        }

                                        return (
                                            <Paper 
                                                className='adminHRContentUser' 
                                                elevation={3}
                                                onClick={() => {
                                                    setSelectedAnalystKey(key)
                                                    setManageAnalystDialogOpen(true)    
                                                }}
                                                key={`managers-${key}`}>
                                                <img src={photoURL} />
                                                <h2>{userObj?.displayName}</h2>
                                                <h4>{userObj?.email}</h4>
                                                <p>{`Last Signin: ${calculateDateFromMillisecond(userObj?.lastUpdated?.seconds * 1000)}`}</p>

                                            </Paper>
                                        )
                                    }
                                }
                            })
                        }                        
                        </div>

                    </div>
                    <div className='adminHRContentArea'>
                        <div className='adminHRContentHeader'>
                            <h3>Analyst Team</h3>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setSendInviteMapReviewTeamDialogOpen(true)
                                }}
                                startIcon={<AddCircleIcon />}
                                style={{display: adminMode ? 'flex':'none'}}>
                                Add Analyst 
                            </Button>
                        </div>
                        <div className='adminHRContentUserArea'>
                        {
                            analystKeys
                            .sort((a, b) => {
                                //sort by display name then by email
                                return users[a]?.displayName?.localeCompare(users[b]?.displayName) || users[a]?.email?.localeCompare(users[b]?.email)
                            })
                            .map((key) => {
                                const value = analysts?.[key]

                                if(value === true){
                                    const userObj = users[key]
                                    if(userObj != null ){
                                        var photoURL = userObj?.photoURL
                                        //if the user photoURL is null, use the default image
                                        if(userObj?.photoURL == null){
                                            photoURL = DefaultAccount
                                        }

                                        return (
                                            <Paper 
                                                className='adminHRContentUser' 
                                                elevation={3}
                                                onClick={() => {
                                                    setSelectedAnalystKey(key)
                                                    setManageAnalystDialogOpen(true)    
                                                }}
                                                key={`analysts-${key}`}>
                                                <img src={photoURL} />
                                                <h2>{userObj?.displayName}</h2>
                                                <h4>{userObj?.email}</h4>
                                                <p>{`Last Signin: ${calculateDateFromMillisecond(userObj?.lastUpdated?.seconds * 1000)}`}</p>                                                
                                            </Paper>
                                        )
                                    }
                                }
                            })
                        }                        
                        </div>

                    </div>
                </Paper>
                
            </div>            
            <Footer></Footer>
            <Dialog
                open={sendInviteMapReviewTeamDialogOpen && adminMode}
                onClose={() => setSendInviteMapReviewTeamDialogOpen(false)}>
                <DialogTitle >
                    Add a Map Analyst 
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{marginBottom: '15px'}}>
                        Once the Reviwer accepts the invite they will have access to review maps
                    </DialogContentText>
                    <TextField
                        helperText="Please enter the Analyst's email address."
                        id="demo-helper-text-aligned"
                        label="Email"
                        type="email"
                        fullWidth
                        value={inviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSendInviteMapReviewTeamDialogOpen(false)}>Cancel</Button>
                    <Button 
                        onClick={() => sendHrEmailInvite()} 
                        autoFocus 
                        color="secondary"
                        variant="contained"
                        disabled={inviteEmail.length == 0}>
                        Send Invite Email
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={manageAnalystDialogOpen && adminMode}
                onClose={() => {
                    setManageAnalystDialogOpen(false)
                    setTimeout(() => {setSelectedAnalystKey("")}, 1500)
                }}
                style={{minWidth: '400px'}}>
                <DialogTitle >
                    {`Manage Analyst`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        {`Display Name: ${users[selectedAnalystKey]?.displayName}`}
                    </DialogContentText>
                    <DialogContentText style={{marginBottom: '15px'}}>
                        {`Email: ${users[selectedAnalystKey]?.email}`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setManageAnalystDialogOpen(false)
                        setTimeout(() => {setSelectedAnalystKey("")}, 1500)
                    }}>
                        Cancel
                    </Button>
                    <div style={{flex: 1}}></div>                    
                    <Button 
                        onClick={() => setDeactivateAnalystDialogOpen(true)} 
                        autoFocus 
                        color="red"
                        variant="outlined">
                        Deactivate Analyst
                    </Button>
                    <div style={{flex: 1}}></div>
                    {
                        analystManagers[selectedAnalystKey] === true ?
                        <Button 
                            onClick={() => promoteUserToManager(selectedAnalystKey, false)} 
                            autoFocus 
                            color="primary"
                            variant="contained">
                            Demote from Supervisor
                        </Button>
                        :
                        <Button 
                            onClick={() => promoteUserToManager(selectedAnalystKey, true)} 
                            autoFocus 
                            color="primary"
                            variant="contained">
                            Promote to Supervisor
                        </Button>
                    }
                    
                </DialogActions>
            </Dialog>

            <Dialog
                open={deactivateAnalystDialogOpen && adminMode}
                onClose={() => {
                    setDeactivateAnalystDialogOpen(false)                    
                }}
                style={{minWidth: '400px'}}>
                <DialogTitle color={'red'}>
                    {`Deactivate Analyst - ${users[selectedAnalystKey]?.displayName}`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        {`Are you sure you want to deactivate this analyst? ${users[selectedAnalystKey]?.displayName} (${users[selectedAnalystKey]?.email}) will no longer have access to the system.`}
                    </DialogContentText>              
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        setDeactivateAnalystDialogOpen(false)                        
                    }}>
                        Cancel
                    </Button>
                    <div style={{flex: 1}}></div>                    
                    <Button 
                        onClick={() => deactivateAnalyst(selectedAnalystKey)} 
                        autoFocus 
                        color="red"
                        variant="contained">
                        Deactivate Analyst
                    </Button>                    
                </DialogActions>
            </Dialog>
            
        </div>
    )

}


export default AdminHR