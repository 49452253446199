import React, { useEffect, useState, useCallback, useRef, useImperativeHandle } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import firebase from 'firebase/compat/app'
import './BlogHome.css'

import Footer from '../Footer/Footer'
import { Button, IconButton, Paper, Slider, Typography } from '@mui/material';
import ContactUsForm from '../ContactUsForm/ContactUsForm';
import blogData from './blogdata.json'
import FeedIcon from '@mui/icons-material/Feed';


function BlogHome(props) {

    const currentUser = props.auth.currentUser
    const currentUserEmail = currentUser != null ? currentUser.email : ""
    const loggedIn = props.loggedIn

    const navigate = useNavigate()
    
    return (
                
        <div className="blogContent">
            <div className='blogContentScroller'>
                <Paper elevation={2} className='blogContentPaper' style={{borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px'}}>
                    <div style={{padding: '0px', paddingBottom: '0px', }}>
                        <div className="sectionHeader" style={{borderBottomColor: '#f9a825', padding: '15px',}}>
                            <FeedIcon style={{marginRight: '10px'}}/>
                            Blog
                        </div>
                    </div>
                </Paper>
                
                <div 
                    className='scrollerArea'>
                    {
                        Object.keys(blogData).sort((a, b) => {
                            //sort by date
                            return new Date(blogData[b]['postedDate']) - new Date(blogData[a]['postedDate'])
                        }).map(key => {
                            
                            try{
                                const blogElement = blogData[key];
                                const image = require(`../../assets/BlogData/${key}/${blogElement.bannerImg}`);                                    

                                return (
                                    <Paper elevation={3}
                                        className='blogElement'
                                        onClick={() => navigate(`/Blog/${key}`)}>
                                        <img className='blogImg' src={image}/>
                                        <div className='textArea'>
                                            <div className='title'>{blogElement.title}</div>
                                            <div className='postedData'>{`Published ${new Date(blogElement['postedDate']).toLocaleDateString('en-us', {year: 'numeric', month: 'long', day: 'numeric'})} by ${blogElement.author}`}</div>
                                            <div className='description'>{blogElement.description}</div>
                                        </div>
                                    </Paper>

                                )

                            }catch(e) {

                                return null

                            }
                        })   
                    }     
                    <div style={{height: '40px'}}></div>                      
                </div>

            </div>            
            <Footer></Footer>
        </div>
    )

}


export default BlogHome